import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Text } from '@soluto-private/mx-asurion-ui-react';
import {
    pricing,
    selectedDevice,
    selectedRepair,
    locale,
    deviceColor,
} from 'state/ApplicationRecoil';
import { formatPrice } from 'utils/helpers';
import Callout from './Callout';

// Follows callout pattern in Asurion UI but with a custom nested layout
// Current version of Asurion UI ^1.19.0 does not include this component either

const PricingDetails = () => {
    const { t } = useTranslation('common');
    const device = useRecoilValue(selectedDevice);
    const repair = useRecoilValue(selectedRepair);
    const color = useRecoilValue(deviceColor);
    const currentLocale = useRecoilValue(locale);
    const pricingState = useRecoilValue(pricing);

    return (
        <Callout>
            <Text className="section-header" weight="heavy" size="3" as="h2">
                {t('repair_details')}
            </Text>
            <div className="bucket">
                <div className="bucket-col">
                    <img
                        className="device-image"
                        src={device.image_link}
                        alt=""
                    />
                    <div>
                        <Text as="div" weight="heavy">
                            {device.name[currentLocale.apiTranslationKey]}
                        </Text>
                        <Text as="div">
                            {!!color && `${color} - `}

                            {
                                repair.repair_type.symptom[
                                    currentLocale.apiTranslationKey
                                ]
                            }
                        </Text>
                    </div>
                </div>
                {pricingState.price && (
                    <Text className="price" as="div">
                        {t('estimated_cost')}:{' '}
                        <Text className="price-amount" weight="heavy" size="4">
                            {formatPrice(
                                currentLocale.explicit,
                                pricingState.currency,
                                pricingState.price
                            )}
                            *
                        </Text>
                    </Text>
                )}
            </div>
        </Callout>
    );
};

export default PricingDetails;
