import {
    Text,
    Link,
    TextField,
    RadioButton,
    Button,
} from '@soluto-private/mx-asurion-ui-react';
import {
    CardElement,
    useStripe,
    useElements,
    PaymentRequestButtonElement,
} from '@stripe/react-stripe-js';
import { useState, useEffect, useCallback } from 'react';
import { useRecoilValue, useSetRecoilState, useResetRecoilState } from 'recoil';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
    customerDetails,
    pricing,
    countryCode,
    payment,
    bookingWindow,
} from 'state/ApplicationRecoil';
import { useLeadSubmission, useCheckBookingWindow } from 'hooks';
import { useAlert } from 'components/Alert';
import Loader from 'components/Loader';
import BillingAddress from './BillingAddress';
import { createPaymentIntent } from 'utils/api';

const CheckoutForm = () => {
    const { error: alertError } = useAlert();
    const { t } = useTranslation(['common', 'errors']);
    const { getLeadSubmissionPayload, handleSuccess } = useLeadSubmission();
    const customerDetailsState = useRecoilValue(customerDetails);
    const setConfirmedPayment = useSetRecoilState(payment);
    const pricingState = useRecoilValue(pricing);
    const countryCodeState = useRecoilValue(countryCode);
    const { checkBookingWindowAvailability } = useCheckBookingWindow();
    const resetBookingWindow = useResetRecoilState(bookingWindow);
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();

    const [sameBillingAddress, setSameBillingAddress] = useState('yes');
    const [paymentRequest, setPaymentRequest] = useState(null);
    const [isValidCard, setIsValidCard] = useState(false);
    const [cardError, setCardError] = useState(null);
    const [cardClass, setCardClass] = useState('cc-field');
    const [processingPayment, setProcessingPayment] = useState(false);
    const [checkedForSavedPayment, setCheckedForSavedPayment] = useState(false);

    const stripe = useStripe();
    const elements = useElements();

    const createStripePaymentIntent = useCallback(async () => {
        const response = await createPaymentIntent(getLeadSubmissionPayload());
        const { data } = response.data;

        // Check for success data
        if (data && data.client_secret) {
            return data;
        }
    }, [getLeadSubmissionPayload]);

    useEffect(() => {
        if (stripe && !checkedForSavedPayment) {
            const pr = stripe.paymentRequest({
                country: countryCodeState,
                currency: pricingState.currency.toLowerCase(),
                total: {
                    label: t('remote_tech_deposit'),
                    amount: pricingState.deposit_price * 100,
                },
                requestPayerName: true,
                requestPayerEmail: true,
            });

            // Check the availability of the Payment Request API.
            pr.canMakePayment().then((result) => {
                setCheckedForSavedPayment(true);

                if (result) {
                    setPaymentRequest(pr);

                    pr.on('paymentmethod', async (ev) => {
                        // Check booking window availability
                        const matchingWindow =
                            await checkBookingWindowAvailability();

                        // Booking window does not exist anymore - handle failure
                        if (!matchingWindow) {
                            ev.complete('fail');

                            return;
                        }

                        // Create the client secret token to process this payment
                        const paymentIntentData =
                            await createStripePaymentIntent();

                        const { paymentIntent, error: confirmError } =
                            await stripe.confirmCardPayment(
                                paymentIntentData.client_secret,
                                { payment_method: ev.paymentMethod.id },
                                { handleActions: false }
                            );

                        if (confirmError) {
                            // Report to the browser that the payment failed, prompting it to
                            // re-show the payment interface, or show an error message and close
                            // the payment interface.
                            ev.complete('fail');
                        } else {
                            // Report to the browser that the confirmation was successful, prompting
                            // it to close the browser payment method collection interface.
                            ev.complete('success');
                            // Check if the PaymentIntent requires any actions and if so let Stripe.js
                            if (paymentIntent.status === 'requires_action') {
                                // Let Stripe.js handle the rest of the payment flow.
                                const { paymentIntent, error } =
                                    await stripe.confirmCardPayment(
                                        paymentIntentData.client_secret
                                    );

                                if (error) {
                                    // The payment failed -- ask your customer for a new payment method
                                    alertError(error.message);
                                } else {
                                    // The payment has succeeded.
                                    handleSuccess();
                                    setConfirmedPayment(paymentIntent);
                                }
                            } else {
                                // The payment has succeeded.
                                handleSuccess();
                                setConfirmedPayment(paymentIntent);
                            }
                        }
                    });
                }
            });
        }
    }, [
        t,
        stripe,
        alertError,
        countryCodeState,
        pricingState,
        createStripePaymentIntent,
        setConfirmedPayment,
        handleSuccess,
        checkedForSavedPayment,
        checkBookingWindowAvailability,
    ]);

    useEffect(() => {
        // Setup card events
        if (elements) {
            const cardElement = elements.getElement(CardElement);

            cardElement.on('change', (event) => {
                if (event.complete) {
                    setIsValidCard(true);
                    setCardError(null);
                    setCardClass('cc-field valid');
                } else if (event.empty) {
                    setIsValidCard(false);
                    setCardError(null);
                    setCardClass('cc-field');
                } else if (event.error) {
                    setIsValidCard(false);
                    setCardError(event.error.message);
                    setCardClass('cc-field invalid');
                }
            });
        }
    }, [elements]);

    const handleStripePayment = async (client_secret, formValues) => {
        const addressValues =
            sameBillingAddress === 'no' ? formValues : customerDetailsState;

        try {
            const { paymentIntent, error } = await stripe.confirmCardPayment(
                client_secret,
                {
                    payment_method: {
                        card: elements.getElement(CardElement),
                        billing_details: {
                            name: `${formValues.givenName} ${formValues.familyName}`,
                            email: customerDetailsState.email,
                            address: {
                                city: addressValues.city,
                                country: countryCodeState,
                                line1: addressValues.address_line_1,
                                line2: addressValues.address_line_2,
                                postal_code: addressValues.postal_code,
                                state: addressValues.state,
                            },
                        },
                    },
                }
            );

            if (error) {
                setCardError(error.message);
                setCardClass('cc-field invalid');
            } else {
                handleSuccess();
                setConfirmedPayment(paymentIntent);
            }
        } catch (error) {
            alertError('Could not process payment');
        }

        setProcessingPayment(false);
    };

    const submitForm = async (formValues) => {
        try {
            if (!stripe || !elements) {
                // Stripe.js has not loaded yet. Make sure to disable
                // form submission until Stripe.js has loaded.
                return;
            }

            // Loading state
            setProcessingPayment(true);

            const matchingWindow = await checkBookingWindowAvailability();

            // Booking window still exists! Handle Payment
            if (matchingWindow) {
                // Create the client secret token to process this payment
                const paymentIntent = await createStripePaymentIntent();

                await handleStripePayment(
                    paymentIntent.client_secret,
                    formValues
                );
            } else {
                // Booking window slot does not exist anymore
                // Send them back to select booking window step
                alertError(t('errors:booking_window_not_available'));

                resetBookingWindow();
            }
        } catch (error) {
            setProcessingPayment(false);

            alertError(t('errors:something_went_wrong'));
        }
    };

    return (
        <Form onSubmit={handleSubmit(submitForm)}>
            <Card>
                <label htmlFor="card-element">{t('payment_methods')}</label>
                <div className={cardClass}>
                    <CardElement
                        options={{
                            hidePostalCode: true,
                            style: {
                                base: {
                                    fontSize: '1rem',
                                    fontFamily:
                                        'Apercu, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
                                    color: '#000000',
                                    '::placeholder': {
                                        color: '#aab7c4',
                                    },
                                },
                                invalid: {
                                    color: '#9e2146',
                                },
                            },
                        }}
                    />
                </div>
                {cardError && <div className="error">{cardError}</div>}
            </Card>

            <div className="name-container">
                <TextField
                    label={t('attributes:given_name')}
                    fieldStatus={errors.givenName ? 'error' : 'default'}
                    helperText={errors.givenName?.message}
                    {...register('givenName', {
                        required: t('validation:required', {
                            attribute: t('attributes:given_name'),
                        }),
                    })}
                />

                <TextField
                    label={t('attributes:family_name')}
                    fieldStatus={errors.familyName ? 'error' : 'default'}
                    helperText={errors.familyName?.message}
                    {...register('familyName', {
                        required: t('validation:required', {
                            attribute: t('attributes:family_name'),
                        }),
                    })}
                />
            </div>

            <div>
                <Text as="div" className="billing-address-text">
                    {t('same_billing_address_question')}
                </Text>

                <RadioButton
                    label={t('yes')}
                    type="radio"
                    onChange={(e) => setSameBillingAddress(e.target.value)}
                    value="yes"
                    name="sameBillingAddress"
                    checked={sameBillingAddress === 'yes' ? true : false}
                />

                <RadioButton
                    label={t('no')}
                    type="radio"
                    onChange={(e) => setSameBillingAddress(e.target.value)}
                    value="no"
                    name="sameBillingAddress"
                    checked={sameBillingAddress === 'no' ? true : false}
                />

                {sameBillingAddress === 'no' && (
                    <BillingAddress
                        register={register}
                        errors={errors}
                        control={control}
                    />
                )}
            </div>

            <div className="terms-conditions">
                <Text color="#6E767D">
                    <Trans t={t} i18nKey="purchase_terms_conditions">
                        By purchasing I agree to the
                        <Link
                            href={t('routes:terms_conditions')}
                            target="_blank"
                            noreferrer
                            noopener
                        >
                            terms and conditions
                        </Link>
                    </Trans>
                </Text>
            </div>

            {paymentRequest && (
                <PaymentRequestButtonContainer>
                    <PaymentRequestButtonElement options={{ paymentRequest }} />
                </PaymentRequestButtonContainer>
            )}

            <div>
                {processingPayment ? (
                    <Loader />
                ) : (
                    <Button
                        size="medium"
                        variant="default"
                        disabled={!isValidCard}
                        type="submit"
                    >
                        {t('actions.submit_payment')}
                    </Button>
                )}
            </div>

            <Text className="disclaimer" as="div" size={0}>
                {t('deposit_disclaimer')}
            </Text>
        </Form>
    );
};

const Card = styled.div`
    margin: 0.5rem 0 2rem;

    .cc-field {
        margin-top: 0.5rem;
        border-radius: 5px;
        border: 1px solid #a5aaaf;
        padding: 1rem 0.75rem;
    }

    .invalid {
        color: #424770;
        border: 1px solid #db1b1b;
        box-shadow: inset 0px 0px 0px 1px #db1b1b;
        border-radius: 0.5rem 0.5rem 0 0;
    }

    .error {
        font-size: 0.875rem;
        font-weight: 400;
        border: 0.0625rem #6e767d;
        border-radius: 0 0 0.5rem 0.5rem;
        padding: 0.25rem 0.75rem;
        background-color: #db1b1b;
        color: #ffffff;
    }
`;

const Form = styled.form`
    .disclaimer {
        margin-top: 2rem;
    }

    .error-message {
        margin-bottom: 1.5rem;
        color: #db1b1b;
        font-weight: bold;
    }

    .name-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.5rem;

        > div {
            width: 100%;
        }

        > div:nth-child(1) {
            margin-right: 1rem;
        }
    }
`;

const PaymentRequestButtonContainer = styled.div`
    margin-bottom: 1.5rem;
    max-width: 220px;
`;

export default CheckoutForm;
