import { useCallback, useState } from 'react';
import DefaultContext from './Context';

const types = {
    INFO: 'INFO',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    WARN: 'WARN',
};

const AlertProvider = ({ children, context: Context }) => {
    const [alerts, setAlerts] = useState([]);

    const remove = useCallback(
        (alert) => {
            setAlerts((currentAlerts) => {
                const lengthBeforeRemove = currentAlerts.length;
                const filteredAlerts = currentAlerts.filter(
                    (a) => a.id !== alert.id
                );

                if (
                    lengthBeforeRemove > filteredAlerts.length &&
                    alert.options.onClose
                ) {
                    alert.options.onClose();
                }

                return filteredAlerts;
            });
        },
        [setAlerts]
    );

    const removeAll = useCallback(() => {
        alerts.forEach(remove);
    }, [alerts, remove]);

    const show = useCallback(
        (message = '', options = {}) => {
            const id = Math.random().toString(36).substr(2, 9);

            const alert = {
                id,
                message,
                options,
            };

            alert.close = () => remove(alert);

            setAlerts((state) => {
                return state.concat(alert);
            });
            if (alert.options.onOpen) alert.options.onOpen();

            return alert;
        },
        [remove, setAlerts]
    );
    const success = useCallback(
        (message = '', options = {}) => {
            options.type = types.SUCCESS;
            return show(message, options);
        },
        [show]
    );

    const error = useCallback(
        (message = '', options = {}) => {
            options.type = types.ERROR;
            return show(message, options);
        },
        [show]
    );

    const warn = useCallback(
        (message = '', options = {}) => {
            options.type = types.WARN;
            return show(message, options);
        },
        [show]
    );

    const info = useCallback(
        (message = '', options = {}) => {
            options.type = types.INFO;
            return show(message, options);
        },
        [show]
    );

    const value = {
        alerts,
        show,
        remove,
        removeAll,
        success,
        error,
        warn,
        info,
    };

    const Ctx = Context || DefaultContext;

    return <Ctx.Provider value={value}>{children}</Ctx.Provider>;
};

export default AlertProvider;
