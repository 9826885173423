import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import { currentStep } from 'state/ApplicationRecoil';

const progressSteps = [
    {
        steps: ['ROOT_DEVICE'],
        title: 'device.generic',
    },
    {
        steps: ['CUSTOMER_LOCATION'],
        title: 'attributes:zip_code',
    },
    {
        steps: [
            'SERVICE_TYPE',
            'DEVICE_DETAILS',
            'REPAIR_SELECTION',
            'SELECTED_REPAIR_OPTIONS',
        ],
        title: 'service_details',
    },
    {
        steps: [
            'CHOOSE_STORE',
            'APPT_DETAILS',
            'SELECT_BOOKING_WINDOW',
            'RT_REPAIR_LOCATION',
            'CONTACT_DETAILS',
            'MI_REPAIR_LOCATION',
            'PAYMENT_COLLECTION',
            'CONFIRMATION',
        ],
        title: 'appointment',
    },
];

const ProgressNav = () => {
    const { t } = useTranslation(['common', 'attributes']);
    const step = useRecoilValue(currentStep);

    return (
        <StyledProgressNav
            className="progress-nav"
            role="group"
            aria-label="progress"
        >
            {progressSteps.map(({ steps, title }, index) => {
                return (
                    <li
                        key={title}
                        className={`item${
                            steps.includes(step) ? ' --current' : ''
                        }`}
                        aria-current={steps.includes(step)}
                    >
                        <span className="title">
                            {t(title)}
                            <span className="progress">{`(${index + 1}/${
                                progressSteps.length
                            })`}</span>
                        </span>
                        <span className="hardware">
                            <span className="circle" />
                        </span>
                    </li>
                );
            })}
        </StyledProgressNav>
    );
};

const StyledProgressNav = styled.ul`
    align-items: center;
    display: flex;
    position: relative;
    width: 100%;

    .item {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 3rem;
        justify-content: space-between;
        width: ${100 / progressSteps.length}%;

        &:first-child .hardware:before,
        &:last-child .hardware:after {
            display: none;
        }

        &.--current {
            justify-content: flex-end;

            .title {
                left: 50%;
                opacity: 1;
                pointer-events: auto;
                position: absolute;
                top: 0;
                transform: translateX(-50%);
            }

            & ~ .item {
                .hardware {
                    &:before,
                    &:after {
                        background-color: ${(props) =>
                            props.theme.colors.incomplete};
                    }
                }

                .circle {
                    border-color: ${(props) => props.theme.colors.incomplete};
                }
            }
        }
    }

    .title {
        opacity: 0;
        pointer-events: none;
        text-align: center;
    }

    .progress {
        margin-left: 0.25rem;
    }

    .hardware {
        display: flex;
        justify-content: center;
        position: relative;
        width: 100%;

        &:before,
        &:after {
            background-color: ${(props) => props.theme.colors.black};
            content: '';
            height: 1px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        &:before {
            right: 50%;
            width: 75%;
        }

        &:after {
            left: 50%;
            width: 25%;
        }
    }

    .circle {
        background-color: ${(props) => props.theme.colors.white};
        border: 1px solid ${(props) => props.theme.colors.black};
        border-radius: 50%;
        display: block;
        height: 0.5rem;
        position: relative;
        width: 0.5rem;
        z-index: 1;
    }

    ${(props) => props.theme.mediaQuery(props.theme.breakpoints.medium)} {
        .item {
            &.--current {
                justify-content: space-between;

                .title {
                    position: static;
                    transform: none;
                }
            }
        }

        .title {
            opacity: 1;
            pointer-events: auto;
        }

        .progress {
            display: none;
        }
    }
`;

export default ProgressNav;
