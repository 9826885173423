import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

const useValidationSchema = () => {
    const { t } = useTranslation(['common', 'validation', 'attributes']);

    let contactFormSchema = yup.object().shape({
        givenName: yup.string().required(
            t('validation:required', {
                attribute: t('attributes:given_name'),
            })
        ),
        familyName: yup.string().required(
            t('validation:required', {
                attribute: t('attributes:family_name'),
            })
        ),
        email: yup
            .string()
            .email(t('validation:email'))
            .required(
                t('validation:required', {
                    attribute: t('attributes:email'),
                })
            ),
        phone: yup
            .string()
            .required(
                t('validation:required', {
                    attribute: t('attributes:phone'),
                })
            )
            .transform(function (value) {
                return value.replace(/\D/g, '');
            })
            .length(10, t('validation:phone_number')),
        canCall: yup.boolean(),
        canSms: yup.boolean(),
        canEmail: yup.boolean(),
    });

    contactFormSchema = contactFormSchema.test('oneIsSelected', null, (obj) => {
        if (obj.canCall || obj.canSms || obj.canEmail) {
            return true;
        }

        return new yup.ValidationError(
            t('validation:preferred_contact_methods'),
            null,
            'preferredContactMethods'
        );
    });

    return { contactFormSchema };
};

export { useValidationSchema };
