import { useTranslation } from 'react-i18next';
import { Button } from '@soluto-private/mx-asurion-ui-react';
import StepHeader from 'components/StepHeader';
import styled from 'styled-components';

const CenterButton = styled(Button)`
    margin: 0 auto;
`;

const ErrorPage = ({ onNext }) => {
    const { t } = useTranslation('errors');
    return (
        <section className="page">
            <StepHeader
                title={t('errors:error_page.title')}
                description={t('errors:error_page.subtitle')}
            />
            <CenterButton
                style={{ margin: '0 auto' }}
                onClick={onNext || (() => {})}
                type="button"
                className="btn"
            >
                {t('errors:error_page.restart_action')}
            </CenterButton>
        </section>
    );
};

export default ErrorPage;
