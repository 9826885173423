import styled from 'styled-components';

const Callout = styled.div`
    font-style: normal;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-style: solid;
    border-width: 0.125rem;
    border-radius: 0.5rem;
    background-color: #f0f0f5;
    border-color: transparent;
    letter-spacing: 0;
    width: 100%;
    padding: 1rem 0.5rem;

    .device-image {
        width: 75px;
        mix-blend-mode: darken;
    }

    .bucket {
        width: 100%;
    }

    .price {
        margin-left: 75px;
    }

    .bucket-col {
        display: flex;
        align-items: center;
    }

    @media only screen and (min-width: 768px) {
        padding: 1rem;

        .bucket {
            display: flex;
            align-items: center;
            flex-grow: 1;

            @supports (display: grid) {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 1rem;
            }
        }

        .price {
            margin-left: calc(2rem + 41px); // Padding + gutter + SVG size
            margin-top: 0;
            flex-basis: 150px;
            flex-shrink: 0;
            flex-grow: 1;
        }

        .price-amount {
            display: block;
        }
    }
`;

export default Callout;
