import styled from 'styled-components';
import { Text, Button } from '@soluto-private/mx-asurion-ui-react';
import { useTranslation } from 'react-i18next';
import Callout from '../../Callout';

const ShippingCard = ({ shipping }) => {
    const { t } = useTranslation('common');

    return (
        <Callout>
            <Details>
                <Text as="p" className="mb-1" size={3}>
                    {t('shipping_card.description')}
                </Text>

                <div>
                    <DownloadLink
                        href={shipping.label_link}
                        target="_blank"
                        rel="noreferrer noopener"
                        aria-label="Print shipping label"
                    >
                        <Button className="btn" aria-hidden>
                            {t('shipping_card.print_label')}
                        </Button>
                    </DownloadLink>
                </div>
            </Details>
        </Callout>
    );
};

const Details = styled.div`
    .mb-1 {
        margin-bottom: 1rem;
    }
`;
const DownloadLink = styled.a`
    display: inline-block;
    width: 100%;

    .btn {
        width: 100%;
    }
`;

export default ShippingCard;
