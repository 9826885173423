import { useTranslation } from 'react-i18next';
import { Text } from '@soluto-private/mx-asurion-ui-react';
import { useRecoilValue } from 'recoil';
import { locale } from 'state/ApplicationRecoil';
import { formatPrice } from 'utils/helpers';

const RemoteTechContent = ({ amount, currency }) => {
    const { t } = useTranslation();
    const currentLocale = useRecoilValue(locale);

    return (
        <div>
            <h2>
                <Text weight="heavy" size="3">
                    {t('confirmation.subtitle')}
                </Text>
            </h2>

            <div className="text-description">
                <h3>
                    <Text weight="heavy">
                        {t('confirmation.remote_tech.title1')}:
                    </Text>
                </h3>
                <Text as="p">{t('confirmation.remote_tech.description1')}</Text>
            </div>
            <div className="text-description">
                <h3>
                    <Text weight="heavy">
                        {t('confirmation.remote_tech.title2')}:
                    </Text>
                </h3>
                <Text as="p">{t('confirmation.remote_tech.description2')}</Text>
            </div>
            {currency && amount && (
                <div className="text-description">
                    <h3>
                        <Text weight="heavy">
                            {t('confirmation.remote_tech.title3')}:
                        </Text>
                    </h3>
                    <Text as="p">
                        {t('confirmation.remote_tech.description3', {
                            remoteTechDeposit: formatPrice(
                                currentLocale.explicit,
                                currency,
                                amount / 100
                            ),
                        })}
                    </Text>
                </div>
            )}
        </div>
    );
};
export default RemoteTechContent;
