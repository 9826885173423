import { useTranslation } from 'react-i18next';
import { Text } from '@soluto-private/mx-asurion-ui-react';
import Callout from '../../Callout';
import styled from 'styled-components';

const SameDayNotAvailCallout = ({ phoneNumber }) => {
    const { t } = useTranslation('common');

    return (
        <Container>
            <Callout>
                <Text size="3" weight="heavy">
                    {t('schedule_repair.no_same_day_appointment_times.title')}
                </Text>
                <DescriptionBlock>
                    <Text as="p">
                        {t(
                            'schedule_repair.no_same_day_appointment_times.description'
                        )}{' '}
                        {t(
                            'schedule_repair.no_same_day_appointment_times.call_us'
                        )}{' '}
                        <PhoneLink href={`tel:${phoneNumber}`}>
                            {phoneNumber}
                        </PhoneLink>
                        .
                    </Text>
                </DescriptionBlock>
                <Text as="p">
                    {t(
                        'schedule_repair.no_same_day_appointment_times.check_another_store'
                    )}
                </Text>
            </Callout>
        </Container>
    );
};

const Container = styled.div`
    padding-bottom: 30px;
`;

const DescriptionBlock = styled.div`
    padding-top: 10px;
    padding-bottom: 15px;
`;

const PhoneLink = styled.a`
    display: inline-block;
    color: black;
    text-decoration: underline;
`;

export default SameDayNotAvailCallout;
