import { useRecoilTransaction_UNSTABLE } from 'recoil';
import {
    postalCode,
    deviceId,
    repairId,
    serviceTypeCode,
    storeId,
    serviceOptions,
    bookingWindow,
    customerDetails,
    lead,
    payment,
    selectedRepairOptions,
} from 'state/ApplicationRecoil/atoms';

export default function useResetWorkflow() {
    // could use useCallback, but without being a transaction, the user
    // can see each view as the state is being updated.
    return useRecoilTransaction_UNSTABLE(({ reset }) => () => {
        const toReset = [
            postalCode,
            deviceId,
            repairId,
            serviceTypeCode,
            storeId,
            serviceOptions,
            bookingWindow,
            customerDetails,
            lead,
            payment,
            selectedRepairOptions,
        ];
        toReset.reverse().forEach((atom) => {
            reset(atom);
        });
    });
}
