import { colors } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const SelectionCard = ({ children, className, image, onClick }) => {
    return (
        <Card onClick={onClick} className={className}>
            {image && (
                <div className="card-media" data-testid="selection-card-image">
                    <img src={image} alt="" className="image" />
                </div>
            )}

            <div className="card-container">
                {children}

                <svg
                    className="icon"
                    width="10"
                    height="18"
                    viewBox="0 0 10 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10 9L1.33333 -3.78832e-07L-2.27393e-07 1.38461L7.33333 9L2.92155e-06 16.6154L1.33334 18L10 9Z"
                        fill="black"
                    />
                </svg>
            </div>
        </Card>
    );
};

const Card = styled.button`
    border: 1px solid ${colors.neutralDeep};
    border-radius: 0.5rem;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    background-color: ${colors.white};
    font-family: inherit;
    font-size: 100%;
    margin: 0;
    padding: 0;
    text-align: left;
    width: 100%;
    color: ${colors.black};
    cursor: pointer;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: border-color 0.2s ease-in-out 0s,
        background-color 0.2s ease-in-out 0s, color 0.2s ease-in-out 0s;

    &:focus {
        background-color: rgb(230, 230, 235);
        border-color: rgb(0, 0, 0);
    }

    @media (hover: hover) {
        &:hover {
            background-color: rgb(230, 230, 235);
            border-color: rgb(0, 0, 0);
        }
    }

    .card-container {
        display: flex;
        flex-grow: 1;
        width: 100%;
    }

    .card-media {
        display: none;

        ${(props) => props.theme.mediaQuery(props.theme.breakpoints.medium)} {
            position: relative;
            overflow: hidden;
            height: 140px;
            width: 100%;
            display: block;
        }
    }

    .image {
        object-fit: cover;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
    }

    .icon {
        align-self: center;
        flex-shrink: 0;
    }
`;

SelectionCard.propTypes = {
    onClick: PropTypes.func,
    image: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
};

export default SelectionCard;
