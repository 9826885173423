import axios from 'axios';

const baseApiUrl = process.env.REACT_APP_PORTAL_API_URL;
export const APPT_INTENT_URL = `${baseApiUrl}/appointment-intent`;

export const createPaymentIntent = async function (params) {
    return await axios.post(`${baseApiUrl}/create-payment-intent`, params);
};

export const getAppointmentSettings = async function (storeId, date = null) {
    let year,
        month,
        day = null;

    if (!date) {
        let currentDate = new Date();
        year = currentDate.getFullYear();
        month = currentDate.getMonth() + 1;
        day = currentDate.getDate();
    } else {
        year = date.getFullYear();
        month = date.getMonth() + 1;
        day = date.getDate();
    }

    return await axios.get(`${baseApiUrl}/appointment-settings`, {
        headers: {
            'X-Api-Store-Id': storeId,
        },
        params: {
            start_date: `${year}-${month}-${day}`,
        },
    });
};

export const getBookingWindows = async function (params) {
    return await axios.post(`${baseApiUrl}/booking-windows`, params);
};

export const getDeviceTypes = async function (params) {
    return await axios.post(`${baseApiUrl}/device-types`, params);
};

/*
    repair_id
*/
export const getRepairOptions = async function (params) {
    return await axios.post(`${baseApiUrl}/device-type-options`, params);
};

export const getServiceOptions = async function (params, cancelToken) {
    return await axios.post(`${baseApiUrl}/service-options`, params, {
        cancelToken,
    });
};

export const getRepairPrice = async function (params) {
    return await axios.post(`${baseApiUrl}/repair-price`, params);
};

export const createLead = async function (params) {
    return await axios.post(`${baseApiUrl}/aqleads`, params);
};

export const getPhotoboothImages = async function (params) {
    return await axios.post(`${baseApiUrl}/photo-booth`, params);
};

export const appointmentIntentRequest = async function (params) {
    return await axios.post(APPT_INTENT_URL, params);
};

export const paramsToQueryString = (params) => {
    return new URLSearchParams(
        Object.keys(params).map((key) => [key, params[key]])
    ).toString();
};

export const sleep = (m) => new Promise((r) => setTimeout(r, m));
