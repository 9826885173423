import styled from 'styled-components';

const Callout = styled.div`
    background: ${(props) => props.theme.colors.neutralBrightest};
    color: ${(props) => props.theme.colors.black};
    border-radius: 0.5rem;
    padding: 1rem;
`;

export default Callout;
