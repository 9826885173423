import { format } from 'date-fns';
import styled from 'styled-components';
import { Text, Tag } from '@soluto-private/mx-asurion-ui-react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { locales } from 'utils/constants';
import {
    customerDetails,
    selectedDevice,
    serviceTypeCode,
} from 'state/ApplicationRecoil';
import Callout from '../../Callout';
import RemoteTechAppointmentDetails from './RemoteTechAppointmentDetails';

const AppointmentCard = () => {
    const { t, i18n } = useTranslation('common');
    const device = useRecoilValue(selectedDevice);
    const customerDetailsState = useRecoilValue(customerDetails);
    const selectedServiceTypeCode = useRecoilValue(serviceTypeCode);

    return (
        <Callout>
            <Text size={3} weight="heavy">
                {t('appointment_for_device', {
                    device: device.name[
                        locales[i18n.language].apiTranslationKey
                    ],
                })}
            </Text>
            <Details>
                {selectedServiceTypeCode === 'RT' ? (
                    <RemoteTechAppointmentDetails />
                ) : (
                    <>
                        <Text>
                            {format(
                                new Date(customerDetailsState.appointmentTime),
                                'ccc, MMMM d, y',
                                {
                                    locale: locales[i18n.language].dateLocale
                                        .default,
                                }
                            )}
                        </Text>
                        <Text weight="heavy" className="appointment-time">
                            {format(
                                new Date(customerDetailsState.appointmentTime),
                                'haaa',
                                {
                                    locale: locales[i18n.language].dateLocale
                                        .default,
                                }
                            )}
                        </Text>
                    </>
                )}
            </Details>
            <Tag
                type="dark"
                size="regular"
                text={t('shortened_confirmation_message')}
            />
        </Callout>
    );
};

const Details = styled.div`
    margin: 0.25rem 0 0.5rem 0;
    display: flex;
    justify-content: space-between;

    .appointment-time {
        white-space: nowrap;
        padding-left: 0.5rem;
    }
`;

export default AppointmentCard;
