import { Text, Link } from '@soluto-private/mx-asurion-ui-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { selectedStore } from 'state/ApplicationRecoil';
import { ReactComponent as PinLocationIcon } from 'images/pin-location-icon.svg';

const RepairLocationDetails = ({ allowChangingStore, reset }) => {
    const { t } = useTranslation('common');
    const selectedStoreState = useRecoilValue(selectedStore);

    const handleClick = (e) => {
        e.preventDefault();

        reset();
    };

    return (
        <RepairLocationCard>
            <div className="section-header">
                <Text weight="heavy" size="3">
                    {t('repair_location')}
                </Text>
            </div>

            <div className="section-body">
                <PinLocationIcon
                    width="41"
                    height="41"
                    className="pin-location-icon"
                />

                <div>
                    <Text className="title" as="div" weight="heavy" size={2}>
                        {selectedStoreState.store_branding &&
                            `${selectedStoreState.store_branding} - `}
                        {selectedStoreState.name}
                    </Text>

                    <Text as="div" size={2} className="address">
                        <div>{selectedStoreState.address_1}</div>
                        <div>{selectedStoreState.address_2}</div>
                        <div>
                            {selectedStoreState.city},{' '}
                            {selectedStoreState.state},{' '}
                            {selectedStoreState.postal_code}
                        </div>
                    </Text>

                    {allowChangingStore ? (
                        <Link
                            href="#"
                            color="secondary"
                            onClick={handleClick}
                            className="action-link"
                        >
                            {t('actions.change_store')}
                        </Link>
                    ) : null}
                </div>
            </div>
        </RepairLocationCard>
    );
};

RepairLocationDetails.propTypes = {
    allowChangingStore: PropTypes.bool,
    reset: PropTypes.func,
};

const RepairLocationCard = styled.div`
    .pin-location-icon {
        margin-right: 1rem;
    }

    .section-header {
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-between;
    }

    .section-body {
        display: flex;
    }
`;

export default RepairLocationDetails;
