import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
    useRecoilState,
    useRecoilValueLoadable,
    useSetRecoilState,
} from 'recoil';
import { Dropdown, Text } from '@soluto-private/mx-asurion-ui-react';
import {
    selectedRepairOptions,
    repairOptions,
    deviceColor,
} from 'state/ApplicationRecoil';
import { locales } from 'utils/constants';
import Loader from 'components/Loader';

const RepairOptionsFields = () => {
    const { t, i18n } = useTranslation('common');
    const repairOptionsState = useRecoilValueLoadable(repairOptions);
    const [selectedRepairOptionsState, setSelectedRepairOptions] =
        useRecoilState(selectedRepairOptions);
    const [fields, setFields] = useState(null);
    const setDeviceColor = useSetRecoilState(deviceColor);

    const selectFieldOption = (field, event) => {
        const fieldName = field.name;
        const optionValue = event.target.value;

        // Save the color
        if (field.id === 1) {
            // The field text
            setDeviceColor(
                event.target.options[event.target.selectedIndex].text
            );
        }

        setSelectedRepairOptions({
            ...selectedRepairOptionsState,
            [fieldName]: optionValue,
        });
    };

    useEffect(() => {
        setFields(
            repairOptionsState.contents.option_types.map((optionType) => {
                const fieldName =
                    optionType.name[locales[i18n.language].apiTranslationKey];

                if (
                    optionType.options.length === 1 &&
                    selectedRepairOptionsState[fieldName] === undefined
                ) {
                    setSelectedRepairOptions({
                        ...selectedRepairOptionsState,
                        [fieldName]: JSON.stringify(
                            optionType.options[0].option_ids
                        ),
                    });
                }

                return {
                    id: optionType.id,
                    name: fieldName,
                    values: optionType.options.map((option) => {
                        return {
                            id: JSON.stringify(option.option_ids),
                            name: option.value,
                        };
                    }),
                };
            })
        );
    }, [
        i18n.language,
        repairOptionsState.contents.option_types,
        selectedRepairOptionsState,
        setSelectedRepairOptions,
    ]);

    if (repairOptionsState.state === 'loading' || !fields) return <Loader />;

    return (
        <Collection>
            {fields
                .filter((field) => field.values.length > 1)
                .map((field) => {
                    return (
                        <div key={field.id}>
                            <Text className="field-helper" size={3}>
                                {t('device_details.name', {
                                    name: field.name.toLowerCase(),
                                })}
                            </Text>
                            <Dropdown
                                label={`${t(
                                    'actions.select'
                                )} ${field.name.toLowerCase()}`}
                                name={field.id}
                                onChange={(event) =>
                                    selectFieldOption(field, event)
                                }
                                value={selectedRepairOptionsState[field.name]}
                                options={field.values.map((child) => {
                                    return {
                                        name: child.name,
                                        value: child.id,
                                    };
                                })}
                            />
                        </div>
                    );
                })}
        </Collection>
    );
};

const Collection = styled.div`
    padding: 0 1rem;

    > * + * {
        margin-top: 2rem;
    }

    .field-helper {
        display: block;
        margin-bottom: 1rem;
    }
`;

export default RepairOptionsFields;
