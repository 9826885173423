import React from 'react';
import { withRouter } from 'react-router-dom';
import { SimpleAlert, withAlert } from 'components/Alert';
import ErrorPage from 'components/SchedulingFlow/ErrorPage';
import { withTranslation } from 'react-i18next';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

const apiKey = process.env.REACT_APP_BUGSNAG_API_KEY;
const releaseStage = process.env.REACT_APP_ENV;

if (apiKey && releaseStage !== 'local' && releaseStage !== 'dev') {
    Bugsnag.start({
        apiKey,
        plugins: [new BugsnagPluginReact()],
        releaseStage,
    });
}

const initialState = { hasError: false };

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error };
    }

    componentWillUnmount() {
        if (this.props.alert) {
            const { removeAll } = this.props.alert;
            removeAll();
        }
    }

    render() {
        if (this.state.hasError) {
            const { t, history } = this.props;
            let error = t('errors:error_has_occurred_oops');
            if (this.state.error.hasFriendlyMessage) {
                error = this.state.error.message;
            }

            const resetFlow = () => {
                history.push({ search: '' });
                setTimeout(() => {
                    this.setState(initialState);
                });
            };

            // You can render any custom fallback UI
            return (
                <>
                    <SimpleAlert error message={error} />
                    <ErrorPage onNext={resetFlow} />
                </>
            );
        }

        return this.props.children;
    }
}

export default withRouter(
    withTranslation('common')(withAlert()(ErrorBoundary))
);
