import styled from 'styled-components';
import { Text } from '@soluto-private/mx-asurion-ui-react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import {
    sortedHours,
    translatedDaysForStoreOpenClosedHours,
} from 'utils/helpers';
import { ReactComponent as PinLocationIcon } from 'images/pin-location-icon.svg';
import {
    customerDetails,
    selectedStore,
    serviceTypeCode,
} from 'state/ApplicationRecoil';

const RepairLocationCard = () => {
    const { t } = useTranslation('common');
    const selectedStoreState = useRecoilValue(selectedStore);
    const selectedServiceTypeCode = useRecoilValue(serviceTypeCode);
    const customerDetailsState = useRecoilValue(customerDetails);

    return (
        <Details>
            <h2 className="section-header">
                <Text size="3">{t('repair_location')}</Text>
            </h2>

            <div className="section-body">
                <PinLocationIcon
                    width="41"
                    height="41"
                    className="pin-location-icon"
                />

                <div className="section-inner">
                    {selectedServiceTypeCode !== 'RT' && (
                        <Text
                            className="title"
                            as="div"
                            weight="heavy"
                            size={3}
                        >
                            {selectedStoreState.store_branding &&
                                `${selectedStoreState.store_branding} - `}
                            {selectedStoreState.name}
                        </Text>
                    )}

                    {selectedServiceTypeCode !== 'RT' ? (
                        <>
                            <Text as="div" size={1} className="address">
                                <div>{selectedStoreState.address_1}</div>
                                <div>{selectedStoreState.address_2}</div>
                                <div>
                                    {selectedStoreState.city},{' '}
                                    {selectedStoreState.state},{' '}
                                    {selectedStoreState.postal_code}
                                </div>
                            </Text>

                            {selectedStoreState.hours.human ? (
                                <div className="hours">
                                    {sortedHours(
                                        selectedStoreState.hours.human
                                    ).map((block) => {
                                        return (
                                            <Text
                                                as="div"
                                                size={1}
                                                key={block.days}
                                            >
                                                {translatedDaysForStoreOpenClosedHours(
                                                    t,
                                                    block.days
                                                )}
                                                : {block.hours}
                                            </Text>
                                        );
                                    })}
                                </div>
                            ) : null}
                        </>
                    ) : (
                        <Text as="div">
                            <div>{customerDetailsState.address_line_1}</div>
                            <div>{customerDetailsState.address_line_2}</div>
                            <div>
                                {customerDetailsState.city},{' '}
                                {customerDetailsState.state},{' '}
                                {customerDetailsState.postal_code}
                            </div>
                        </Text>
                    )}
                </div>
            </div>
        </Details>
    );
};

const Details = styled.div`
    .pin-location-icon {
        margin-right: 1rem;
    }

    .section-header {
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-between;
    }

    .section-body {
        display: flex;
    }

    .title {
        margin-bottom: 0.25rem;
    }

    .address {
        margin-bottom: 1rem;
    }
`;

export default RepairLocationCard;
