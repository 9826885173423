import { Text, colors } from '@soluto-private/mx-asurion-ui-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import {
    validServiceTypeCode,
    lead,
    payment,
    locale,
    isPaymentCaptureDisabled,
} from 'state/ApplicationRecoil';
import StepHeader from '../../StepHeader';
import AppointmentCard from './AppointmentCard';
import RepairLocationCard from './RepairLocationCard';
import ShippingCard from './ShippingCard';
import CarryInContent from './CarryInContent';
import MailInContent from './MailInContent';
import RemoteTechContent from './RemoteTechContent';
import AsurionExpertsCard from './AsurionExpertsCard';
import { Icon } from 'components/Icons';
import useResetWorkflow from 'hooks/useResetWorkflow';
import { useScrollToTop } from 'hooks';
import { formatPrice } from 'utils/helpers';

const Confirmation = () => {
    useScrollToTop();

    const { t } = useTranslation('common');
    const selectedServiceTypeCode = useRecoilValue(validServiceTypeCode);
    const paymentState = useRecoilValue(payment);
    const leadState = useRecoilValue(lead);
    const currentLocale = useRecoilValue(locale);
    const isPaymentCaptureDisabledState = useRecoilValue(
        isPaymentCaptureDisabled
    );
    const resetWorkflow = useResetWorkflow();

    useEffect(() => {
        // clear query params in case of user reload
        window.history.replaceState(null, null, window.location.pathname);
        window.onpopstate = () => {
            // when user attempts to go back, reset all the workflow data
            resetWorkflow();
            window.onpopstate = undefined; // reset after triggering once
        };
    }, [resetWorkflow]);

    const title = () => {
        if (selectedServiceTypeCode === 'MI') {
            return t('confirmation.shipped_title');
        } else {
            return t('confirmation.title');
        }
    };

    return (
        <section className="confirmation-page page -no-bottom-margin">
            <StepHeader title={title()} />

            <Content>
                <div className="container">
                    <div className="column-left">
                        {selectedServiceTypeCode === 'RT' &&
                            !isPaymentCaptureDisabledState && (
                                <div className="deposit">
                                    <Text
                                        className="deposit-text"
                                        size={3}
                                        as="div"
                                    >
                                        {t(
                                            'confirmation.repair_deposit_submitted'
                                        )}
                                    </Text>
                                    <Icon
                                        name="CheckCircle"
                                        color={colors.successDeep}
                                        size={20}
                                    />
                                    <Text
                                        size={3}
                                        weight="heavy"
                                        className="money"
                                        as="div"
                                    >
                                        {formatPrice(
                                            currentLocale.explicit,
                                            paymentState.currency,
                                            paymentState.amount / 100
                                        )}
                                    </Text>
                                </div>
                            )}

                        {selectedServiceTypeCode !== 'MI' && (
                            <div className="section">
                                <AppointmentCard />
                            </div>
                        )}

                        {!!leadState.shipping_label && (
                            <div className="section">
                                <ShippingCard
                                    shipping={leadState.shipping_label}
                                />
                            </div>
                        )}

                        <div className="section appointment-location">
                            <RepairLocationCard />
                        </div>
                    </div>

                    <div className="separator" />

                    <div className="column-right">
                        {selectedServiceTypeCode === 'CC' && (
                            <div className="section">
                                <CarryInContent />
                            </div>
                        )}

                        {selectedServiceTypeCode === 'MI' && (
                            <div className="section">
                                <MailInContent />
                            </div>
                        )}

                        {selectedServiceTypeCode === 'RT' && (
                            <div className="section">
                                <RemoteTechContent
                                    amount={
                                        isPaymentCaptureDisabled
                                            ? paymentState.amount
                                            : null
                                    }
                                    currency={
                                        isPaymentCaptureDisabled
                                            ? paymentState.currency
                                            : null
                                    }
                                />
                            </div>
                        )}
                    </div>
                </div>

                <AsurionExpertsCard />
            </Content>
        </section>
    );
};

const Content = styled.div`
    .container {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 1.25rem;
        padding: 0 1rem;

        ${(props) => props.theme.mediaQuery(props.theme.breakpoints.medium)} {
            grid-template-columns: 1fr 1fr;
            grid-gap: 3rem;
        }
    }

    .section {
        margin-bottom: 2rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .separator {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
        border-bottom: 1px solid
            ${(props) => props.theme.colors.neutralBrighter};

        ${(props) => props.theme.mediaQuery(props.theme.breakpoints.medium)} {
            display: none;
        }
    }

    .text-description {
        margin: 1.5rem 0;
        line-height: 1.3;
    }

    // Similar styling to billing page
    .deposit {
        display: flex;
        align-items: center;
        padding: 1.3rem 0;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        margin-bottom: 2rem;
    }

    .money {
        margin-left: auto;
    }

    .deposit-text {
        margin-right: 0.75rem;
    }
`;

export default Confirmation;
