import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useSetRecoilState, useRecoilValueLoadable } from 'recoil';
import { Dropdown, Text } from '@soluto-private/mx-asurion-ui-react';
import {
    devicesState,
    deviceId,
    selectedDevice,
} from 'state/ApplicationRecoil';
import { locales, rootDeviceTypes } from 'utils/constants';
import { findDeviceByParentId } from 'utils/helpers';

const DeviceFields = () => {
    const { t, i18n } = useTranslation('common');
    const [deviceSelectionFieldOptions, setDeviceSelectionFieldOptions] =
        useState([]);
    const setDeviceId = useSetRecoilState(deviceId);
    const devices = useRecoilValueLoadable(devicesState);
    const device = useRecoilValueLoadable(selectedDevice);

    const handleChange = (e) => {
        setDeviceId(e.target.value);
    };

    const selectedOption = (field) => {
        const matchedOption = deviceSelectionFieldOptions.find(
            (deviceType) => deviceType.parent_device_type_id === field.id
        );

        if (matchedOption) {
            return matchedOption.id;
        } else {
            return false;
        }
    };

    const deviceTypeStructure = (deviceType, locale = 'us_en') => {
        const fields = () => {
            if (deviceType.children) {
                return deviceType.children.map((child) => {
                    return {
                        ...child,
                        value: child.id,
                        name: child.name[locale],
                    };
                });
            } else {
                return [];
            }
        };

        return {
            children: fields(),
            id: deviceType.id,
            is_bottom_level: deviceType.is_bottom_level,
            label:
                rootDeviceTypes.indexOf(deviceType.id) > -1
                    ? deviceType.name[locale].toLowerCase()
                    : deviceType.name[locale],
            parent_device_type_id: deviceType.parent_device_type_id,
            root_parent_device_type_id: deviceType.root_parent_device_type_id,
        };
    };

    useEffect(() => {
        let deviceTree = [];

        if (device.state === 'hasValue' && devices.contents) {
            let currentDeviceType = device.contents;

            deviceTree.push(
                deviceTypeStructure(
                    currentDeviceType,
                    locales[i18n.language.apiTranslationKey]
                )
            );

            while (
                currentDeviceType &&
                currentDeviceType.parent_device_type_id
            ) {
                currentDeviceType = findDeviceByParentId(
                    devices.contents,
                    currentDeviceType
                );

                if (
                    currentDeviceType !== undefined &&
                    !currentDeviceType.is_bottom_level
                ) {
                    deviceTree.unshift(
                        deviceTypeStructure(
                            currentDeviceType,
                            locales[i18n.language.apiTranslationKey]
                        )
                    );
                }
            }

            setDeviceSelectionFieldOptions(deviceTree);
        }
    }, [
        devices.contents,
        device.state,
        device.contents,
        i18n.language.apiTranslationKey,
    ]);

    return (
        <Collection>
            {deviceSelectionFieldOptions
                .filter((field) => !field.is_bottom_level)
                .map((field) => {
                    return (
                        <div key={field.id}>
                            <Text className="field-helper" size={3}>
                                {t('device_details.name', {
                                    name: field.label,
                                })}
                            </Text>
                            <Dropdown
                                label={`${t('actions.select')} ${field.label}`}
                                name={field.id}
                                onChange={handleChange}
                                disabled={devices.state === 'loading'}
                                value={selectedOption(field)}
                                options={[
                                    {
                                        name: `---`,
                                        value: field.id,
                                    },
                                    ...field.children.map((child) => {
                                        return {
                                            name: child.name,
                                            value: child.value,
                                        };
                                    }),
                                ]}
                            />
                        </div>
                    );
                })}
        </Collection>
    );
};

const Collection = styled.div`
    padding: 0 1rem;

    > * + * {
        margin-top: 2rem;
    }

    .field-helper {
        display: block;
        margin-bottom: 1rem;
    }
`;

export default DeviceFields;
