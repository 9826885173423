const siteUrl = `${process.env.REACT_APP_WEBSITE_URL}/us/es`;

const routes = {
    about: `${siteUrl}/compania/acerca-de`,
    account: `${siteUrl}/cuenta`,
    blog: `${process.env.REACT_APP_WEBSITE_URL}/blog`,
    careers: `${process.env.REACT_APP_WEBSITE_URL}/careers`,
    cell_phone: `${siteUrl}/reparacion-de-celulares`,
    computer: `${siteUrl}/reparacion-de-computadora`,
    contact: `${siteUrl}/contacto`,
    device_tracking: `${siteUrl}/seguimiento-dispositivo`,
    faq: `${siteUrl}/preguntas-frecuentes`,
    feedback: `${siteUrl}/opinion`,
    franchising: 'https://franchising.ubreakifix.com/',
    game_console: `${siteUrl}/reparacion-de-consolas-de-juegos`,
    gift_card_balance: `${siteUrl}/saldo-de-tarjeta-regalo`,
    google_repair: `${siteUrl}/reparacion-de-google`,
    home: `${siteUrl}/`,
    iphone_repair: `${siteUrl}/reparacion-de-iphone`,
    local_area: 'https://local.ubreakifix.com/',
    locations: `${siteUrl}/ubicaciones`,
    other: `${siteUrl}/otro`,
    partnerships: `${siteUrl}/empresa-y-educacion`,
    press: `${siteUrl}/compania/prensa`,
    privacy_policy: `${siteUrl}/politica-de-privacidad`,
    repairs: `${siteUrl}/reparaciones`,
    samsung_repair: `${siteUrl}/reparacion-de-samsung`,
    search: `${siteUrl}/buscar`,
    sitemap: `${siteUrl}/mapa-del-sitio`,
    tablet: `${siteUrl}/reparacion-de-tabletas`,
    terms_conditions: `${siteUrl}/terminos-y-condiciones`,
};

export default routes;
