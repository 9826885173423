import React from 'react';

export const Close: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <line
                style={{
                    fill: 'none',
                    stroke: 'currentColor',
                    strokeLinecap: 'round',
                    strokeLinejoin: 'round',
                    strokeWidth: '1.5px',
                }}
                x1="0.75"
                y1="23.249"
                x2="23.25"
                y2="0.749"
            />
            <line
                style={{
                    fill: 'none',
                    stroke: 'currentColor',
                    strokeLinecap: 'round',
                    strokeLinejoin: 'round',
                    strokeWidth: '1.5px',
                }}
                x1="23.25"
                y1="23.249"
                x2="0.75"
                y2="0.749"
            />
        </svg>
    );
};
