import { useCallback, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import format from 'date-fns/format';
import addDays from 'date-fns/addDays';
import { getBookingWindows } from 'utils/api';
import { bookingWindow, bookingWindows } from 'state/ApplicationRecoil';
import { useLeadSubmission } from 'hooks';

const useCheckBookingWindow = () => {
    const { getLeadSubmissionPayload } = useLeadSubmission();
    const setBookingWindows = useSetRecoilState(bookingWindows);
    const selectedBookingWindow = useRecoilValue(bookingWindow);
    const [loading, setIsLoading] = useState(false);

    const availableBookingWindows = useCallback(
        async (postalCode) => {
            const today = new Date();
            const payload = getLeadSubmissionPayload();
            if (!postalCode) {
                postalCode = payload.customer.postal_code;
            }

            setIsLoading(true);

            const {
                data: { data },
            } = await getBookingWindows({
                quote_data: {
                    quote_range_start: format(today, 'yyyy-LL-dd'),
                    quote_range_end: format(addDays(today, 28), 'yyyy-LL-dd'),
                    postal_code: postalCode,
                    partner_data: {
                        device_repair_id: payload.device_repair_id,
                        device_type_option_ids:
                            payload.partner_data.device_type_option_ids,
                    },
                },
            });

            setIsLoading(false);

            const slots = data?.available_windows;

            // Setting newly obtained booking windows
            if (slots) {
                setBookingWindows(slots);

                return slots;
            } else {
                // Data will contain an error object
                throw data;
            }
        },
        [getLeadSubmissionPayload, setBookingWindows]
    );

    const checkBookingWindowAvailability = useCallback(
        async (postalCode) => {
            const slots = await availableBookingWindows(postalCode);

            // Find matching booking window
            const matchingWindow = slots.find((bookingWindow) => {
                return (
                    bookingWindow.window_start ===
                        selectedBookingWindow.window_start &&
                    bookingWindow.window_end ===
                        selectedBookingWindow.window_end
                );
            });

            return matchingWindow;
        },
        [
            availableBookingWindows,
            selectedBookingWindow.window_end,
            selectedBookingWindow.window_start,
        ]
    );

    return {
        availableBookingWindows,
        checkBookingWindowAvailability,
        loading,
    };
};

export { useCheckBookingWindow };
