import { Text, Link } from '@soluto-private/mx-asurion-ui-react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import {
    storeId,
    customerDetails,
    locale,
    appointmentIntent,
    pricing,
} from 'state/ApplicationRecoil';
import RepairLocationDetails from './RepairLocationDetails';
import { Icon } from 'components/Icons';
import { appointmentIntentRequest } from 'utils/api';
import PricingDetails from './PricingDetails';

const CarryInDetails = () => {
    const { t } = useTranslation('common');
    const [customerDetailsState, setCustomerDetails] =
        useRecoilState(customerDetails);
    const resetStore = useResetRecoilState(storeId);
    const localeData = useRecoilValue(locale);
    const [intent, setIntent] = useRecoilState(appointmentIntent);
    const pricingState = useRecoilValue(pricing);

    const resetAppointmentTime = () => {
        setCustomerDetails((customerData) => {
            return {
                ...customerData,
                appointmentTime: null,
            };
        });
        if (intent) {
            appointmentIntentRequest({
                delete: true,
                time: intent.time,
                store_id: intent.storeId,
                intent_id: intent.intentId,
            }).then(() => {
                setIntent(null);
            });
        }
    };

    const handleAppointmentTimeReset = (e) => {
        e.preventDefault();

        resetAppointmentTime();
    };

    const handleStoreReset = () => {
        resetStore();
        resetAppointmentTime();
    };

    return (
        <>
            <div className="appointment-details">
                <div className="section">
                    <Text
                        className="section-header"
                        weight="heavy"
                        size="3"
                        as="div"
                    >
                        {t('appointment_time')}
                    </Text>

                    <div className="section-body">
                        <Icon className="icon" name="Calendar" size={41} />

                        <div>
                            <Text as="div">
                                {format(
                                    new Date(
                                        customerDetailsState.appointmentTime
                                    ),
                                    'ccc, MMMM d, y',
                                    {
                                        locale: localeData.dateLocale.default,
                                    }
                                )}
                                <br />
                                {format(
                                    new Date(
                                        customerDetailsState.appointmentTime
                                    ),
                                    'haaa',
                                    {
                                        locale: localeData.dateLocale.default,
                                    }
                                )}
                            </Text>

                            <Link
                                href="#"
                                color="secondary"
                                onClick={handleAppointmentTimeReset}
                                className="action-link"
                            >
                                {t('actions.change_time')}
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="line" />

                <div className="section">
                    <RepairLocationDetails
                        allowChangingStore={true}
                        reset={handleStoreReset}
                    />
                </div>
            </div>

            {pricingState.price && (
                <div className="row row--callout">
                    <PricingDetails />
                </div>
            )}
        </>
    );
};

export default CarryInDetails;
