import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { locales } from 'utils/constants';
import { addDays, getMonth, eachWeekOfInterval, getWeek } from 'date-fns';

interface MonthSectionProps {
    month: string;
    monthTitle: string;
    days: Date[];
    activeDate: Date;
    onChange: (value?: unknown) => void;
    isDateAvailable: (date: Date) => boolean;
}

const MonthSectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    height: 100%;

    ${(props) => props.theme.mediaQuery(props.theme.breakpoints.medium)} {
        &:nth-child(2) {
            margin: 0px 20px;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }
`;

const MonthTitle = styled.p`
    font-size: 20px;
    line-height: 150%;
    justify-self: flex-start;
    width: 100%;
    margin-bottom: 20px;
`;

const WeekContainer = styled.div`
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    margin-bottom: 10px;
    & > * {
        margin-right: 5px;
    }
`;

const EmptyButton = styled.div`
    min-width: 40px;
    min-height: 40px;
`;

const Day = styled.p`
    font-size: 16px;
    justify-self: center;
    align-self: center;
    min-width: 40px;
    min-height: 40px;
    text-align: center;
`;

const DateButton = styled.button<{ active: boolean }>`
    min-width: 40px;
    min-height: 40px;
    border: ${(props) =>
        props.active ? '1px solid #000' : '1px solid #d5d6da'};
    border-radius: 3px;
    color: ${(props) => props.theme.colors.black};
    :disabled {
        color: #d5d6da;
    }
`;

enum MonthToNumbers {
    January = 0,
    February = 1,
    March = 2,
    April = 3,
    May = 4,
    June = 5,
    July = 6,
    August = 7,
    September = 8,
    October = 9,
    November = 10,
    December = 11,
}

export const MonthSection: React.FC<MonthSectionProps> = ({
    month,
    monthTitle,
    days,
    activeDate,
    onChange,
    isDateAvailable,
}: MonthSectionProps) => {
    const { t, i18n } = useTranslation('common');

    const daysOfMonth = React.useMemo(() => {
        const dateArr: { date: Date; disabled: boolean; dateString: string }[] =
            [];

        const daysByMonth = days.filter((day) => {
            // @ts-ignore
            return getMonth(day) === MonthToNumbers[month];
        });

        let firstDate = new Date(
            daysByMonth[0].getFullYear(),
            // @ts-ignore
            MonthToNumbers[month],
            1
        );

        // @ts-ignore
        while (firstDate.getMonth() === MonthToNumbers[month]) {
            const localeDateString = firstDate.toLocaleDateString('en-US', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });
            const dateString = `${localeDateString.substring(
                6
            )}${localeDateString.substring(0, 2)}${localeDateString.substring(
                3,
                5
            )}`;

            const disabled = !isDateAvailable(firstDate);

            dateArr.push({ date: new Date(firstDate), disabled, dateString });

            firstDate = addDays(firstDate, 1);
        }

        return dateArr;
    }, [month, days, isDateAvailable]);

    const weekNumbers = React.useMemo(() => {
        const firstDate = daysOfMonth[0];
        const lastDate = daysOfMonth[daysOfMonth.length - 1];

        return eachWeekOfInterval({
            start: firstDate.date,
            end: lastDate.date,
        }).map((firstDayOfWeek) => {
            return getWeek(firstDayOfWeek);
        });
    }, [daysOfMonth]);

    let index = 0;

    const iterateThroughWeek = (week: number): (JSX.Element | undefined)[] => {
        return [0, 1, 2, 3, 4, 5, 6].map((num) => {
            if (index >= daysOfMonth.length) {
                return undefined;
            }
            const weekFromDay = getWeek(daysOfMonth[index].date);
            const dayOfMonth = daysOfMonth[index].date.getDay();

            if (dayOfMonth === num && weekFromDay === week) {
                const oldIndex = index;

                if (index <= daysOfMonth.length) {
                    index++;
                }

                return (
                    <DateButton
                        key={`${week}-${daysOfMonth[oldIndex].dateString}`}
                        active={
                            activeDate.toString() ===
                            daysOfMonth[oldIndex].date.toString()
                        }
                        disabled={daysOfMonth[oldIndex].disabled}
                        onClick={() => onChange(daysOfMonth[oldIndex].date)}
                    >
                        {daysOfMonth[oldIndex].date.toLocaleString(
                            // @ts-ignore
                            locales[i18n.language].explicit,
                            {
                                day: '2-digit',
                            }
                        )}
                    </DateButton>
                );
            }

            return <EmptyButton key={`${week}-${num}`} />;
        });
    };

    return (
        <MonthSectionContainer>
            <MonthTitle>{monthTitle}</MonthTitle>
            <WeekContainer>
                <Day>{t('Sun').charAt(0)}</Day>
                <Day>{t('Mon').charAt(0)}</Day>
                <Day>{t('Tue').charAt(0)}</Day>
                <Day>{t('Wed').charAt(0)}</Day>
                <Day>{t('Thu').charAt(0)}</Day>
                <Day>{t('Fri').charAt(0)}</Day>
                <Day>{t('Sat').charAt(0)}</Day>
            </WeekContainer>
            {weekNumbers.map((week: number) => (
                <WeekContainer key={`${month}-${week}`}>
                    {iterateThroughWeek(week)}
                </WeekContainer>
            ))}
        </MonthSectionContainer>
    );
};
