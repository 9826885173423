import { useEffect } from 'react';
import {
    Text,
    TextField,
    Button,
    Checkbox,
    colors,
} from '@soluto-private/mx-asurion-ui-react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useRecoilValue, useRecoilState } from 'recoil';
import styled from 'styled-components';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLeadSubmission, useValidationSchema, useScrollToTop } from 'hooks';
import {
    customerDetails,
    isPaymentCaptureDisabled,
    pricing,
    serviceOption,
    validServiceTypeCode,
    selectedRepair,
    selectedDevice,
    deviceColor,
    storeId,
    locale,
} from 'state/ApplicationRecoil';
import StepHeader from '../../StepHeader';
import Loader from 'components/Loader';
import CarryInDetails from './CarryInDetails';
import RemoteTechDetails from './RemoteTechDetails';

const ContactDetails = () => {
    useScrollToTop();

    const { t } = useTranslation(['common', 'attributes', 'routes']);
    const { contactFormSchema } = useValidationSchema();
    const selectedServiceTypeCode = useRecoilValue(validServiceTypeCode);
    const device = useRecoilValue(selectedDevice);
    const repair = useRecoilValue(selectedRepair);
    const color = useRecoilValue(deviceColor);
    const store = useRecoilValue(storeId);
    const currentLocale = useRecoilValue(locale);
    const rtServiceOption = useRecoilValue(serviceOption('RT'));
    const [customerDetailsState, setCustomerDetails] =
        useRecoilState(customerDetails);
    const [isPaymentCaptureDisabledState, setIsPaymentCaptureDisabled] =
        useRecoilState(isPaymentCaptureDisabled);
    const pricingState = useRecoilValue(pricing);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(contactFormSchema),
    });
    const { submitLead, leadSubmissionState } = useLeadSubmission();

    const submitForm = (values) => {
        setCustomerDetails((prevState) => ({
            ...prevState,
            ...values,
            hasValidDetails: true,
        }));

        if (
            selectedServiceTypeCode === 'CC' ||
            (selectedServiceTypeCode === 'RT' && isPaymentCaptureDisabledState)
        ) {
            submitLead();
        }
    };

    // Push to gtm datalayer
    useEffect(() => {
        Array.isArray(window.dataLayer) &&
            window.dataLayer.push({
                deviceColor: color,
                deviceId: device.id,
                deviceModel: device.name['us_en'],
                deviceRepair: repair.name['us_en'],
                deviceType: device.root_parent_device_type_id,
                locale: currentLocale.explicit,
                peril: repair.repair_type.name['us_en'],
                repairId: repair.id,
                repairMethod: selectedServiceTypeCode,
                storeId: store,
                event: 'repairData',
            });
    }, [color, device, repair, currentLocale, selectedServiceTypeCode, store]);

    // Check disable_payment_capture flag here - will alter how submission works
    useEffect(() => {
        setIsPaymentCaptureDisabled(rtServiceOption.disable_payment_capture);
    }, [setIsPaymentCaptureDisabled, rtServiceOption]);

    const handleFieldUpdate = (e) => {
        setCustomerDetails({
            ...customerDetailsState,
            [e.target.name]: e.target.value,
        });
    };

    const handleCheckboxToggle = (e) => {
        setCustomerDetails({
            ...customerDetailsState,
            [e.target.name]: e.target.checked,
        });
    };

    const canCreateLead =
        selectedServiceTypeCode === 'CC' || isPaymentCaptureDisabledState;

    return (
        <div className="page contact-details-page">
            <StepHeader
                title={
                    selectedServiceTypeCode === 'CC'
                        ? t('contact_details.last_step_title')
                        : t('contact_details.title')
                }
                description={t('contact_details.description')}
            />

            <Container>
                <form onSubmit={handleSubmit(submitForm)}>
                    <div className="section">
                        <div className="field-group">
                            <div className="field">
                                <TextField
                                    {...register('givenName')}
                                    defaultValue={
                                        customerDetailsState.givenName
                                    }
                                    label={t('attributes:given_name')}
                                    fieldStatus={
                                        errors.givenName ? 'error' : 'default'
                                    }
                                    helperText={errors.givenName?.message}
                                    onInput={handleFieldUpdate}
                                />
                            </div>
                            <div className="field">
                                <TextField
                                    {...register('familyName')}
                                    defaultValue={
                                        customerDetailsState.familyName
                                    }
                                    label={t('attributes:family_name')}
                                    fieldStatus={
                                        errors.familyName ? 'error' : 'default'
                                    }
                                    helperText={errors.familyName?.message}
                                    onInput={handleFieldUpdate}
                                />
                            </div>
                        </div>

                        <div className="field-group field-group--respond">
                            <div className="field">
                                <TextField
                                    {...register('email')}
                                    defaultValue={customerDetailsState.email}
                                    label={t('attributes:email')}
                                    fieldStatus={
                                        errors.email ? 'error' : 'default'
                                    }
                                    helperText={errors.email?.message}
                                    onInput={handleFieldUpdate}
                                />
                            </div>
                            <div className="field">
                                <TextField
                                    {...register('phone')}
                                    defaultValue={customerDetailsState.phone}
                                    label={t('attributes:phone')}
                                    fieldStatus={
                                        errors.phone ? 'error' : 'default'
                                    }
                                    helperText={errors.phone?.message}
                                    onInput={handleFieldUpdate}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="section">
                        <Text className="section-header" as="div">
                            {t('contact_details.contact_me_by')}
                        </Text>

                        <div className="checkbox-group">
                            <Checkbox
                                {...register('canCall')}
                                defaultChecked={customerDetailsState.canCall}
                                label={t('phone_call')}
                                onClick={handleCheckboxToggle}
                            />
                            <Checkbox
                                {...register('canEmail')}
                                defaultChecked={customerDetailsState.canEmail}
                                label={t('attributes:email')}
                                onClick={handleCheckboxToggle}
                            />
                            <Checkbox
                                {...register('canSms')}
                                defaultChecked={customerDetailsState.canSms}
                                label={t('sms')}
                                onClick={handleCheckboxToggle}
                            />
                        </div>

                        {errors.preferredContactMethods && (
                            <Text color={colors.errorDeep}>
                                {errors.preferredContactMethods?.message}
                            </Text>
                        )}
                    </div>

                    {selectedServiceTypeCode === 'CC' && <CarryInDetails />}

                    {selectedServiceTypeCode === 'RT' && <RemoteTechDetails />}

                    <div className="row">
                        <Button
                            type="submit"
                            className="btn"
                            disabled={leadSubmissionState.loading}
                        >
                            {canCreateLead
                                ? t('confirm_appointment')
                                : t('actions.continue')}
                        </Button>
                    </div>

                    {leadSubmissionState.loading && <Loader />}
                </form>

                <div className="row">
                    <Text size={0} className="terms" as="div">
                        {t('contact_details.terms')}{' '}
                        <Trans
                            t={t}
                            i18nKey="see_privacy_terms_for_more_detail"
                        >
                            Please see our
                            <a
                                href={t('routes:privacy_policy')}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Privacy Policy
                            </a>
                            and
                            <a
                                href={t('routes:terms_conditions')}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Terms &amp; Conditions
                            </a>
                            for more detail.
                        </Trans>
                    </Text>

                    {pricingState.price && (
                        <Text size={0} className="terms" as="div">
                            {t('contact_details.pricing_terms')}
                        </Text>
                    )}
                </div>
            </Container>
        </div>
    );
};

const Container = styled.div`
    .terms {
        margin-top: 1rem;
    }

    .row {
        padding: 0 1rem;

        &.row--callout {
            padding: 0 0.5rem;
        }
    }

    .section {
        padding: 0 1rem;
        margin: 2rem 0;
        position: relative;
    }

    .section-header {
        margin-bottom: 1rem;
    }

    .section-body {
        display: flex;
    }

    .field {
        width: 100%;
        margin-right: 1rem;

        &:last-child {
            margin-right: 0;
        }
    }

    .field-group {
        display: flex;
        margin-bottom: 1rem;

        &.field-group--respond {
            display: block;

            .field {
                margin-bottom: 1rem;
            }

            ${(props) =>
                props.theme.mediaQuery(props.theme.breakpoints.medium)} {
                display: flex;

                .field {
                    margin-bottom: 0;
                }
            }
        }
    }

    .checkbox-group {
        margin-left: -0.5rem;

        > label {
            margin-bottom: 1rem;
            margin-right: 2rem;

            &:last-of-type {
                margin-right: 0;
            }
        }
    }

    .action-link {
        font-size: 0.875rem;
        position: absolute;
        right: 1rem;
        top: 0;
    }

    .icon {
        display: block;
        margin-right: 1rem;
    }

    .line {
        margin: 2rem 1rem;
        border-bottom: 1px solid #999999;

        ${(props) => props.theme.mediaQuery(props.theme.breakpoints.medium)} {
            display: none;
        }
    }

    .btn {
        width: 100%;
        margin-bottom: 2rem;
        margin-top: 2rem;

        ${(props) => props.theme.mediaQuery(props.theme.breakpoints.medium)} {
            min-width: 344px;
            margin-left: auto;
            margin-right: auto;
            width: auto;
        }
    }

    ${(props) => props.theme.mediaQuery(props.theme.breakpoints.medium)} {
        .appointment-details {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 1rem;
        }

        .action-link {
            margin-top: 0.5rem;
            position: static;
        }
    }
`;

export default ContactDetails;
