import { useTheme } from '@soluto-private/mx-asurion-ui-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Loader } from '@googlemaps/js-api-loader';

const GoogleMap = ({ locations, center }) => {
    const loader = new Loader({
        apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
        version: 'weekly',
        libraries: ['places'],
    });

    const mapOptions = {
        center: center,
        zoom: 10,
        streetViewControl: false,
        disableDefaultUI: true,
    };

    const colors = useTheme('asurion');

    loader
        .load()
        .then((google) => {
            const map = new google.maps.Map(
                document.getElementById('map'),
                mapOptions
            );

            const iconSVG = {
                path: `M13.04,41.77c-0.11-1.29-0.35-3.2-0.99-5.42c-0.91-3.17-4.74-9.54-5.49-10.79c-3.64-6.1-5.46-9.21-5.45-12.07
                    c0.03-4.57,2.77-7.72,3.21-8.22c0.52-0.58,4.12-4.47,9.8-4.17c4.73,0.24,7.67,3.23,8.45,4.07c0.47,0.51,3.22,3.61,3.31,8.11
                    c0.06,3.01-1.89,6.26-5.78,12.77c-0.18,0.3-4.15,6.95-5.1,10.26c-0.64,2.24-0.89,4.17-1,5.48C13.68,41.78,13.36,41.78,13.04,41.77z
                    `,
                fillColor: `${colors.primary}`,
                fillOpacity: 1,
                strokeColor: '#ffffff',
                strokeWeight: 1,
                anchor: new google.maps.Point(14, 43),
                labelOrigin: new google.maps.Point(13.5, 15),
            };

            for (let i = 0; i < locations.length; i++) {
                new google.maps.Marker({
                    position: new google.maps.LatLng(
                        locations[i].lat,
                        locations[i].lng
                    ),
                    map: map,
                    icon: iconSVG,
                    title: locations[i].name,
                    opacity: 0.9,
                    label: {
                        fontSize: '0.75rem',
                        fontWeight: '800',
                        text: String(i + 1),
                        color: '#ffffff',
                    },
                });
            }
        })
        .catch((e) => {
            console.log(e);
        });

    return <Map id="map"></Map>;
};

GoogleMap.propTypes = {
    locations: PropTypes.array,
    center: PropTypes.object,
};

const Map = styled.div`
    width: 100%;
    height: 350px;
    margin-bottom: 2rem;
    order: 2;
`;

export default GoogleMap;
