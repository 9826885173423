import styled from 'styled-components';
import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { serviceTypeCode } from 'state/ApplicationRecoil';
import { Text } from '@soluto-private/mx-asurion-ui-react';
import optionCarryIn from 'images/option-carry-in.jpeg';
import optionRemoteTech from 'images/option-remote-tech.jpeg';
import optionMailIn from 'images/option-mail-in.jpeg';
import SelectionCard from '../SelectionCard';
export default function ServiceOptionsCards({
    rtAvailable,
    ccAvailable,
    miAvailable,
}) {
    const setSelectedServiceType = useSetRecoilState(serviceTypeCode);
    const { t } = useTranslation('common');

    const handleSelection = (e, code) => {
        e.preventDefault();

        setSelectedServiceType(code);
    };

    return (
        <Options className="service-option">
            {ccAvailable && (
                <SelectionCard
                    image={optionCarryIn}
                    onClick={(e) => handleSelection(e, 'CC')}
                >
                    <div className="card-content">
                        <Text size={3} as="h2" className="card-title">
                            {t('service_type.carry_in')}
                        </Text>

                        <Text className="description" as="p" size={2}>
                            {t('service_options.carry_in')}
                        </Text>
                    </div>
                </SelectionCard>
            )}

            {rtAvailable && (
                <SelectionCard
                    image={optionRemoteTech}
                    onClick={(e) => handleSelection(e, 'RT')}
                >
                    <div className="card-content">
                        <Text size={3} as="h2" className="card-title">
                            {t('service_type.remote_tech')}
                        </Text>

                        <Text className="description" as="p" size={2}>
                            {t('service_options.remote_tech')}
                        </Text>
                    </div>
                </SelectionCard>
            )}

            {miAvailable && (
                <SelectionCard
                    title="Mail-in Repair"
                    image={optionMailIn}
                    onClick={(e) => handleSelection(e, 'MI')}
                >
                    <div className="card-content">
                        <Text size={3} as="h2" className="card-title">
                            {t('service_type.mail_in')}
                        </Text>

                        <Text className="description" as="p" size={2}>
                            {t('service_options.mail_in')}
                        </Text>
                    </div>
                </SelectionCard>
            )}
        </Options>
    );
}

const Options = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    padding: 0 1rem;

    .description {
        margin-bottom: 0.5rem;
    }

    .card-container {
        padding: 1rem;
    }

    .card-title {
        margin-bottom: 1rem;
        margin-top: 0;
    }

    .card-content {
        padding-right: 1rem;
        width: 100%;
    }

    .icon {
        margin-right: 0.5rem;
    }

    @media only screen and (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
    }

    @media only screen and (min-width: 1200px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
`;
