import { useState } from 'react';
import qs from 'query-string';
import * as yup from 'yup';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TextField, Button } from '@soluto-private/mx-asurion-ui-react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { yupResolver } from '@hookform/resolvers/yup';
import { getPhotoboothImages } from 'utils/api';
import { useAlert } from 'components/Alert';
import StepHeader from 'components/StepHeader';
import Loader from 'components/Loader';

const Photobooth = (props) => {
    const location = useLocation();
    const query = qs.parse(location.search);

    const { t } = useTranslation([
        'common',
        'validation',
        'attributes',
        'errors',
    ]);
    const { error: alertError } = useAlert();

    let [urlsImages, setUrlsImages] = useState([]);
    let [isSubmitted, setIsSubmitted] = useState(false);
    let [isLoading, setIsLoading] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(
            yup.object().shape({
                phone: yup
                    .string()
                    .required(
                        t('validation:required', {
                            attribute: t('attributes:phone'),
                        })
                    )
                    .transform(function (value) {
                        return value.replace(/\D/g, '');
                    })
                    .length(10, t('validation:phone_number')),
            })
        ),
    });

    let formData = {
        work_order_guid: query.uid,
        direction: query.d,
    };

    const submitForm = async (values) => {
        try {
            formData.phone = values.phone;

            setIsLoading(true);
            const {
                data: { data },
            } = await getPhotoboothImages(formData);

            setIsLoading(false);

            if (data && data.photo_booth_image_urls) {
                setUrlsImages(data.photo_booth_image_urls);
            }

            setIsSubmitted(true);
        } catch (err) {
            setIsLoading(false);
            alertError(t('errors:something_went_wrong'));
        }
    };

    return (
        <PhotoboothContainer className="page photobooth-page">
            <StepHeader
                title={
                    urlsImages.length === 0
                        ? 'Repair photos'
                        : query.d === 'in'
                        ? 'Your Device, Pre-repair'
                        : 'Your Device, Now Picture Perfect'
                }
                description={
                    !isSubmitted
                        ? 'Please enter your phone number to see images of your phone ' +
                          (query.d === 'in' ? 'pre-repair' : 'post-repair')
                        : ''
                }
            />

            {urlsImages.length === 0 && !isSubmitted && (
                <form onSubmit={handleSubmit(submitForm)}>
                    <div className="field">
                        <TextField
                            {...register('phone')}
                            label={t('attributes:phone')}
                            fieldStatus={errors.phone ? 'error' : 'default'}
                            helperText={errors.phone?.message}
                        />
                    </div>
                    <Button type="submit" className="btn">
                        Submit
                    </Button>
                </form>
            )}

            {isLoading && <Loader />}
            {urlsImages.length === 0 && isSubmitted && (
                <div>
                    There was no record found. Please make sure the phone number
                    you entered is correct and that you copied/pasted the URL
                    correctly.
                </div>
            )}

            {urlsImages.length > 0 && isSubmitted && (
                <div className="photoBooth">
                    {urlsImages.map((url, index) => (
                        <a
                            key={index}
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={url} alt="photobooth images" />
                        </a>
                    ))}
                </div>
            )}
        </PhotoboothContainer>
    );
};

const PhotoboothContainer = styled.div`
    padding: 0 1rem;

    .field {
        width: 100%;
        margin: 1rem auto;
        max-width: 500px;
    }

    .btn {
        width: 100%;
        margin-bottom: 2rem;
        margin-top: 2rem;

        ${(props) => props.theme.mediaQuery(props.theme.breakpoints.medium)} {
            min-width: 344px;
            margin-left: auto;
            margin-right: auto;
            width: auto;
        }
    }

    .photoBooth {
        margin-bottom: 5%;
        padding: 2rem;
        text-align: center;
        a:nth-child(5n + 1) img {
            transform: rotate(10deg);
        }
        a:nth-child(5n + 2) img {
            transform: rotate(-2deg);
        }
        h2 {
            margin-bottom: 50px;
        }
        img {
            width: 70%;
            display: inline;
            margin-right: 20px;
            overflow-x: scroll;
            padding: 0.8rem 0.8rem 2.5rem 0.8rem;
            box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
            background: white;
        }
    }
`;
export default Photobooth;
