import { useEffect } from 'react';
import { useAlert } from 'components/Alert';

/**
 * Use alerts as simple components.
 * example:
 * {error && <SimpleAlert error message={error} />}
 *
 * The alert will be automatically cleared upon unmouting the component.
 */
const SimpleAlert = ({ message, error, success, info, warn }) => {
    const {
        error: _error,
        success: _success,
        info: _info,
        warn: _warn,
    } = useAlert();

    useEffect(() => {
        const alert = (() => {
            if (error) return _error(message);
            if (success) return _success(message);
            if (warn) return _warn(message);
            return _info(message);
        })();
        return alert.close;
    }, [message, error, success, info, warn, _error, _success, _info, _warn]);

    return <></>;
};

export default SimpleAlert;
