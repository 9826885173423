import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import { Dropdown, Text } from '@soluto-private/mx-asurion-ui-react';
import {
    addDays,
    eachDayOfInterval,
    startOfWeek,
    endOfWeek,
    format,
    isSameDay,
} from 'date-fns';
import {
    bookingWindow,
    bookingWindows,
    serviceOption,
} from 'state/ApplicationRecoil';
import { formatBookingWindowSlot } from 'utils/helpers';
import StepHeader from 'components/StepHeader';
import Loader from 'components/Loader';
import { DatePicker } from 'components/DatePicker';
import { useScrollToTop } from 'hooks';

const SelectBookingWindow = () => {
    useScrollToTop();

    const { t } = useTranslation('common');
    const [activeDay, setActiveDay] = useState();
    const [availableWindows, setBookingWindows] =
        useRecoilState(bookingWindows);
    const rtServiceOption = useRecoilValueLoadable(serviceOption('RT'));
    const [activeBookingWindow, setActiveBookingWindow] =
        useRecoilState(bookingWindow);

    const startDate = startOfWeek(new Date());
    const endDate = endOfWeek(addDays(new Date(), 28));

    const days = eachDayOfInterval({
        start: startDate,
        end: endDate,
    });

    useEffect(() => {
        if (
            rtServiceOption.state === 'hasValue' &&
            availableWindows === null &&
            rtServiceOption.contents
        ) {
            // Set booking windows based on rtServiceOption
            const slots = rtServiceOption.contents.available_windows;

            // We set the booking windows here so they can be managed separately
            // ie. setting them later through getBookingWindows
            setBookingWindows(slots);
        }
    }, [activeDay, availableWindows, rtServiceOption, setBookingWindows]);

    useEffect(() => {
        // Set the active day to closest booking window
        if (
            !activeDay &&
            rtServiceOption.contents &&
            rtServiceOption.contents.next_available
        ) {
            const day = rtServiceOption.contents.next_available.split(' ');

            // Give the next_available day correct formatting
            // Failure to do so will error on Safari, etc.
            selectDay(`${day[0]}T${day[1]}Z`);
        }
    }, [activeDay, rtServiceOption]);

    const selectBookingWindow = (bookingWindowId) => {
        setActiveBookingWindow(
            availableWindows.find(
                (slot) => slot.window_id === parseInt(bookingWindowId)
            )
        );
    };

    const selectDay = (dateString) => {
        setActiveDay(new Date(dateString));
    };

    const formattedSlots = (slots) => {
        return slots
            .map((slot) => {
                return formatBookingWindowSlot(slot);
            })
            .sort((a, b) => {
                return a.window_start - b.window_start;
            });
    };

    return (
        <Section className="page choose-appointment-time">
            <StepHeader
                title={t('schedule_your_repair')}
                description={t('phone_repair_description')}
            />

            <div className="row">
                {rtServiceOption.state === 'loading' && <Loader />}

                {activeDay &&
                    rtServiceOption.state === 'hasValue' &&
                    availableWindows && (
                        <>
                            <DatePicker
                                days={days}
                                setActiveDay={selectDay}
                                activeDay={activeDay}
                                timeslots={formattedSlots(availableWindows)}
                                allowSameDay={true}
                            />
                            <div>
                                <Text className="field-helper">
                                    {t(
                                        'schedule_repair.appointment_time_title'
                                    )}{' '}
                                    <Text weight="heavy">
                                        {format(activeDay, 'E M/d')}
                                    </Text>
                                    .
                                </Text>

                                <Dropdown
                                    label={t('actions.select_one')}
                                    name="available-times"
                                    id="available-times"
                                    onChange={(e) =>
                                        selectBookingWindow(e.target.value)
                                    }
                                    value={
                                        activeBookingWindow
                                            ? activeBookingWindow.window_id
                                            : ''
                                    }
                                    options={formattedSlots(availableWindows)
                                        .filter(
                                            (slot) =>
                                                isSameDay(
                                                    slot.window_start,
                                                    activeDay
                                                ) &&
                                                isSameDay(
                                                    slot.window_end,
                                                    activeDay
                                                )
                                        )
                                        .map((slot) => {
                                            return {
                                                name: `${format(
                                                    slot.window_start,
                                                    'p'
                                                )} - ${format(
                                                    slot.window_end,
                                                    'p'
                                                )}`,
                                                value: slot.window_id,
                                            };
                                        })}
                                />
                            </div>
                        </>
                    )}
            </div>
        </Section>
    );
};

const Section = styled.section`
    .field-helper {
        display: block;
        margin-bottom: 1rem;
    }

    .row {
        padding: 0 1rem;
    }
`;

export default SelectBookingWindow;
