const siteUrl = `${process.env.REACT_APP_WEBSITE_URL}/ca/fr`;

const routes = {
    about: `${siteUrl}/compagnie/propos`,
    account: `${siteUrl}/compte`,
    blog: `${process.env.REACT_APP_WEBSITE_URL}/blog`,
    careers: `${process.env.REACT_APP_WEBSITE_URL}/careers`,
    cell_phone: `${siteUrl}/reparation-de-telephone-intelligent`,
    computer: `${siteUrl}/reparation-dordinateur`,
    contact: `${siteUrl}/contactez`,
    device_tracking: `${siteUrl}/suivi-des-appareils`,
    faq: `${siteUrl}/faq`,
    feedback: `${siteUrl}/commentaires`,
    franchising: 'https://franchising.ubreakifix.com/ca-fr/',
    game_console: `${siteUrl}/reparation-de-console-de-jeux`,
    gift_card_balance: `${siteUrl}/solde-carte-cadeau`,
    google_repair: `${siteUrl}/reparation-de-google`,
    home: `${siteUrl}/`,
    iphone_repair: `${siteUrl}/reparation-diphone`,
    locations: `${siteUrl}/emplacements`,
    local_area: 'https://local.ubreakifix.com/',
    other: `${siteUrl}/autre`,
    partnerships: `${siteUrl}/les-affaires`,
    press: `${siteUrl}/compagnie/presse`,
    privacy_policy: `${siteUrl}/politique-de-confidentialite`,
    repairs: `${siteUrl}/reparations`,
    samsung_repair: `${siteUrl}/reparation-de-samsung`,
    search: `${siteUrl}/recherche`,
    sitemap: `${siteUrl}/plan-du-site`,
    tablet: `${siteUrl}/reparation-de-tablette`,
    terms_conditions: `${siteUrl}/termes-et-conditions`,
};

export default routes;
