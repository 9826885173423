import React from 'react';
import styled from 'styled-components';

import * as Icons from './components';

type IconNames = 'Calendar' | 'Close' | 'CheckCircle';

interface IconProps {
    name: IconNames;
    size?: number;
    color?: string;
    className?: string;
    style?: React.CSSProperties;
    iconProps?: Record<string, string>;
}

type StyledComponentsProps = Partial<IconProps>;

const IconContainer = styled.div<StyledComponentsProps>`
    display: inline-block;
    position: relative;
    width: ${(props) => props.size + 'px'};
    height: ${(props) => props.size + 'px'};
    & svg {
        width: 100%;
        height: auto;
        color: ${(props) => props.color};
    }
`;

export const Icon: React.FC<IconProps> = ({
    name,
    size = 24,
    color = '#000',
    className,
    style,
}: IconProps) => {
    const RenderedIcon: React.FC = Icons[name];

    if (!name || !RenderedIcon) {
        return null;
    }

    return (
        <IconContainer
            size={size}
            color={color}
            data-testid="icon"
            style={style}
            className={className}
        >
            <RenderedIcon />
        </IconContainer>
    );
};
