const enUS = require('date-fns/locale/en-US');
const es = require('date-fns/locale/es');
const enCA = require('date-fns/locale/en-CA');
const frCA = require('date-fns/locale/fr-CA');

const siteUrl = process.env.REACT_APP_WEBSITE_URL;

const states = [
    { name: 'Alabama', value: 'AL' },
    { name: 'Alaska', value: 'AK' },
    { name: 'Arizona', value: 'AZ' },
    { name: 'Arkansas', value: 'AR' },
    { name: 'California', value: 'CA' },
    { name: 'Colorado', value: 'CO' },
    { name: 'Connecticut', value: 'CT' },
    { name: 'Delaware', value: 'DE' },
    { name: 'District of Columbia', value: 'DC' },
    { name: 'Florida', value: 'FL' },
    { name: 'Georgia', value: 'GA' },
    { name: 'Hawaii', value: 'HI' },
    { name: 'Idaho', value: 'ID' },
    { name: 'Illinois', value: 'IL' },
    { name: 'Indiana', value: 'IN' },
    { name: 'Iowa', value: 'IA' },
    { name: 'Kansas', value: 'KS' },
    { name: 'Kentucky', value: 'KY' },
    { name: 'Louisiana', value: 'LA' },
    { name: 'Maine', value: 'ME' },
    { name: 'Maryland', value: 'MD' },
    { name: 'Massachusetts', value: 'MA' },
    { name: 'Michigan', value: 'MI' },
    { name: 'Minnesota', value: 'MN' },
    { name: 'Mississippi', value: 'MS' },
    { name: 'Missouri', value: 'MO' },
    { name: 'Montana', value: 'MT' },
    { name: 'Nebraska', value: 'NE' },
    { name: 'Nevada', value: 'NV' },
    { name: 'New Hampshire', value: 'NH' },
    { name: 'New Jersey', value: 'NJ' },
    { name: 'New Mexico', value: 'NM' },
    { name: 'New York', value: 'NY' },
    { name: 'North Carolina', value: 'NC' },
    { name: 'North Dakota', value: 'ND' },
    { name: 'Ohio', value: 'OH' },
    { name: 'Oklahoma', value: 'OK' },
    { name: 'Oregon', value: 'OR' },
    { name: 'Pennsylvania', value: 'PA' },
    { name: 'Rhode Island', value: 'RI' },
    { name: 'South Carolina', value: 'SC' },
    { name: 'South Dakota', value: 'SD' },
    { name: 'Tennessee', value: 'TN' },
    { name: 'Texas', value: 'TX' },
    { name: 'Utah', value: 'UT' },
    { name: 'Vermont', value: 'VT' },
    { name: 'Virginia', value: 'VA' },
    { name: 'Washington', value: 'WA' },
    { name: 'West Virginia', value: 'WV' },
    { name: 'Wisconsin', value: 'WI' },
    { name: 'Wyoming', value: 'WY' },
];

const provinces = [
    { name: 'Alberta', value: 'AB' },
    { name: 'British Columbia', value: 'BC' },
    { name: 'Manitoba', value: 'MB' },
    { name: 'New Brunswick', value: 'NB' },
    { name: 'Newfoundland and Labrador', value: 'NL' },
    { name: 'Nova Scotia', value: 'NS' },
    { name: 'Ontario', value: 'ON' },
    { name: 'Prince Edward Island', value: 'PE' },
    { name: 'Quebec', value: 'QC' },
    { name: 'Saskatchewan', value: 'SK' },
    { name: 'Northwest Territories', value: 'NT' },
    { name: 'Nunavut', value: 'NU' },
    { name: 'Yukon', value: 'YT' },
];

const serviceTypeCodes = {
    CI: {
        id: 1,
        name: 'Carry-In',
    },
    MI: {
        id: 4,
        name: 'Mail-In',
    },
    RT: {
        id: 5,
        name: 'Remote Tech',
    },
    CS: {
        id: 6,
        name: 'Curbside',
    },
    CC: {
        id: 0,
        name: 'Carry-In Or Curbside',
    },
};

const fallbackLocale = 'us_en';

const locales = {
    us_en: {
        pathAlias: 'en',
        long: 'English (US)',
        short: 'us',
        countryCode: 'US',
        country: 'United States',
        apiTranslationKey: 'us_en',
        countryId: 1,
        explicit: 'en-US',
        currencyCode: 'usd',
        iso: 'en',
        dateLocale: enUS,
    },
    us_es: {
        pathAlias: 'la',
        long: 'Español (US)',
        short: 'pr', // latin america but we use pr flag
        countryCode: 'US',
        country: 'United States',
        apiTranslationKey: 'us_es',
        explicit: 'es-419',
        countryId: 1,
        currencyCode: 'usd',
        iso: 'es',
        dateLocale: es,
    },
    ca_en: {
        pathAlias: 'ca',
        long: 'English (CA)',
        short: 'ca',
        countryCode: 'CA',
        country: 'Canada',
        apiTranslationKey: 'ca_en',
        countryId: 2,
        explicit: 'en-CA',
        currencyCode: 'cad',
        iso: 'en',
        dateLocale: enCA,
    },
    ca_fr: {
        pathAlias: 'ca/fr',
        long: 'Français (CA)',
        short: 'qc',
        countryCode: 'CA',
        country: 'Canada',
        apiTranslationKey: 'ca_fr',
        countryId: 2,
        explicit: 'fr-CA',
        currencyCode: 'cad',
        iso: 'fr',
        dateLocale: frCA,
    },
    tt_en: {
        pathAlias: 'tt',
        long: 'English (TT)',
        short: 'tt',
        countryCode: 'TT',
        country: 'Trinidad and Tobago',
        apiTranslationKey: 'tt_en',
        countryId: 3,
        explicit: 'en-TT',
        currencyCode: 'usd',
        iso: 'en',
        dateLocale: enUS,
    },
};

// Root devices for mobile or tablet
const batteryEligibleDevices = [59];

const screenRepairTypes = [1, 2, 3, 42, 43];

const otherDevice = 1354;
const rootDeviceTypeOther = 1251;
const rootDeviceTypes = [9, 10, 11, 46, rootDeviceTypeOther];

const CELL_PHONE_ID = 9;

const Validation = {
    postal_code: {
        US: /^[0-9]{5}$/,
        TT: /^[0-9]{5}$/,
        CA: /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z]\s?\d[ABCEGHJ-NPRSTV-Z]\d$/i,
    },
};

const errorCodes = {
    TIMESLOT_TAKEN: '1000',
};

module.exports = {
    siteUrl,
    serviceTypeCodes,
    fallbackLocale,
    locales,
    batteryEligibleDevices,
    screenRepairTypes,
    otherDevice,
    rootDeviceTypeOther,
    rootDeviceTypes,
    states,
    provinces,
    CELL_PHONE_ID,
    Validation,
    errorCodes,
};
