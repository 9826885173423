import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import { locale } from 'state/ApplicationRecoil';
import { locales } from 'utils/constants';
import { percentage } from 'utils/helpers';
import { ReactComponent as Logo } from 'images/brand-white-logo.svg';

const Footer = () => {
    const { t, i18n } = useTranslation(['common', 'routes']);
    const currentLocale = useRecoilValue(locale);
    const { explicit } = currentLocale || {};

    return (
        <StyledFooter>
            <div id="footer-container" className="footer__top">
                <div className="footer__container">
                    <div className="row">
                        <div className="col-md-8 col-xs-12 footer__menu">
                            <div className="row">
                                <div className="col-md-4 col-xs-12">
                                    <a
                                        href={t('routes:home')}
                                        className="footer__logo"
                                        aria-label={t('visit_website')}
                                    >
                                        <Logo />
                                    </a>
                                </div>

                                <div className="col-md-4 col-xs-12 footer__col">
                                    <a
                                        className="footer__title"
                                        href={t('routes:repairs')}
                                    >
                                        {t('start_repair')}
                                    </a>
                                    <ul className="footer__list">
                                        <li className="footer__item">
                                            <a
                                                href={t('routes:iphone_repair')}
                                                className="footer__link"
                                            >
                                                {t('repair_type.iphone_repair')}
                                            </a>
                                        </li>
                                        <li className="footer__item">
                                            <a
                                                href={t(
                                                    'routes:samsung_repair'
                                                )}
                                                className="footer__link"
                                            >
                                                {t(
                                                    'repair_type.samsung_repair'
                                                )}
                                            </a>
                                        </li>
                                        <li className="footer__item">
                                            <a
                                                href={t('routes:google_repair')}
                                                className="footer__link"
                                            >
                                                {t('repair_type.google_repair')}
                                            </a>
                                        </li>
                                        <li className="footer__item">
                                            <a
                                                href={t(`routes:cell_phone`)}
                                                className="footer__link"
                                            >
                                                {t(
                                                    'repair_type.cell_phone_repair'
                                                )}
                                            </a>
                                        </li>
                                        <li className="footer__item">
                                            <a
                                                href={t(`routes:tablet`)}
                                                className="footer__link"
                                            >
                                                {t('repair_type.tablet_repair')}
                                            </a>
                                        </li>
                                        <li className="footer__item">
                                            <a
                                                href={t(`routes:computer`)}
                                                className="footer__link"
                                            >
                                                {t(
                                                    'repair_type.computer_repair'
                                                )}
                                            </a>
                                        </li>
                                        <li className="footer__item">
                                            <a
                                                href={t(`routes:game_console`)}
                                                className="footer__link"
                                            >
                                                {t(
                                                    'repair_type.game_console_repair'
                                                )}
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                <div className="col-md-4 col-xs-12">
                                    <p className="footer__title">
                                        {t('ubreakifix_who')}
                                    </p>
                                    <ul className="footer__list">
                                        <li className="footer__item">
                                            <a
                                                className="footer__link"
                                                href={t('routes:partnerships')}
                                            >
                                                {t('partnerships')}
                                            </a>
                                        </li>
                                        <li className="footer__item">
                                            <a
                                                className="footer__link"
                                                href={t('routes:careers')}
                                            >
                                                {t('careers')}
                                            </a>
                                        </li>
                                        <li className="footer__item">
                                            <a
                                                className="footer__link"
                                                href={t('routes:franchising')}
                                            >
                                                {t('franchising')}
                                            </a>
                                        </li>
                                        <li className="footer__item">
                                            <a
                                                className="footer__link"
                                                href={t('routes:local_area')}
                                            >
                                                {t('areas_served')}
                                            </a>
                                        </li>
                                    </ul>
                                    <p className="footer__title">
                                        {t('support')}
                                    </p>
                                    <ul className="footer__list">
                                        <li className="footer__item">
                                            <a
                                                className="footer__link"
                                                href={t('routes:faq')}
                                            >
                                                {t('faq')}
                                            </a>
                                        </li>
                                        <li className="footer__item">
                                            <a
                                                className="footer__link"
                                                href={t(
                                                    'routes:gift_card_balance'
                                                )}
                                            >
                                                {t('gift_card_balance')}
                                            </a>
                                        </li>
                                        <li className="footer__item">
                                            <a
                                                className="footer__link"
                                                href={t('routes:blog')}
                                            >
                                                {t('blog')}
                                            </a>
                                        </li>
                                        <li className="footer__item">
                                            <a
                                                className="footer__link"
                                                href={t(
                                                    'routes:device_tracking'
                                                )}
                                            >
                                                {t('track_repair')}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 col-xs-12 footer__contact">
                            <p className="footer__title --gray">
                                {i18n.language === 'en-US'
                                    ? t('newsletter')
                                    : t('get_newsletter')}
                            </p>
                            {/* Begin MailChimp Signup Form */}
                            <div
                                id="mc_embed_signup"
                                className="mailchimp-embed"
                                v-if="$i18n.locale === 'us_en'"
                            >
                                <form
                                    className="footer__form"
                                    action="https://manage.kmail-lists.com/subscriptions/subscribe?a=PYJG9q&g=MjKhMj"
                                    method="post"
                                    id="mc-embedded-subscribe-form"
                                    name="mc-embedded-subscribe-form"
                                    target="_blank"
                                    noValidate
                                >
                                    <div
                                        id="mc_embed_signup_scroll"
                                        className="footer__form-inner"
                                    >
                                        <input
                                            type="hidden"
                                            name="g"
                                            value="MjKhMj"
                                        />
                                        <input
                                            type="email"
                                            defaultValue=""
                                            name="EMAIL"
                                            className="footer__form-input"
                                            id="k_id_email"
                                            placeholder={t('enter_email')}
                                            aria-label="Email"
                                        />

                                        <div id="mce-responses" className="">
                                            <div
                                                className=""
                                                id="mce-error-response"
                                                style={{ display: 'none' }}
                                            ></div>
                                            <div
                                                className=""
                                                id="mce-success-response"
                                                style={{ display: 'none' }}
                                            ></div>
                                        </div>
                                        {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
                                        <div
                                            style={{
                                                position: 'absolute',
                                                left: '-5000px',
                                            }}
                                        >
                                            <input
                                                type="text"
                                                name="b_755c2fc3055e742bc9602a3a9_c86f34ac76"
                                                tabIndex="-1"
                                                defaultValue=""
                                            />
                                        </div>
                                        <input
                                            type="submit"
                                            value={t('actions.send')}
                                            name="subscribe"
                                            id="mc-embedded-subscribe"
                                            className="footer__button footer__button--submit gtm-news-btn"
                                            aria-label={t('actions.send')}
                                        />
                                    </div>
                                </form>
                            </div>
                            {/* End mc_embed_signup  */}

                            <div className="footer__contact-details">
                                <a
                                    href={`tel:${t('support_phone')}`}
                                    className="footer__button gtm-call-btn-home"
                                >
                                    <i className="fa fa-phone"></i>
                                    <span className="footer__button-text">
                                        {t('support_phone')}
                                    </span>
                                </a>

                                <a
                                    href={t('routes:contact')}
                                    className="footer__button"
                                >
                                    <i className="fa fa-envelope"></i>
                                    <span className="footer__button-text">
                                        {t('contact_us')}
                                    </span>
                                </a>
                            </div>

                            <ul className="footer__social">
                                <li className="footer__social-item">
                                    <a
                                        href="https://www.facebook.com/ubreakifix"
                                        target="_blank"
                                        className="facebook"
                                        aria-label="Facebook"
                                        rel="noreferrer"
                                    >
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                </li>
                                <li className="footer__social-item">
                                    <a
                                        href="https://www.twitter.com/ubreakifix"
                                        target="_blank"
                                        className="twitter"
                                        aria-label="Twitter"
                                        rel="noreferrer"
                                    >
                                        <i className="fab fa-twitter"></i>
                                    </a>
                                </li>
                                <li className="footer__social-item">
                                    <a
                                        href="https://www.youtube.com/user/ubreakifixco"
                                        target="_blank"
                                        className="youtube"
                                        aria-label="YouTube"
                                        rel="noreferrer"
                                    >
                                        <i className="fab fa-youtube"></i>
                                    </a>
                                </li>
                                <li className="footer__social-item">
                                    <a
                                        href="https://www.instagram.com/ubreakifix"
                                        target="_blank"
                                        className="instagram"
                                        aria-label="Instagram"
                                        rel="noreferrer"
                                    >
                                        <i className="fab fa-instagram"></i>
                                    </a>
                                </li>
                            </ul>

                            <small className="footer__small">
                                {t('trademarks_affiliated')}
                            </small>

                            <small className="footer__small">
                                {t('ada_website')}
                            </small>
                        </div>
                    </div>
                </div>
            </div>

            <div id="footer-disclosure" className="footer__bottom">
                <div className="footer__container">
                    <div className="footer__split">
                        <div className="footer__languages">
                            {Object.keys(i18n.services.resourceStore.data).map(
                                (lang) => {
                                    return (
                                        <button
                                            key={lang}
                                            aria-label={locales[lang].long}
                                            onClick={() =>
                                                i18n.changeLanguage(lang)
                                            }
                                        >
                                            <span
                                                className={`flag-icon flag-icon-${locales[lang].short}`}
                                            />
                                        </button>
                                    );
                                }
                            )}
                        </div>

                        <div>
                            <span className="footer__bottom-text">
                                &copy; {new Date().getFullYear()} uBreakiFix
                            </span>
                            <a
                                className="footer__link"
                                href={t('routes:privacy_policy')}
                            >
                                {t('privacy')}
                            </a>
                            <a
                                className="footer__link"
                                href={t('routes:terms_conditions')}
                            >
                                {t('terms')}
                            </a>
                            {explicit === 'en-US' ? (
                                <a
                                    className="footer__link"
                                    href="https://privacyportal.onetrust.com/webform/c40dfca6-525d-44cd-82a0-f892f87753af/3628ce01-e3f3-4d8f-9da2-bc60dce6fab2"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {t('do_not_sell')}
                                </a>
                            ) : (
                                ''
                            )}
                            <a
                                className="footer__link"
                                href={t('routes:sitemap')}
                            >
                                {t('sitemap')}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </StyledFooter>
    );
};

const StyledFooter = styled.footer`
    background-color: black;
    // font-family: roboto, Tahoma, Arial, sans-serif;
    font-size: 14px;
    flex-shrink: 0;
    overflow: hidden;
    padding-bottom: 174px;
    line-height: 1.5;

    .flag-icon {
        margin-right: 4px;
    }

    // Footer grid
    .row {
        box-sizing: border-box;
        display: flex;
        flex: 0 1 auto;
        flex-direction: row;
        flex-wrap: wrap;
        margin-right: -10px;
        margin-left: -10px;
    }

    [class^='col-'] {
        box-sizing: border-box;
        flex: 0 0 auto;
        padding-right: 10px;
        padding-left: 10px;
    }

    .col-xs-12 {
        flex-basis: 100%;
        max-width: 100%;
    }

    @media (min-width: 768px) {
        padding-bottom: 0;

        .col-md-4 {
            flex-basis: ${percentage(4, 12)};
            max-width: ${percentage(4, 12)};
        }

        .col-md-8 {
            flex-basis: ${percentage(8, 12)};
            max-width: ${percentage(8, 12)};
        }
    }

    .footer__top {
        padding: 6rem 0em 3.5rem;
    }

    .footer__logo {
        display: inline-block;
        margin-bottom: 10px;

        > img,
        > svg {
            max-width: 200px;
            width: 100%;
        }
    }

    // List item
    .footer__item {
        display: block;
        margin-bottom: 5px;

        @media (min-width: 768px) {
            margin-bottom: 1rem;
        }
    }

    .footer__link {
        color: #a5aaaf;
        font-weight: 300;

        &:hover,
        &:focus {
            color: #ffffff;
            text-decoration: underline;
        }
    }

    .footer__menu {
        text-align: center;
        margin-bottom: 20px;

        @media (min-width: 768px) {
            text-align: left;
            margin-bottom: 0;
        }
    }

    .footer__small {
        color: #a5aaaf;
        display: block;
        font-size: 12px;
        font-weight: 300;
    }

    .footer__social {
        display: inline-block;
        list-style-type: none;
        margin: 0;
    }

    .footer__social-item {
        display: inline-block;
        line-height: 1;
        margin: 20px 25px 15px 5px;
        font-size: 10px;
        vertical-align: middle;

        a {
            color: #a5aaaf;

            &:hover,
            &:focus {
                color: #fff;
            }
        }

        i {
            font-size: 2em;
        }
    }

    .footer__form {
        position: relative;

        // Submit
        .footer__button {
            flex-shrink: 0;
            flex-grow: 0;
            flex-basis: auto;
            margin-left: 5px;
        }
    }

    .footer__form-inner {
        display: flex;
    }

    .footer__form-input {
        width: 100%;
        height: 48px;
        padding: 6px 12px;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 8px;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        font-size: 15px;
        flex: 1 1 auto;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &:focus {
            border-color: #da291c;
            box-shadow: none;
            outline: 0;
        }
    }

    .footer__contact {
        .footer__form {
            margin-bottom: 1rem;
        }

        .footer__button {
            margin-left: 17px;

            &:first-of-type {
                margin-left: 0;
            }
        }

        .footer__small {
            margin-bottom: 15px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        .footer__social {
            margin-bottom: 10px;
        }

        .footer__contact-details {
            margin-bottom: 10px;
        }

        @media (min-width: 768px) {
            border-left: 0.5px solid #424857;
        }
    }

    // buttons
    .footer__contact-details {
        display: flex;
        flex-wrap: nowrap;

        .footer__button {
            flex: 1 1 auto;
        }
    }

    .footer__button {
        background-color: transparent;
        border: 1px solid #a5aaaf;
        display: inline-block;
        text-align: center;
        color: #a5aaaf;
        font-weight: 400;
        font-size: 15px;
        line-height: 34px;
        touch-action: manipulation;
        cursor: pointer;
        white-space: nowrap;
        padding: 6px 12px;
        border-radius: 8px;
        user-select: none;
        transition: all 0.25s;

        &:hover,
        &:focus {
            background-color: #5b5b5b;
            border-color: #565656;
            color: #ffffff;
        }

        i {
            display: inline-block;
            margin-right: 4px;
        }

        &.footer__button--submit {
            background-color: #a5aaaf;
            border-color: #a5aaaf;
            border-radius: 100px;
            color: black;
            padding-left: 28px;
            padding-right: 28px;
            margin-left: 16px;

            &:hover,
            &:focus {
                background-color: white;
                border-color: white;
            }
        }
    }

    .footer__button-text {
        display: inline-block;
    }

    .footer__col {
        margin: 20px 0;

        @media (min-width: 768px) {
            margin: 0;
        }
    }

    .footer__list {
        list-style-type: none;
        margin: 1rem 0 0 0;
        padding: 0;
    }

    .footer__title {
        color: #ffffff;
        font-size: 16px;
        font-weight: 300;
        margin-bottom: 10px;
        margin-top: 0;

        &.--gray {
            color: #a5aaaf;
        }

        &:not(:first-child) {
            margin-top: 30px;
        }
    }

    // Handle footer title links
    a.footer__title {
        &:hover,
        &:focus {
            color: #ffffff;
            text-decoration: underline;
        }
    }

    // Copies cell/container to not depend on it
    .footer__container {
        margin-right: auto;
        margin-left: auto;
        padding: 0 10px;

        @media (min-width: 768px) {
            max-width: 740px;
        }

        @media (min-width: 992px) {
            max-width: 960px;
        }

        @media (min-width: 1200px) {
            max-width: 1160px;
        }
    }

    .footer__bottom {
        color: #a5aaaf;
        padding: 10px 0px 60px;

        .footer__link {
            color: #a5aaaf;
            display: inline-block;
            font-size: 12px;
            font-weight: 300;

            &::before {
                content: '|';
                display: inline-block;
                padding: 0 10px;
            }
        }
    }

    .footer__split {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .footer__bottom-text {
        font-size: 0.75rem;
        font-weight: 300;
    }
`;

export default Footer;
