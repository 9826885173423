import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Text, Button } from '@soluto-private/mx-asurion-ui-react';
import SiteDownImage from 'images/404page_hero.png';

const Maintenance = () => {
    const { t } = useTranslation();

    return (
        <Hero>
            <div className="hero-container">
                <div className="hero-inner">
                    <Text size="7">{t('error.down')}</Text>
                    <div>
                        <Button
                            href="https://www.asurion.com"
                            as="a"
                            className="hero-cta"
                        >
                            {t('visit')} asurion.com
                        </Button>
                    </div>
                </div>
            </div>
        </Hero>
    );
};

const Hero = styled.div`
    margin-top: 2rem;
    padding: 1rem;
    text-align: center;

    .hero-container {
        align-items: center;
        background-color: #5b5b5b;
        background-image: url(${SiteDownImage});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        color: #fff;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        max-width: 1160px;
        min-height: 496px;
        overflow: hidden;
        padding: 1rem;
        position: relative;
        width: 100%;

        &::before {
            background-color: #000;
            content: '';
            height: 100%;
            inset: 0;
            opacity: 0.3;
            position: absolute;
            width: 100%;
            z-index: 1;
        }
    }

    .hero-inner {
        z-index: 2;
    }

    .hero-cta {
        display: inline-block;
        margin-top: 2rem;
    }
`;

export default Maintenance;
