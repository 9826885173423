import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format, isSameDay, addDays } from 'date-fns';
import styled from 'styled-components';
import { locales } from 'utils/constants';

export const DatePickerButton = ({
    date,
    onClick,
    active,
    disabled,
    nextAvailAppointmentDate,
}) => {
    const { t, i18n } = useTranslation('common');
    const [showTag, setShowTag] = useState(false);
    const [tagText, setTagText] = useState(t('tomorrow')); // today,
    const dayText = format(new Date(date), 'E', {
        locale: locales[i18n.language].dateLocale.default,
    });
    const dateText = format(new Date(date), 'L/d', {
        locale: locales[i18n.language].dateLocale.default,
    });

    useEffect(() => {
        const today = new Date();
        const tomorrow = addDays(today, 1);

        if (date) {
            if (isSameDay(date, tomorrow)) {
                setShowTag(true);
                setTagText(t('tomorrow'));
            } else if (isSameDay(date, today)) {
                setShowTag(true);
                setTagText(t('today'));
            } else if (isSameDay(date, nextAvailAppointmentDate)) {
                setShowTag(true);
                setTagText(t('next_available'));
            } else {
                setShowTag(false);
            }
        }
    }, [active, date, t, nextAvailAppointmentDate]);

    return (
        <DatePickerButtonContainer htmlFor={date}>
            {showTag && (
                <DatePickerTag active={active}>{tagText}</DatePickerTag>
            )}
            <HiddenRadioButton
                name="day-group"
                data-testid="date-picker-radio"
                id={date}
                value={date}
                checked={active}
                onChange={(ev) => onClick(ev.target.value)}
                disabled={disabled}
            />
            <DayPickerContent disabled={disabled} active={active}>
                <DatePickerDayText>{dayText}</DatePickerDayText>
                <DatePickerNumberText>{dateText}</DatePickerNumberText>
            </DayPickerContent>
        </DatePickerButtonContainer>
    );
};

const DatePickerButtonContainer = styled.label`
    position: relative;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: flex-end;
    pointer-events: auto;
    margin: 0 0.5rem;
`;

const DayPickerContent = styled.div`
    height: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 5rem;
    position: relative;
    color: ${(props) =>
        props.disabled
            ? props.theme.colors.disabled
            : props.theme.colors.black};
    cursor: ${(props) => (!props.disabled ? 'pointer' : 'not-allowed')};

    :hover::before {
        box-shadow: ${(props) =>
            props.disabled
                ? 'none'
                : props.active
                ? `0px 0px 0px 2px ${props.theme.colors.black} inset`
                : `0px 0px 0px 1px ${props.theme.colors.black} inset`};
    }

    ::before {
        content: '';
        border-radius: 8px;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        box-shadow: ${(props) =>
            props.disabled
                ? 'none'
                : props.active
                ? `0px 0px 0px 2px ${props.theme.colors.black} inset`
                : `0px 0px 0px 1px ${props.theme.colors.disabled} inset`};
        transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
`;

const HiddenRadioButton = styled.input.attrs({ type: 'radio' })`
    display: none;
`;

const DatePickerTag = styled.div`
    background: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.white};
    margin-bottom: 0.375rem;
    padding: 0.15rem;
    font-weight: 400;
    border-radius: 3px;
    font-size: 12px;
    width: 100%;
    display: block;
    text-align: center;
    opacity: ${(props) => (props.active ? '100%' : '50%')};
    width: 5rem;
`;

const DatePickerDayText = styled.span`
    font-weight: 600;
    margin-bottom: 0.35rem;
    text-transform: capitalize;
`;

const DatePickerNumberText = styled.span`
    font-size: 14px;
`;
