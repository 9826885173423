import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@soluto-private/mx-asurion-ui-react';
import Callout from '../../Callout';
import styled from 'styled-components';

const TimeSlotTakenToast = ({ hideAppointmentToastHandler }) => {
    const { t } = useTranslation(['common', 'errors']);

    return (
        <>
            <ErrorToastContainer>
                <Callout className="callout_container">
                    <Text className="uh_oh_message_container">
                        {t('errors:time_no_longer_available')}{' '}
                        {t('schedule_repair.choose_different_time')}
                    </Text>
                    <Text size="2" weight="heavy" className="got_it_action_btn">
                        <BtnActionLink onClick={hideAppointmentToastHandler}>
                            {t(
                                'schedule_repair.time_slot_not_avail_action_text'
                            )}
                        </BtnActionLink>
                    </Text>
                </Callout>
            </ErrorToastContainer>
        </>
    );
};

const ErrorToastContainer = styled.div`
    z-index: 999;
    position: fixed;
    bottom: 10px;
    width: 95%;
    right: 0px;
    left: 50%;
    transform: translateX(-50%);

    ${(props) => props.theme.mediaQuery(props.theme.breakpoints.medium)} {
        width: 350px;
        position: fixed;
        bottom: 50px;
        right: 150px;
        left: auto;
        transform: none;
    }

    .callout_container {
        display: flex;
        background: black;
        color: white;
        box-shadow: 0px 2px 10px 0px rgba(255, 255, 255, 0.6);
    }

    a {
        color: white;
    }

    .uh_oh_message_container {
        width: 225px;
    }

    ${(props) => props.theme.mediaQuery(props.theme.breakpoints.xsmall)} {
        .uh_oh_message_container {
            width: 210px;
        }
    }

    ${(props) => props.theme.mediaQuery(props.theme.breakpoints.medium)} {
        .uh_oh_message_container {
            width: 250px;
        }
    }

    .got_it_action_btn {
        text-decoration: underline;
        margin-left: auto;
        margin-top: auto;
    }
`;

const BtnActionLink = styled.a`
    cursor: pointer;
`;

export default TimeSlotTakenToast;
