import { useEffect, useState } from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { useTranslation } from 'react-i18next';
import { selectedDevice, selectedRootDevice } from 'state/ApplicationRecoil';
import { locales, rootDeviceTypes } from 'utils/constants';

const useDeviceName = () => {
    const { state, contents } = useRecoilValueLoadable(selectedDevice);
    const { state: rootDeviceState, contents: rootDeviceContents } =
        useRecoilValueLoadable(selectedRootDevice);

    const { t, i18n } = useTranslation(['common', 'attributes']);
    const [rootDeviceName, setRootDeviceName] = useState(
        t('device.generic').toLowerCase()
    );

    useEffect(() => {
        if (rootDeviceState === 'hasValue') {
            const name =
                rootDeviceContents.name[
                    locales[i18n.language].apiTranslationKey
                ].toLowerCase();

            if (name !== rootDeviceName) {
                setRootDeviceName(name);
            }
        }
    }, [rootDeviceState, rootDeviceContents, rootDeviceName, i18n.language]);

    const deviceName =
        state === 'hasValue'
            ? contents.name[locales[i18n.language].apiTranslationKey]
            : t('device.generic').toLowerCase();

    const displayedDeviceName =
        rootDeviceTypes.indexOf(contents.id) > -1
            ? deviceName.toLowerCase()
            : deviceName;

    return {
        deviceName,
        displayedDeviceName,
        rootDeviceName,
    };
};

export { useDeviceName };
