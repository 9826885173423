import styled from 'styled-components';
import { Text } from '@soluto-private/mx-asurion-ui-react';
import { useTranslation } from 'react-i18next';
import asurionExperts from 'images/asurion-experts-full.png';

const AsurionExpertsCard = () => {
    const { t } = useTranslation('common');

    return (
        <Card>
            <img src={asurionExperts} alt="" className="media" />
            <div className="black-box">
                <h3 className="title">
                    <Text weight="heavy" size={3}>
                        {t('confirmation.asurion_experts.title')}
                    </Text>
                </h3>
                <Text as="p" size={1} className="body">
                    {t('confirmation.asurion_experts.description')}
                </Text>
            </div>
        </Card>
    );
};

export default AsurionExpertsCard;

const Card = styled.div`
    margin-top: 2rem;
    position: relative;

    &:before {
        background-color: ${(props) => props.theme.colors.white};
        bottom: 0px;
        content: '';
        height: 102%;
        left: 0;
        position: absolute;
        width: 7.25rem;
    }

    &:after {
        clear: both;
        content: '';
        display: block;
    }

    .media {
        float: left;
        clip-path: polygon(
            0% 0%,
            80% 0,
            80% 33%,
            86% 37%,
            92% 44%,
            95% 53%,
            98% 67%,
            100% 92%,
            100% 100%,
            0 100%
        );
        shape-outside: polygon(
            0% 0%,
            80% 0,
            80% 33%,
            86% 37%,
            92% 44%,
            95% 53%,
            98% 67%,
            100% 92%,
            100% 100%,
            0 100%
        );
        margin: -1.25rem 0 0 -4.375rem;
    }

    .black-box {
        background-color: ${(props) => props.theme.colors.black};
        color: ${(props) => props.theme.colors.white};
        padding: 1.125rem 1rem 1.75rem;
        position: relative;
        width: 100%;
        z-index: -1;

        .title {
            margin-bottom: 0.75rem;
        }

        .body {
            line-height: 1.25rem;
        }

        ${(props) => props.theme.mediaQuery(props.theme.breakpoints.medium)} {
            padding: 3rem 4.75rem 3rem 13.25rem;
            width: calc(100% - 1rem);
        }
    }
`;
