import { atom, DefaultValue } from 'recoil';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';

const historyEffect =
    (name) =>
    ({ setSelf, trigger, onSet }) => {
        const history = useHistory();
        const location = useLocation();

        if (trigger === 'get') {
            const query = qs.parse(location.search);

            if (query[name] !== undefined) {
                setSelf(query[name]);
            }
        }

        onSet((newValue) => {
            // We use setTimeout here to bypass the batching error
            setTimeout(() => {
                if (!(newValue instanceof DefaultValue)) {
                    history.push({
                        search: qs.stringify({
                            ...qs.parse(window.location.search),
                            [name]: newValue,
                        }),
                    });
                } else {
                    // If default value is passed through after resetting state, remove the query param from the url
                    history.replace({
                        search: qs.stringify({
                            ...qs.parse(window.location.search),
                            [name]: undefined,
                        }),
                    });
                }
            }, 0);
        });

        const listener = history.listen((location) => {
            setSelf(qs.parse(location.search)[name]);
        });

        return () => {
            listener();
        };
    };

export const error = atom({
    key: 'error',
    default: '',
});

export const postalCode = atom({
    key: 'postalCode',
    default: undefined,
    effects_UNSTABLE: [historyEffect('postal_code')],
});

export const deviceId = atom({
    key: 'deviceId',
    default: undefined,
    effects_UNSTABLE: [historyEffect('device')],
});

export const repairId = atom({
    key: 'repairId',
    default: undefined,
    effects_UNSTABLE: [historyEffect('repair')],
});

export const deviceColor = atom({
    key: 'deviceColor',
    default: null,
});

export const serviceTypeCode = atom({
    key: 'serviceTypeCode',
    default: undefined,
    effects_UNSTABLE: [historyEffect('service')],
});

export const storeId = atom({
    key: 'storeId',
    default: undefined,
    effects_UNSTABLE: [historyEffect('store')],
});

export const serviceOptions = atom({
    key: 'serviceOptions',
    default: null,
});

export const locale = atom({
    key: 'locale',
    default: null,
});

// We're assuming that all data in here has already been validated
export const customerDetails = atom({
    key: 'customerDetails',
    default: {
        givenName: '',
        familyName: '',
        email: '',
        phone: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        state: '',
        postal_code: '',
        special_instructions: '',
        canSms: '',
        canEmail: '',
        canCall: '',
        locationType: '',
        service_type_code: '',
        // Validation confirmations
        hasValidDetails: false,
        hasValidLocation: false,
    },
});

export const lead = atom({
    key: 'lead',
    default: {},
});

export const payment = atom({
    key: 'payment',
    default: {},
});

export const selectedRepairOptions = atom({
    key: 'selectedRepairOptions',
    default: {},
});

export const bookingWindow = atom({
    key: 'bookingWindow',
    default: null,
});

export const bookingWindows = atom({
    key: 'bookingWindows',
    default: null,
});

export const isPaymentCaptureDisabled = atom({
    key: 'isPaymentCaptureDisabled',
    default: false,
});

export const calendarActiveDate = atom({
    key: 'calendarActiveDate',
    default: new Date(),
});

export const appointmentIntent = atom({
    key: 'appointmentIntent',
    default: null,
});

export const appointmentSlotTaken = atom({
    key: 'appointmentSlotTaken',
    default: false,
});
