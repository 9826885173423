import React from 'react';
import styled from 'styled-components';
import { TextField, Dropdown } from '@soluto-private/mx-asurion-ui-react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { states, provinces, Validation } from 'utils/constants';
import { DropdownField, HelperText } from 'components/Field';
import { useRecoilValue } from 'recoil';
import { countryCode } from 'state/ApplicationRecoil';

const BillingAddress = ({ register, errors, control }) => {
    const { t } = useTranslation(['common', 'validation', 'attributes']);
    const currentCountryCode = useRecoilValue(countryCode);

    const pattern = Validation.postal_code[currentCountryCode];

    return (
        <BillingAddressCard>
            <div className="field">
                <TextField
                    label={t('attributes:address_line_1')}
                    fieldStatus={errors.address_line_1 ? 'error' : 'default'}
                    helperText={errors.address_line_1?.message}
                    {...register('address_line_1', {
                        required: t('validation:required', {
                            attribute: t('attributes:address_line_1'),
                        }),
                    })}
                />
            </div>
            <div className="field">
                <TextField
                    label={`${t('attributes:address_line_2')} (${t(
                        'optional'
                    )})`}
                    {...register('address_line_2')}
                />
            </div>
            <div className="field">
                <TextField
                    label={t('attributes:city')}
                    fieldStatus={errors.city ? 'error' : 'default'}
                    helperText={errors.city?.message}
                    {...register('city', {
                        required: t('validation:required', {
                            attribute: t('attributes:city'),
                        }),
                    })}
                />
            </div>
            <div className="field-group">
                <div className="field">
                    <Controller
                        control={control}
                        name="state"
                        rules={{
                            required: t('validation:required', {
                                attribute: t('attributes:state'),
                            }),
                        }}
                        render={({
                            field: { onChange, value, name },
                            fieldState: { invalid },
                        }) => (
                            <React.Fragment>
                                <DropdownField>
                                    <Dropdown
                                        label={t('attributes:state')}
                                        name={name}
                                        options={
                                            currentCountryCode === 'CA'
                                                ? provinces
                                                : states
                                        }
                                        value={value}
                                        onChange={onChange}
                                        selectClassName={`select-input${
                                            invalid ? ' is-invalid' : ''
                                        }`}
                                    />
                                </DropdownField>

                                {invalid && (
                                    <HelperText>State is required</HelperText>
                                )}
                            </React.Fragment>
                        )}
                    />
                </div>
                <div className="field">
                    <TextField
                        label={t('attributes:zip_code')}
                        fieldStatus={errors.postal_code ? 'error' : 'default'}
                        helperText={errors.postal_code?.message}
                        {...register('postal_code', {
                            required: t('validation:required', {
                                attribute: t('attributes:postal_code'),
                            }),
                            pattern: {
                                value: pattern,
                                message: t('validation:invalid', {
                                    attribute: t('attributes:postal_code'),
                                }),
                            },
                        })}
                    />
                </div>
            </div>
        </BillingAddressCard>
    );
};

const BillingAddressCard = styled.div`
    margin: 1rem 0;

    .field {
        width: 100%;
        margin-bottom: 1rem;
        margin-right: 1rem;

        &:last-child {
            margin-right: 0;
        }
    }

    .field-group {
        display: flex;
        margin-bottom: 1rem;

        .field {
            margin-bottom: 0;
        }
    }
`;

export default BillingAddress;
