import { Text } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';
import { useRecoilValueLoadable, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { pricing, locale } from 'state/ApplicationRecoil';
import StepHeader from '../../StepHeader';
import CheckoutForm from './CheckoutForm';
import Loader from 'components/Loader';
import Apercu from 'fonts/Apercu-Regular-Pro.woff';
import { useScrollToTop } from 'hooks';
import { formatPrice } from 'utils/helpers';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const RepairDeposit = () => {
    useScrollToTop();

    const pricingState = useRecoilValueLoadable(pricing);
    const { t } = useTranslation('common');
    const currentLocale = useRecoilValue(locale);

    // Load in Apercu font for stripe elements iframe
    const options = {
        fonts: [
            {
                family: 'Apercu',
                src: `url(${window.location.origin}${Apercu})`,
            },
        ],
    };

    return (
        <Deposit className="page repair-deposit-page">
            <StepHeader title={t('repair_deposit_view.title')} />

            <div className="row">
                {pricingState.state === 'loading' ? (
                    <Loader />
                ) : (
                    <>
                        <div className="deposit">
                            <Text size={3} as="div">
                                {t('repair_deposit')}
                            </Text>
                            <Text
                                size={3}
                                weight="heavy"
                                className="money"
                                as="div"
                            >
                                {formatPrice(
                                    currentLocale.explicit,
                                    pricingState.contents.currency,
                                    pricingState.contents.deposit_price
                                )}
                            </Text>
                        </div>

                        <Elements stripe={stripePromise} options={options}>
                            <CheckoutForm />
                        </Elements>
                    </>
                )}
            </div>
        </Deposit>
    );
};

const Deposit = styled.div`
    > * + * {
        margin-top: 1rem;
    }

    .row {
        padding: 0 1rem;
    }

    .deposit {
        display: flex;
        padding: 1.3rem 0;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        margin-bottom: 2rem;
    }

    .money {
        margin-left: auto;
    }

    .billing-address-text {
        margin-bottom: 1rem;
    }

    .terms-conditions {
        padding: 1.5rem 0;
        margin: 1rem 0;
        border-bottom: 1px solid #e6e6eb;
    }
`;

export default RepairDeposit;
