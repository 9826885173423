import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Text, Button } from '@soluto-private/mx-asurion-ui-react';

const NotFound = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const backToHomePage = () => {
        let path = '/';
        history.push(path);
    };

    const errorMsgStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '10rem',
        marginBottom: '10rem',
    };

    return (
        <div style={errorMsgStyle}>
            <Text style={{ paddingBottom: '3rem', fontSize: '2rem' }}>
                {t('error.not_found')}
            </Text>
            <Button onClick={backToHomePage}>{t('start_repair')}</Button>
        </div>
    );
};

export default NotFound;
