import { useMemo, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { getDay, getDayOfYear, isSameDay, isAfter, getYear } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { stringToDateWithoutOffset, getCurrentActiveDay } from 'utils/helpers';

import calendarImg from 'images/icons/calendar-img.svg';
import { DatePickerButton } from './DatePickerButton';
import { CalendarModal } from '../CalendarModal';

// weekOfDays/nextAvailAppointmentDate only apply to Carry in
// timeslots only apply to Remote tech
export const DatePicker = ({
    days = [],
    activeDay,
    setActiveDay,
    disabledDaysOfWeek = [],
    blackoutDays = [],
    allowSameDay = false,
    setCalendarActiveDay,
    weekOfDays,
    nextAvailAppointmentDate,
    timeslots,
}) => {
    const { t } = useTranslation('common');
    const [checkedDay, setCheckedDay] = useState();
    const [showDatePicker, setShowDatePicker] = useState(false);
    const today = new Date();

    useEffect(() => {
        if (!checkedDay) {
            setCheckedDay(activeDay);
        }
    }, [checkedDay, weekOfDays, activeDay]);

    const shownDays = useMemo(() => {
        const currentFirstDate = days.findIndex((day) =>
            isSameDay(day, checkedDay)
        );

        return days.slice(currentFirstDate, currentFirstDate + 7);
    }, [checkedDay, days]);

    const onDatePickerCalendarChange = (date) => {
        setActiveDay(date);
        setCheckedDay(date);

        // Calendar active day is only set for carry in appointments
        if (setCalendarActiveDay) {
            setCalendarActiveDay(date);
        }

        setShowDatePicker(false);
    };

    const availableBookingWindows = useCallback(() => {
        return timeslots.reduce((acc, slot) => {
            const day = getDayOfYear(slot.window_start);

            if (acc[day] !== undefined) {
                acc[day].push(slot);
            } else {
                acc[day] = [slot];
            }

            return acc;
        }, {});
    }, [timeslots]);

    const isDateAvailable = (date) => {
        // RT check
        if (timeslots && !availableBookingWindows()[getDayOfYear(date)]) {
            return false;
        }

        // Perform other checks
        // If the day has no time slots
        if (disabledDaysOfWeek.indexOf(getDay(date)) > -1) {
            // Check if day is on a disabled weekday
            return false;
        } else if (
            blackoutDays.length &&
            blackoutDays.find(
                (blackoutDay) =>
                    getDayOfYear(date) === blackoutDay.day_of_year &&
                    blackoutDay.year === getYear(date)
            )
        ) {
            // Check if day is same day of year as a blackout day
            return false;
        } else if (allowSameDay && isSameDay(today, date)) {
            // Check same day and if we allow that
            return true;
        } else if (isAfter(date, days[days.length - 1])) {
            return false;
        } else {
            return isAfter(date, today);
        }
    };

    const dateHasAppointments = (date) => {
        if (
            getCurrentActiveDay(date, weekOfDays) &&
            getCurrentActiveDay(date, weekOfDays).at.find((timeslot) => {
                return timeslot.slots > 0;
            })
        ) {
            return true;
        }
    };

    // Determine whether we have a weekOfDays or if it is derived from the full provided days
    const daysToDisplay = weekOfDays ? weekOfDays : shownDays;

    return (
        <DatePickerContainer>
            <DatePickerHeader>
                <p>{t('actions.choose_day')}.</p>
                <DatePickerCircleButton
                    onClick={() => setShowDatePicker(!showDatePicker)}
                >
                    <DatePickerCircleButtonImg
                        src={calendarImg}
                        alt={t('actions.open_calendar')}
                    />
                </DatePickerCircleButton>
            </DatePickerHeader>
            <DatePickerButtonContainer>
                {daysToDisplay.map((day) => {
                    const formattedDate = weekOfDays
                        ? stringToDateWithoutOffset(day.date)
                        : day;

                    let disabled = !isDateAvailable(formattedDate);

                    // For carry in the calendar should be unaffected by slot availability
                    if (weekOfDays) {
                        disabled =
                            !isDateAvailable(formattedDate) ||
                            !dateHasAppointments(formattedDate);
                    }

                    return (
                        <DatePickerButton
                            key={formattedDate}
                            date={formattedDate}
                            active={isSameDay(formattedDate, activeDay)}
                            onClick={setActiveDay}
                            disabled={disabled}
                            nextAvailAppointmentDate={nextAvailAppointmentDate}
                        />
                    );
                })}
            </DatePickerButtonContainer>
            <CalendarModal
                onExit={() => setShowDatePicker(false)}
                active={showDatePicker}
                activeDate={activeDay}
                days={days}
                isDateAvailable={isDateAvailable}
                onChange={onDatePickerCalendarChange}
            ></CalendarModal>
        </DatePickerContainer>
    );
};

const DatePickerContainer = styled.div`
    margin-bottom: 40px;
`;

const DatePickerCircleButton = styled.button`
    box-shadow: 0px 0px 0px 2px #000 inset;
    background: none;
    border: none;
    border-radius: 100%;
    width: 48px;
    height: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

const DatePickerCircleButtonImg = styled.img`
    width: 24px;
    height: 24px;
`;

const DatePickerButtonContainer = styled.div`
    display: flex;
    padding-bottom: 1rem;
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow-x: scroll;
    overflow-y: hidden;
    margin-left: -1rem;
    width: calc(100% + 2rem);
    padding-left: 1rem;

    ::-webkit-scrollbar {
        display: none;
    }

    ${(props) => props.theme.mediaQuery(props.theme.breakpoints.small)} {
        overflow-x: auto;
    }
`;

const DatePickerHeader = styled.div`
    margin: 20px 0;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .date-picker {
        width: 40%;
    }
`;
