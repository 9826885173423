import { useRecoilValue } from 'recoil';
import { format } from 'date-fns';
import { Text } from '@soluto-private/mx-asurion-ui-react';
import { bookingWindow, locale } from 'state/ApplicationRecoil';
import { formatBookingWindowSlot } from 'utils/helpers';

const RemoteTechAppointmentDetails = () => {
    const bookingWindowState = useRecoilValue(bookingWindow);
    const localeState = useRecoilValue(locale);
    const formattedBookingWindow = formatBookingWindowSlot(bookingWindowState);

    return (
        <>
            <Text>
                {format(formattedBookingWindow.window_start, 'ccc, MMMM d, y', {
                    locale: localeState.dateLocale.default,
                })}
            </Text>
            <Text weight="heavy">
                {format(formattedBookingWindow.window_start, 'h', {
                    locale: localeState.dateLocale.default,
                })}
                -
                {format(formattedBookingWindow.window_end, 'ha', {
                    locale: localeState.dateLocale.default,
                })}
            </Text>
        </>
    );
};

export default RemoteTechAppointmentDetails;
