import React from 'react';
import styled from 'styled-components';
// @ts-ignore
import AriaModal from 'react-aria-modal';
import { Icon } from 'components/Icons';

interface ModalProps {
    children: React.ReactNode;
    active: boolean;
    titleText: string;
    onExit: () => void;
    innerClassName?: string;
    dialogStyle?: Record<string, unknown>;
    underlayStyle?: Record<string, unknown>;
}

const ModalContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 20px;
    background-color: ${(props) => props.theme.colors.white};
    border-radius: 8px;
    z-index: 99999999;
    overflow: auto;
`;

const ModalHeader = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`;

const TitleText = styled.p`
    font-size: 16px;
    line-height: 150%;
`;

const CloseButton = styled.button``;

export const Modal: React.FC<ModalProps> = ({
    children,
    active,
    titleText,
    onExit,
    innerClassName,
    dialogStyle,
    underlayStyle,
}: ModalProps) => {
    const usedUnderlayStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: 99999999,
        ...underlayStyle,
    };

    const usedDialogStyle = {
        ...dialogStyle,
    };

    const getApplicationNode = (): any => {
        return document.getElementById('root-element');
    };

    if (!active) {
        return null;
    }

    return (
        <AriaModal
            titleText={titleText}
            onExit={onExit}
            underlayStyle={usedUnderlayStyle}
            getApplicationNode={getApplicationNode}
            initialFocus="#modal-content"
            dialogStyle={usedDialogStyle}
        >
            <ModalContainer id="modal-content" className={innerClassName}>
                <ModalHeader>
                    <TitleText>{titleText}</TitleText>
                    <CloseButton onClick={onExit}>
                        <Icon name="Close" size={18} />
                    </CloseButton>
                </ModalHeader>
                {children}
            </ModalContainer>
        </AriaModal>
    );
};
