import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useRecoilValueLoadable, useRecoilState } from 'recoil';
import { Text, TextField, Button } from '@soluto-private/mx-asurion-ui-react';
import {
    selectedDevice,
    postalCode,
    locationBasedServiceOptions,
} from 'state/ApplicationRecoil';
import { locales, Validation } from 'utils/constants';
import { useDeviceName } from 'hooks';
import { SimpleAlert } from './../Alert';
import StepHeader from 'components/StepHeader';
import Loader from 'components/Loader';

const CustomerLocator = () => {
    const { t, i18n } = useTranslation([
        'common',
        'validation',
        'attributes',
        'errors',
    ]);
    const { state, contents } = useRecoilValueLoadable(selectedDevice);
    const [postalCodeValue, savePostalCode] = useRecoilState(postalCode);
    const serviceOptions = useRecoilValueLoadable(
        locationBasedServiceOptions(postalCodeValue)
    );
    const { displayedDeviceName } = useDeviceName();

    const {
        register,
        handleSubmit,
        setError,
        watch,
        formState: { errors, isSubmitting },
    } = useForm({
        defaultValues: {
            postal_code: postalCodeValue,
        },
    });

    const pattern =
        Validation.postal_code[locales[i18n.language].countryCode] ||
        Validation.postal_code['US'];

    const handleSubmission = async ({ postal_code }) => {
        if (isSubmitting) return;

        savePostalCode(postal_code);
    };

    const postalCodeField = register('postal_code', {
        required: t('validation:required', {
            attribute: t('attributes:postal_code'),
        }),
        pattern: {
            value: pattern,
            message: t('validation:invalid', {
                attribute: t('attributes:postal_code'),
            }),
        },
    });

    useEffect(() => {
        if (
            serviceOptions.state === 'hasError' &&
            serviceOptions.contents?.error_code === 'WEB001'
        ) {
            setError('postal_code', {
                type: 'no_service_options',
                message: t('errors:no_service_options'),
            });
        }
    }, [setError, savePostalCode, serviceOptions, t]);

    return (
        <StyledCustomerLocator className="page customer-locator-page">
            <StepHeader
                title={t('customer_locator.title')}
                description={t('customer_locator.description')}
            >
                {serviceOptions.state === 'hasError' &&
                    !serviceOptions.contents?.error_message && (
                        <SimpleAlert
                            error
                            message={t('errors:error_has_occurred_oops')}
                        />
                    )}

                {state === 'hasValue' && (
                    <div className="bucket">
                        <img
                            className="bucket-media"
                            src={contents.image_link}
                            alt=""
                        />
                        <Text size={3}>
                            {t('customer_locator.pre_title', {
                                device: displayedDeviceName,
                            })}
                        </Text>
                    </div>
                )}
            </StepHeader>

            <form className="row" onSubmit={handleSubmit(handleSubmission)}>
                <FieldWrapper>
                    <TextField
                        className="field"
                        label={t('attributes:zip_code')}
                        fieldStatus={errors.postal_code ? 'error' : 'default'}
                        helperText={errors.postal_code?.message}
                        disabled={serviceOptions.state === 'loading'}
                        {...postalCodeField}
                    />
                </FieldWrapper>

                {serviceOptions.state === 'loading' ? (
                    <Loader />
                ) : (
                    <Button
                        className="btn"
                        disabled={watch('postal_code') === postalCodeValue}
                    >
                        {t('actions.continue')}
                    </Button>
                )}
            </form>
        </StyledCustomerLocator>
    );
};

const FieldWrapper = styled.div`
    margin-bottom: 2.5rem;
`;

const StyledCustomerLocator = styled.section`
    .row {
        padding: 0 1rem;
    }

    .btn {
        width: 100%;

        ${(props) => props.theme.mediaQuery(props.theme.breakpoints.medium)} {
            min-width: 344px;
            margin-left: auto;
            margin-right: auto;
            width: auto;
        }
    }

    .bucket {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;

        ${(props) => props.theme.mediaQuery(props.theme.breakpoints.medium)} {
            justify-content: center;
        }
    }

    .bucket-media {
        height: 5.3125rem;
        padding-right: 1rem;
    }
`;

export default CustomerLocator;
