import { Text } from '@soluto-private/mx-asurion-ui-react';
import { useTranslation } from 'react-i18next';

const CarryInContent = () => {
    const { t } = useTranslation('common');
    return (
        <div>
            <h2>
                <Text weight="heavy" size="3">
                    {t('confirmation.subtitle')}
                </Text>
            </h2>
            <div className="text-description">
                <h3>
                    <Text weight="heavy">
                        {t('confirmation.carry_in.title1')}
                    </Text>
                </h3>
                <Text as="p">{t('confirmation.carry_in.description1')}</Text>
            </div>
            <div className="text-description">
                <h3>
                    <Text weight="heavy">
                        {t('confirmation.carry_in.title2')}
                    </Text>
                </h3>
                <Text as="p">{t('confirmation.carry_in.description2')}</Text>
            </div>
            <div className="text-description">
                <h3>
                    <Text weight="heavy">
                        {t('confirmation.carry_in.title3')}
                    </Text>
                </h3>
                <Text as="p">{t('confirmation.carry_in.description3')}</Text>
            </div>
            <div className="text-description">
                <h3>
                    <Text weight="heavy">
                        {t('confirmation.carry_in.title4')}
                    </Text>
                </h3>
                <Text as="p">{t('confirmation.carry_in.description4')}</Text>
            </div>
        </div>
    );
};
export default CarryInContent;
