import { useRecoilValueLoadable } from 'recoil';
import { useTranslation } from 'react-i18next';
import { serviceOption } from 'state/ApplicationRecoil';
import ServiceOptionsCards from './ServiceOptionsCards';
import StepHeader from '../StepHeader';
import Loader from '../Loader';
import { useDeviceName } from 'hooks';

const ServiceOptions = () => {
    const { t } = useTranslation('common');
    const { displayedDeviceName } = useDeviceName();
    const rtServiceOption = useRecoilValueLoadable(serviceOption('RT'));
    const miServiceOption = useRecoilValueLoadable(serviceOption('MI'));
    const ccServiceOption = useRecoilValueLoadable(serviceOption('CC'));

    // At this point we assume RT could be available
    const rtAvailable = true;

    const miAvailable = miServiceOption?.contents.available;
    const ccAvailable = ccServiceOption?.contents.available;

    return (
        <section className="page service-options-page">
            <StepHeader
                title={t('service_options.title', {
                    device: displayedDeviceName,
                })}
                description={t('service_options.description')}
            />

            {rtServiceOption.state === 'loading' ? (
                <Loader />
            ) : (
                <ServiceOptionsCards
                    rtAvailable={rtAvailable}
                    miAvailable={miAvailable}
                    ccAvailable={ccAvailable}
                />
            )}
        </section>
    );
};

export default ServiceOptions;
