import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import styled from 'styled-components';
import { useRecoilSnapshot, useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import { locale, appointmentIntent } from 'state/ApplicationRecoil';
import { locales } from 'utils/constants';
import ErrorBoundary from 'components/ErrorBoundary';
import Loader from 'components/Loader';
import SchedulingSteps from 'components/SchedulingFlow/SchedulingSteps';
import PrimaryNav from 'components/Navigation/PrimaryNav';
import AppNav from 'components/Navigation/AppNav';
import Footer from 'components/Navigation/Footer';
import { AlertProvider } from 'components/Alert';
import Head from 'components/Head';
import Photobooth from 'components/Photobooth';
import { APPT_INTENT_URL, paramsToQueryString } from 'utils/api';
import NotFound from 'components/NotFound';
import Maintenance from 'components/Maintenance';

function DebugObserver() {
    const snapshot = useRecoilSnapshot();

    useEffect(() => {
        console.debug('The following atoms were modified:');

        for (const node of snapshot.getNodes_UNSTABLE({
            isModified: true,
        })) {
            console.debug(node.key, snapshot.getLoadable(node));
        }
    }, [snapshot]);

    return null;
}

function App() {
    const { i18n } = useTranslation();
    // Track data from the current locale
    const setLocale = useSetRecoilState(locale);
    const history = useHistory();
    const intent = useRecoilValue(appointmentIntent);

    useEffect(() => {
        const query = qs.parse(history.location.search);

        // Remove the locale from the query
        // This ensures that if user refreshes they will keep whatever other locale they have selected
        // There is also no need to track the current locale in the url
        if (query.locale !== undefined) {
            history.replace({
                search: qs.stringify({
                    ...query,
                    locale: undefined,
                }),
            });
        }

        setLocale(locales[i18n.language]);
    }, [history, setLocale, i18n.language]);

    useEffect(() => {
        // Takes care of removing appointment intent if present, upon users leaving the site.
        function cleanupAppointmentIntent() {
            if (intent) {
                // navigator beacon can only send very restricted requests
                // we can't use json, headers, or any other method than post
                navigator.sendBeacon(
                    `${APPT_INTENT_URL}?${paramsToQueryString({
                        delete: 1,
                        time: intent.time,
                        store_id: intent.storeId,
                        intent_id: intent.intentId,
                    })}`
                );
            }
        }
        window.addEventListener('beforeunload', cleanupAppointmentIntent);
        return () => {
            window.removeEventListener(
                'beforeunload',
                cleanupAppointmentIntent
            );
        };
    });

    return (
        <Layout className="App">
            <AlertProvider>
                <PrimaryNav />

                <main
                    className="wrapper"
                    aria-live="polite"
                    id="main"
                    role="main"
                >
                    {process.env.NODE_ENV !== 'production' && <DebugObserver />}

                    <Head />
                    <Switch>
                        <Route path="/" exact>
                            <ErrorBoundary>
                                <React.Suspense
                                    fallback={<Loader full={true} />}
                                >
                                    <AppNav />

                                    <SchedulingSteps />
                                </React.Suspense>
                            </ErrorBoundary>
                        </Route>
                        <Route path="/pb" exact>
                            <Photobooth />
                        </Route>
                        <Route path="/down" exact>
                            <Maintenance />
                        </Route>
                        <Route path="*">
                            <NotFound />
                        </Route>
                    </Switch>
                </main>

                <Footer />
            </AlertProvider>
        </Layout>
    );
}

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;

    .wrapper {
        flex: 1 0 auto;
        margin: 5.125rem auto 0;
        min-height: calc(100vh - 5.125rem);
        width: 100%;
        position: relative;

        ${(props) => props.theme.mediaQuery(props.theme.breakpoints.large)} {
            margin: 6.5rem auto 0;
            min-height: calc(100vh - 6.5rem);
        }

        .page {
            margin-bottom: 5.5rem;
            margin-top: 2rem;

            &.-no-bottom-margin {
                margin-bottom: 0px;
            }
        }

        @media only screen and (min-width: 768px) {
            min-height: 500px;

            .page {
                margin-left: auto;
                margin-right: auto;
            }

            .confirmation-page,
            .contact-details-page,
            .customer-locator-page,
            .device-details-page,
            .choose-appointment-time,
            .repair-deposit-page {
                max-width: 767px;
            }

            .service-options-page,
            .repair-details-page,
            .root-device-selection-page,
            .choose-location-page,
            .photobooth-page {
                max-width: 900px;
            }
        }
    }
`;

export default App;
