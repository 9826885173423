import { Helmet } from 'react-helmet-async';
import TagManager from 'react-gtm-module';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { locales } from 'utils/constants';
import { useLocation } from 'react-router-dom';

if (process.env.REACT_APP_ENV === 'dev') {
    TagManager.initialize({
        gtmId: 'GTM-KJ8JG3D',
        auth: 'TDkPdpnDCFadDZILQivfLA',
        preview: 'env-245',
    });
}

if (process.env.REACT_APP_ENV === 'stage') {
    TagManager.initialize({
        gtmId: 'GTM-KJ8JG3D',
        auth: '54v5R-IZ8bFSf5atchDXRg',
        preview: 'env-4',
    });
}

if (process.env.REACT_APP_ENV === 'prod') {
    TagManager.initialize({
        gtmId: 'GTM-KJ8JG3D',
        auth: 'gMWs56Ku3RfunHtgNt3phw',
        preview: 'env-1',
    });
}

const Head = () => {
    const scripts = [];

    const { i18n } = useTranslation();
    const location = useLocation();

    const query = queryString.parse(location.search);

    scripts.push({
        type: 'text/javascript',
        innerHTML: `window.dataLayer = window.dataLayer || [];`,
    });

    if (query.maintenance === '1' || location.pathname === '/down') {
        scripts.push({
            type: 'text/javascript',
            innerHTML: `
                document.querySelector('.nav-top__inner').firstChild.style.display =
                    'none';
                document.querySelector('.franchising-btn').style.display = 'none';
                document.querySelector('.footer__col').style.display = 'none';
                document.querySelector(
                    '.footer__menu > .row'
                ).lastChild.style.display = 'none';
                document.querySelector('.gtm-call-btn-home').style.display = 'none';
                document
                    .querySelectorAll('.footer__container .footer__link')
                    .forEach((el) => {
                        el.style.display = 'none';
                    });
                document.querySelectorAll('.footer__button').forEach((el) => {
                    el.style.display = 'none';
                });

                document.querySelector('.gtm-call-btn-home').style.display =
                    'block';
            `,
        });
    }

    return (
        <Helmet script={scripts}>
            <html lang={locales[i18n.language].iso} />
        </Helmet>
    );
};

export default Head;
