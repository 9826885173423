const siteUrl = process.env.REACT_APP_WEBSITE_URL;

const routes = {
    about: `${siteUrl}/company/about`,
    account: `${siteUrl}/account`,
    blog: `${siteUrl}/blog`,
    careers: `${siteUrl}/careers`,
    cell_phone: `${siteUrl}/smartphone-repair`,
    computer: `${siteUrl}/computer-repair`,
    contact: `${siteUrl}/contact`,
    device_tracking: `${siteUrl}/device-tracking`,
    faq: `${siteUrl}/faq`,
    feedback: `${siteUrl}/feedback`,
    franchising: 'https://franchising.ubreakifix.com',
    game_console: `${siteUrl}/game-console-repair`,
    gift_card_balance: `${siteUrl}/gift-card-balance`,
    google_repair: `${siteUrl}/google-repair`,
    home: siteUrl,
    iphone_repair: `${siteUrl}/iphone-repair`,
    local_area: 'https://local.ubreakifix.com/',
    locations: `${siteUrl}/locations`,
    other: `${siteUrl}/other`,
    partnerships: `${siteUrl}/business-edu`,
    press: `${siteUrl}/company/press`,
    privacy_policy: `${siteUrl}/privacy-policy`,
    repairs: `${siteUrl}/repairs`,
    samsung_repair: `${siteUrl}/samsung-repair`,
    search: `${siteUrl}/search`,
    sitemap: `${siteUrl}/sitemap`,
    tablet: `${siteUrl}/tablet-repair`,
    terms_conditions: `${siteUrl}/terms-conditions`,
};

export default routes;
