import React from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import {
    Text,
    TextField,
    Textarea,
    Button,
    Dropdown,
    RadioButton,
} from '@soluto-private/mx-asurion-ui-react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
    customerDetails,
    countryCode,
    postalCode,
    bookingWindow,
} from 'state/ApplicationRecoil';
import { states, provinces, Validation } from 'utils/constants';
import { useScrollToTop, useCheckBookingWindow } from 'hooks';
import { useAlert } from 'components/Alert';
import { DropdownField, HelperText } from 'components/Field';
import Loader from 'components/Loader';
import StepHeader from '../StepHeader';

const RemoteTechLocation = () => {
    useScrollToTop();

    const { t } = useTranslation(['common', 'validation', 'attributes']);
    const [customerDetailsState, setCustomerDetails] =
        useRecoilState(customerDetails);
    const currentCountryCode = useRecoilValue(countryCode);
    const postalCodeState = useRecoilValue(postalCode);
    const resetBookingWindow = useResetRecoilState(bookingWindow);
    const { error: alertError } = useAlert();
    const { checkBookingWindowAvailability, loading: verifyingBookingWindow } =
        useCheckBookingWindow();

    const pattern = Validation.postal_code[currentCountryCode];

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();

    const submitForm = async (values) => {
        try {
            if (verifyingBookingWindow) return;

            if (values.postal_code !== postalCodeState) {
                // Check that booking window exists for new postal code
                const matchingWindow = await checkBookingWindowAvailability(
                    values.postal_code
                );

                // Booking window slot does not exist anymore
                // Send them back to select booking window step
                if (!matchingWindow) {
                    setCustomerDetails((prevState) => ({
                        ...prevState,
                        ...values,
                        hasValidLocation: false,
                    }));

                    alertError(t('errors:booking_window_not_available'));

                    resetBookingWindow();

                    return;
                }
            }

            setCustomerDetails((prevState) => ({
                ...prevState,
                ...values,
                hasValidLocation: true,
            }));
        } catch (error) {
            // Handle errors from booking windows api
            let errorMessage = 'errors:something_went_wrong';

            if (error?.error_code === 'BW001') {
                errorMessage = 'errors:invalid_address';
            }

            if (error?.error_code === 'BW002') {
                errorMessage = 'errors:no_operators_in_range';
            }

            alertError(t(errorMessage));
        }
    };

    return (
        <div className="page contact-details-page">
            <StepHeader title={t('remote_tech_location_view.title')} />

            <ContactDetailsCard>
                <form onSubmit={handleSubmit(submitForm)}>
                    <Text className="field-helper">
                        {t('attributes:location_type')}
                    </Text>
                    <Controller
                        control={control}
                        name="locationType"
                        defaultValue="home"
                        render={({ field: { onChange, value, name } }) => (
                            <div className="radio-group">
                                <RadioButton
                                    label={t('home')}
                                    value="home"
                                    name={name}
                                    checked={value === 'home'}
                                    onChange={onChange}
                                />
                                <RadioButton
                                    label={t('work')}
                                    value="work"
                                    name={name}
                                    checked={value === 'work'}
                                    onChange={onChange}
                                />
                                <RadioButton
                                    label={t('other')}
                                    value="other"
                                    name={name}
                                    checked={value === 'other'}
                                    onChange={onChange}
                                />
                            </div>
                        )}
                    />
                    <div className="field">
                        <TextField
                            label="Address 1"
                            fieldStatus={
                                errors.address_line_1 ? 'error' : 'default'
                            }
                            helperText={errors.address_line_1?.message}
                            {...register('address_line_1', {
                                required: t('validation:required', {
                                    attribute: t('attributes:address_line_1'),
                                }),
                            })}
                        />
                    </div>
                    <div className="field">
                        <TextField
                            label={`${t('attributes:address_line_2')} (${t(
                                'optional'
                            )})`}
                            {...register('address_line_2')}
                        />
                    </div>
                    <div className="field">
                        <TextField
                            label={t('attributes:city')}
                            fieldStatus={errors.city ? 'error' : 'default'}
                            helperText={errors.city?.message}
                            {...register('city', {
                                required: t('validation:required', {
                                    attribute: t('attributes:city'),
                                }),
                            })}
                        />
                    </div>
                    <div className="field-group">
                        <div className="field">
                            <Controller
                                control={control}
                                name="state"
                                rules={{
                                    required: t('validation:required', {
                                        attribute: t('attributes:state'),
                                    }),
                                }}
                                render={({
                                    field: { onChange, value, name },
                                    fieldState: { invalid },
                                }) => (
                                    <React.Fragment>
                                        <DropdownField>
                                            <Dropdown
                                                label={t('attributes:state')}
                                                name={name}
                                                options={
                                                    currentCountryCode === 'CA'
                                                        ? provinces
                                                        : states
                                                }
                                                value={value}
                                                onChange={onChange}
                                                selectClassName={`select-input${
                                                    invalid ? ' is-invalid' : ''
                                                }`}
                                            />
                                        </DropdownField>

                                        {invalid && (
                                            <HelperText>
                                                {t('validation:required', {
                                                    attribute:
                                                        t('attributes:state'),
                                                })}
                                            </HelperText>
                                        )}
                                    </React.Fragment>
                                )}
                            />
                        </div>
                        <div className="field">
                            <TextField
                                defaultValue={
                                    customerDetailsState.postal_code ||
                                    postalCodeState
                                }
                                label={t('attributes:zip_code')}
                                fieldStatus={
                                    errors.postal_code ? 'error' : 'default'
                                }
                                helperText={
                                    !errors.postal_code
                                        ? t(
                                              'remote_tech_location_view.postal_code_warning'
                                          )
                                        : errors.postal_code?.message
                                }
                                {...register('postal_code', {
                                    required: t('validation:required', {
                                        attribute: t('attributes:postal_code'),
                                    }),
                                    pattern: {
                                        value: pattern,
                                        message: t('validation:invalid', {
                                            attribute: t(
                                                'attributes:postal_code'
                                            ),
                                        }),
                                    },
                                })}
                            />
                        </div>
                    </div>

                    <div className="field">
                        <Controller
                            control={control}
                            name="special_instructions"
                            render={({
                                field: { onChange, value, name },
                                fieldState: { invalid },
                            }) => (
                                <Textarea
                                    label={`${t(
                                        'attributes:special_instructions'
                                    )} (${t('optional')})`}
                                    fieldStatus={invalid ? 'error' : 'default'}
                                    helperText={t(
                                        'special_instructions_example'
                                    )}
                                    onChange={onChange}
                                    value={value}
                                    name={name}
                                    helperTextClassName="helper-text"
                                />
                            )}
                        />
                    </div>

                    {verifyingBookingWindow ? (
                        <Loader />
                    ) : (
                        <Button
                            type="submit"
                            className="btn"
                            disabled={verifyingBookingWindow}
                        >
                            {t('actions.continue')}
                        </Button>
                    )}
                </form>
            </ContactDetailsCard>
        </div>
    );
};
const ContactDetailsCard = styled.div`
    padding: 0 1rem;

    .field {
        width: 100%;
        margin-bottom: 1rem;
        margin-right: 1rem;

        &:last-child {
            margin-right: 0;
        }
    }

    .field-group {
        display: flex;
        margin-bottom: 1rem;

        .field {
            margin-bottom: 0;
        }
    }

    .field-helper {
        display: block;
        margin-bottom: 1rem;
    }

    .radio-group {
        margin: 0 0 1rem -0.5rem;

        > label {
            margin: 0 1.375rem 0.5rem 0;

            ${(props) =>
                props.theme.mediaQuery(props.theme.breakpoints.medium)} {
                margin-right: 2.5rem;
            }
        }
    }

    // Textarea helper text needs to match that of TextField
    .helper-text {
        border-radius: 0 0 0.5rem 0.5rem;
    }

    .btn {
        width: 100%;
        margin-bottom: 2rem;
        margin-top: 2rem;

        ${(props) => props.theme.mediaQuery(props.theme.breakpoints.medium)} {
            min-width: 344px;
            margin-left: auto;
            margin-right: auto;
            width: auto;
        }
    }
`;

export default RemoteTechLocation;
