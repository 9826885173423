import { parse } from 'date-fns';

/**
 * @param {string} phoneNumberString
 * @returns {string|null}
 */
export function formatPhoneNumber(phoneNumberString) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        const intlCode = match[1] ? '+1 ' : '';
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join(
            ''
        );
    }
    return null;
}

/**
 * @param {number} distance
 * @param {string} translatedMileText
 * @param {boolean} displayDistanceInKm
 * @returns {string}
 */
export function humanReadableDistance(
    distance,
    translatedMileText,
    displayDistanceInKm = false
) {
    return `${Math.round(distance)} ${
        displayDistanceInKm
            ? 'km'
            : `${translatedMileText}${distance !== 1 ? 's' : ''}`
    }`;
}

/**
 * @param {{days: String, hours: String}[]} hours
 * @returns {{days: String, hours: String}[]}
 */
export function sortedHours(hours) {
    return [...hours].sort((a, b) => (a.days > b.days ? 1 : -1));
}

export const findDeviceByParentId = (devices, device) => {
    return devices.find((node) => node.id === device.parent_device_type_id);
};

/**
 *
 * @param {number} slice
 * @param {number} total
 * @returns {string}
 */
export function percentage(slice, total) {
    return `${(slice / total) * 100}%`;
}

/**
 * @param {string} date
 * @returns {Date}
 */
export const stringToDateWithoutOffset = (date) => {
    let getDateFromString = new Date(date);

    return new Date(
        getDateFromString.getTime() +
            Math.abs(getDateFromString.getTimezoneOffset() * 60000)
    );
};

/**
 * @param  {string} name
 * @returns {string}
 */
export const getCookieValue = (name) => {
    return (
        document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() ||
        ''
    );
};

// Need to compute the actual time based on the string that comes back from the API
// This seems to do the trick - as any attempts to manually set timezone/offset/etc. in parsing methods resulted in the wrong times
export const formatBookingWindowSlot = (slot) => {
    const start = slot.window_start.split(' ');
    const end = slot.window_end.split(' ');

    return {
        ...slot,
        window_start: new Date(`${start[0]}T${start[1]}Z`),
        window_end: new Date(`${end[0]}T${end[1]}Z`),
    };
};

export const translatedDaysForStoreOpenClosedHours = (t, days) => {
    const block = days.split(' - ', 2);
    let translatedDays = '';

    for (let i = 0; i <= block.length; i += 1) {
        translatedDays += t(block[i]);

        // Add the dash after first day in a block
        if (block.length === 2 && i === 0) {
            translatedDays += ' - ';
        }
    }

    return translatedDays;
};

// Appointment time / day helpers

// Find the first Appointment time with timeslots still available
export const getFirstAppointment = (appointments) => {
    return appointments.find((appt) => {
        return appt.at.find((timeslot) => {
            return timeslot.slots > 0;
        });
    });
};

export const getAppointmentDate = (openAppointmentDay) => {
    if (!openAppointmentDay) return;

    return stringToDateWithoutOffset(openAppointmentDay.date);
};

export const timeSlotDropdownOptions = (openAppointmentsDay) => {
    if (!openAppointmentsDay.at || !openAppointmentsDay.at.length) return null;

    return openAppointmentsDay.at
        .map((timeslot) => {
            if (timeslot.slots > 0) {
                return {
                    name: timeslot.start,
                    value: timeslot.appointment_at_utc,
                };
            }

            return false;
        })
        .filter((slot) => slot);
};

export const getFirstAppointmentTimeSlot = (timeslots) => {
    return timeslots.find((timeslot) => {
        return timeslot.slots > 0;
    });
};

export const getCurrentActiveDay = (
    selectedDay,
    currentOpenAppointments = []
) => {
    return currentOpenAppointments.find((day) => {
        const appointmentDay = parse(day.date, 'yyyy-MM-dd', new Date());
        return selectedDay.getDate() === appointmentDay.getDate();
    });
};

export const formatPrice = (locale = 'en-US', currency = 'usd', price) => {
    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
        minimumFractionDigits: 2,
    }).format(price);
};
