import { colors } from '@soluto-private/mx-asurion-ui-react';

const typography = {
    fontFamily: `Apercu !important`,
};

export const asurionColors = {
    ...colors,
    primary: colors.primaryDeep,
    disabled: '#A5AAAF',
    incomplete: '#E5E5E5',
};

const breakpoints = {
    xsmall: 320,
    small: 512,
    medium: 768,
    large: 1100,
};

const layout = {
    maxWidth: 1440,
    gutter: 16,
};

const mediaQuery = (minWidth) => {
    return `@media (min-width: ${minWidth}px)`;
};

const theme = {
    typography,
    colors: asurionColors,
    breakpoints,
    layout,
    mediaQuery,
};

export default theme;
