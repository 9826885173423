import { createGlobalStyle, css } from 'styled-components';
import {
    colors,
    getFontSize,
    getFontWeight,
} from '@soluto-private/mx-asurion-ui-react';
import usFlag from 'images/us.png';
import prFlag from 'images/pr.png';
import ttFlag from 'images/tt.png';
import qcFlag from 'images/qc.png';
import caFlag from 'images/ca.png';
import ApercuBoldProWoff from './../fonts/Apercu-Bold-Pro.woff';
import ApercuBoldProWoff2 from './../fonts/Apercu-Bold-Pro.woff2';
import ApercuRegularProWoff from './../fonts/Apercu-Regular-Pro.woff';
import ApercuRegularProWoff2 from './../fonts/Apercu-Regular-Pro.woff2';
import ApercuLightProWoff from './../fonts/Apercu-Light-Pro.woff';
import ApercuLightProWoff2 from './../fonts/Apercu-Light-Pro.woff2';

const ThemedGlobalCss = css`
    :root {
        --nav-secondary-height: 24;
        --breakpoint-desktop: 768px;
    }

    @font-face {
        font-family: 'Apercu';
        font-weight: 700;
        font-display: swap;
        src: url(${ApercuBoldProWoff2}) format('woff2'),
            url(${ApercuBoldProWoff}) format('woff');
    }

    @font-face {
        font-family: 'Apercu';
        font-display: swap;
        font-weight: 300;
        src: url(${ApercuLightProWoff2}) format('woff2'),
            url(${ApercuLightProWoff}) format('woff');
    }

    @font-face {
        font-family: 'Apercu';
        font-display: swap;
        font-weight: 400;
        src: url(${ApercuRegularProWoff2}) format('woff2'),
            url(${ApercuRegularProWoff}) format('woff');
    }

    html {
        box-sizing: border-box;
        font-size: 16px;
    }

    body {
        font-size: ${getFontSize(2)};
        font-family: 'Apercu', -apple-system, BlinkMacSystemFont, 'Segoe UI',
            Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
        font-weight: ${getFontWeight('base')};
        color: ${colors.black};
        margin: 0;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
    }

    strong {
        font-weight: ${getFontWeight('heavy')};
    }

    * {
        -webkit-font-smoothing: antialiased;
    }

    html,
    body,
    #root {
        height: 100%;
    }

    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    button {
        background: none;
        border: 0;
        cursor: pointer;
        font: inherit;
        padding: 0;
        margin: 0;
    }

    a {
        color: ${colors.primaryDeep};
        text-decoration: none;
    }

    img {
        display: block;
    }

    .skip-link {
        background: #000;
        color: #fff;
        font-weight: bold;
        left: 50%;
        padding: 3px 10px;
        position: absolute;
        transform: translate(-50%, -100%);
        z-index: 5;
    }

    .skip-link:focus {
        transform: translate(-50%, 0%);
    }

    @media print {
        .skip-link {
            display: none;
        }
    }

    // Locale flags
    .flag-icon {
        position: relative;
        display: inline-block;
        width: 1.33333333em;
        line-height: 1em;
        margin-right: 5px;

        &::before {
            content: '\xa0';
        }
    }

    .flag-icon,
    .flag-icon-background {
        background-size: contain;
        background-position: 50%;
        background-repeat: no-repeat;
    }

    .flag-icon-us {
        background-image: url(${usFlag});
    }

    .flag-icon-tt {
        background-image: url(${ttFlag});
    }

    .flag-icon-pr {
        background-image: url(${prFlag});
    }

    .flag-icon-ca {
        background-image: url(${caFlag});
    }

    .flag-icon-qc {
        background-image: url(${qcFlag});
    }

    // Reduce padding for french links
    html[lang='fr'] {
        .nav__link {
            padding-left: 7px;
            padding-right: 7px;
        }
    }
`;

export const GlobalStyles = createGlobalStyle`
    // Begin Reset
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed,
    figure, figcaption, footer, header, hgroup,
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }
    article, aside, details, figcaption, figure,
    footer, header, hgroup, menu, nav, section {
        display: block;
    }
    body {
        line-height: 1;
    }
    ol, ul {
        list-style: none;
    }
    blockquote, q {
        quotes: none;
    }
    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }
    // End Reset

    ${ThemedGlobalCss}
`;
