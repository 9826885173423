import { useEffect, useState } from 'react';

const useScrollToTop = (elementRef) => {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        if (!isScrolled) {
            window.scrollTo({
                behavior: 'smooth',
                top: elementRef ? elementRef.current.offsetTop : 0,
            });

            setIsScrolled(true);
        }
    }, [isScrolled, elementRef]);
};

export { useScrollToTop };
