import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { wrapHistory } from 'oaf-react-router';
import { HelmetProvider } from 'react-helmet-async';
import reportWebVitals from 'reportWebVitals';
import ThemeWrapper from './components/ThemeWrapper';
import { RecoilRoot } from 'recoil';
import { GlobalStyles as LocalGlobalStyles } from './styles';
import App from 'App';
import './i18n';

const history = createBrowserHistory();
const settings = {
    primaryFocusTarget: 'body',
    smoothScroll: false,
};
wrapHistory(history, settings);

ReactDOM.render(
    <React.StrictMode>
        <ThemeWrapper>
            <LocalGlobalStyles />

            <RecoilRoot>
                <Router history={history}>
                    <HelmetProvider>
                        <App />
                    </HelmetProvider>
                </Router>
            </RecoilRoot>
        </ThemeWrapper>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
