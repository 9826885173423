import { useTranslation } from 'react-i18next';
import StepHeader from 'components/StepHeader';
import DeviceFields from 'components/SchedulingFlow/DeviceDetails/DeviceFields';
import { useDeviceName } from 'hooks';
const DeviceDetails = () => {
    const { t } = useTranslation('common');
    const { rootDeviceName } = useDeviceName();

    return (
        <section className="page device-details-page">
            <StepHeader
                title={t('device_details.title', {
                    device: rootDeviceName,
                })}
            />
            <DeviceFields />
        </section>
    );
};

export default DeviceDetails;
