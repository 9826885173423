import React, { useContext, useMemo } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { locales } from 'utils/constants';
import { Modal } from '../Modal';
import { MonthSection } from './MonthSection';

interface CalendarModalProps {
    active: boolean;
    activeDate: Date;
    onExit: () => void;
    onChange: (value?: unknown) => void;
    days: Date[];
    isDateAvailable: (date: Date) => boolean;
}

const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;

    ${(props) => props.theme.mediaQuery(props.theme.breakpoints.medium)} {
        flex-direction: row;
        margin-bottom: 25px;
    }
`;

const ModalYearText = styled.p`
    font-size: 20px;
    line-height: 150%;
    text-align: center;
    width: 100%;
    margin: 23px 0;
`;

export const CalendarModal: React.FC<CalendarModalProps> = ({
    active,
    activeDate,
    onExit,
    onChange,
    days,
    isDateAvailable,
}: CalendarModalProps) => {
    const theme = useContext(ThemeContext);
    const { t, i18n } = useTranslation('common');

    const dialogStyle = useMemo(() => {
        const mediaQuery = window.matchMedia(
            `(max-width: ${theme.breakpoints.small}px)`
        );

        if (mediaQuery.matches) {
            return {
                width: '100%',
                marginTop: 275,
                marginLeft: 50,
                marginRight: 50,
            };
        }

        return {};
    }, [theme.breakpoints.small]);

    const months = useMemo(() => {
        let monthArray: string[] = [];

        days.forEach((date: Date) => {
            const month = date.toLocaleString('en-us', { month: 'long' });

            if (!monthArray.includes(month)) {
                monthArray.push(month);
            }
        });

        return monthArray;
    }, [days]);

    // Easier to do this for month translations since MonthSection utilizes hardcoded MonthToNumbers enum
    const translatedMonths = useMemo(() => {
        let monthArray: string[] = [];

        days.forEach((date: Date) => {
            // @ts-ignore
            const month = date.toLocaleString(locales[i18n.language].explicit, {
                month: 'long',
            });
            const title = month.charAt(0).toUpperCase() + month.slice(1);

            if (!monthArray.includes(title)) {
                monthArray.push(title);
            }
        });

        return monthArray;
    }, [days, i18n.language]);

    const yearOfFirstDay = days[0].getFullYear();
    const yearOfLastDay = days[days.length - 1].getFullYear();
    const yearText = `${days[0].getFullYear()}${
        yearOfFirstDay !== yearOfLastDay ? `-${yearOfLastDay}` : ''
    } `;

    const innerModal = (): JSX.Element => {
        return (
            <>
                <ModalYearText>{yearText}</ModalYearText>
                <ModalContainer>
                    {months.map((month: string, i) => (
                        <MonthSection
                            key={month}
                            month={month}
                            monthTitle={translatedMonths[i]}
                            days={days}
                            isDateAvailable={isDateAvailable}
                            activeDate={activeDate}
                            onChange={onChange}
                        />
                    ))}
                </ModalContainer>
            </>
        );
    };

    return (
        <Modal
            active={active}
            onExit={onExit}
            titleText={t('actions.select_day')}
            dialogStyle={dialogStyle}
        >
            {innerModal()}
        </Modal>
    );
};
