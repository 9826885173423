import { selector, selectorFamily, noWait } from 'recoil';
import { getDay } from 'date-fns';
import {
    error,
    deviceId,
    repairId,
    postalCode,
    serviceTypeCode,
    storeId,
    bookingWindow,
    locale,
    selectedRepairOptions,
    customerDetails,
    lead,
    payment,
    isPaymentCaptureDisabled,
    calendarActiveDate,
} from './atoms';
import {
    getDeviceTypes,
    getServiceOptions,
    getAppointmentSettings,
    getRepairOptions,
    getRepairPrice,
} from 'utils/api';
import { serviceTypeCodes, Validation } from 'utils/constants';

export const hasError = selector({
    key: 'hasError',
    get: ({ get }) => {
        const errorString = get(error);
        return !!errorString;
    },
});

export const devicesState = selector({
    key: 'devices',
    get: async ({ get }) => {
        const currentDeviceId = get(deviceId);

        const {
            data: { data },
        } = await getDeviceTypes({
            device_type_id: currentDeviceId ? parseInt(currentDeviceId) : null,
            include_extended: true,
            partner_id: null,
        });

        return data;
    },
});

export const selectedDevice = selector({
    key: 'device',
    get: ({ get }) => {
        const currentDeviceId = get(deviceId);

        // At least 1 device should be selected
        if (!currentDeviceId) return null;

        const devices = get(devicesState);

        if (devices) {
            return devices.find(
                (device) => device.id === parseInt(currentDeviceId)
            );
        }

        return null;
    },
});

export const selectedRepair = selector({
    key: 'repair',
    get: ({ get }) => {
        const currentRepairId = get(repairId);

        if (!currentRepairId) return null;

        const repairs = get(availableRepairs);

        if (repairs) {
            return repairs.find(
                (repair) => repair.id === parseInt(currentRepairId)
            );
        }

        return null;
    },
});

export const selectedRootDevice = selector({
    key: 'rootDevice',
    get: ({ get }) => {
        const currentDeviceId = get(deviceId);

        // At least 1 device should be selected
        if (!currentDeviceId) return null;

        const currentDevice = get(selectedDevice);
        const devices = get(devicesState);

        const matchedDevice = devices.find(
            (device) => device.id === currentDevice.root_parent_device_type_id
        );

        if (!matchedDevice) return null;

        return matchedDevice;
    },
});

export const rtEligible = selector({
    key: 'rtEligible',
    get: ({ get }) => {
        const repair = get(selectedRepair);
        const countryCode = get(locale)?.countryCode;

        if (repair && countryCode) {
            if (countryCode === 'US') {
                return repair.rt_eligible_us;
            }

            if (countryCode === 'CA') {
                return repair.rt_eligible_ca;
            }
        }

        return false;
    },
});

export const countryCode = selector({
    key: 'countryCode',
    get: ({ get }) => {
        const localeBasedCountryCode = get(locale).countryCode;
        const selectedServiceTypeCode = get(serviceTypeCode);
        const selectedServiceOption = get(
            serviceOption(selectedServiceTypeCode)
        );

        // Set the country code based on returned stores
        // Loop through all stores and grab the first valid country code
        if (selectedServiceOption && !!selectedServiceOption.stores) {
            return selectedServiceOption.stores
                .filter((store) => store !== null)
                .map((store) => store.country_code)
                .find((code) => code);
        } else {
            return localeBasedCountryCode;
        }
    },
});

// Just the basic service options with postal code
export const locationBasedServiceOptions = selectorFamily({
    key: 'locationBasedServiceOptions',
    get:
        (postalCode) =>
        async ({ get }) => {
            const currentLocale = get(locale);

            if (
                !currentLocale ||
                !postalCode ||
                !Validation.postal_code[currentLocale.countryCode].test(
                    postalCode
                )
            )
                return null;

            const {
                data: { data },
            } = await getServiceOptions({
                customer_location: {
                    address_line_1: null,
                    address_line_2: null,
                    city: null,
                    country: currentLocale.country,
                    postal_code: postalCode,
                    state: null,
                },
                rt_eligible: false,
            });

            if (!Array.isArray(data) || data.error_code) throw data;

            return data;
        },
});

// Utilized mainly to determine RT availability since this requires more data
export const serviceOptionsState = selector({
    key: 'services',
    get: async ({ get }) => {
        const currentLocale = get(locale);
        const postalCodeState = get(postalCode);
        const repairOptionsState = get(selectedRepairOptions);

        if (
            !currentLocale ||
            !postalCodeState ||
            !Validation.postal_code[currentLocale.countryCode].test(
                postalCodeState
            )
        )
            return null;

        const {
            data: { data },
        } = await getServiceOptions({
            customer_location: {
                address_line_1: null,
                address_line_2: null,
                city: null,
                country: currentLocale.country,
                postal_code: postalCodeState,
                state: null,
            },
            device_repair_id: get(repairId),
            device_type_option_ids: Object.values(repairOptionsState)
                .map((option) => {
                    return JSON.parse(option);
                })
                .reduce((acc, curr) => acc.concat(curr), []),
            model_number: null,
            partner_code: null,
            partner_id: null,
            rt_eligible: get(rtEligible),
        });

        if (!Array.isArray(data) || data.error_code) throw data;

        return data;
    },
    cachePolicy_UNSTABLE: {
        // Only store the most recent set of dependencies and their values
        eviction: 'most-recent',
    },
});

export const serviceOption = selectorFamily({
    key: 'service',
    get:
        (code) =>
        ({ get }) => {
            let serviceOptions;
            const postalCodeValue = get(postalCode);
            const selectedServiceTypeCode = get(serviceTypeCode);

            // RT will keep requesting service options in order to determine availability throughout
            if (code === 'RT' && selectedServiceTypeCode === 'RT') {
                serviceOptions = get(serviceOptionsState);
            } else {
                serviceOptions = get(
                    locationBasedServiceOptions(postalCodeValue)
                );
            }

            if (!code) return null;

            let serviceType = code;

            if (serviceType === 'CI') {
                serviceType = 'CC';
            }

            const service = serviceTypeCodes[serviceType];
            if (service && Array.isArray(serviceOptions)) {
                return serviceOptions.find(
                    (serviceOption) => serviceOption.id === service.id
                );
            }

            return null;
        },
});

export const availableRepairs = selector({
    key: 'repairs',
    get: ({ get }) => {
        const device = get(selectedDevice);

        if (device && device.is_bottom_level) {
            return [...device.repairs]
                .filter((repair) => repair.visibility === 1)
                .sort((a, b) => {
                    if (b.sort_order === 0) {
                        return -1;
                    } else if (a.sort_order === 0) {
                        return 1;
                    } else {
                        return a.sort_order > b.sort_order ? 1 : -1;
                    }
                });
        }

        return null;
    },
});

export const selectedStore = selector({
    key: 'store',
    get: ({ get }) => {
        const selectedServiceTypeCode = get(serviceTypeCode);
        const selectedStoreId = get(storeId);

        if (selectedServiceTypeCode) {
            const selectedServiceOption = get(
                serviceOption(selectedServiceTypeCode)
            );

            if (selectedServiceOption) {
                if (selectedServiceTypeCode === 'RT') {
                    return selectedServiceOption.store;
                }

                // For Mail-In just take the closest store
                // We assume that stores are always ordered by distance
                if (selectedServiceTypeCode === 'MI' && !selectedStoreId) {
                    return selectedServiceOption.stores[0];
                }

                return selectedServiceOption.stores.find(
                    (store) => store.id === parseInt(selectedStoreId)
                );
            }
        }

        return null;
    },
});

export const allowSameDayAppointments = selector({
    key: 'allowSameDayAppointments',
    get: ({ get }) => {
        const currentStore = get(selectedStore);
        const now = new Date();

        if (currentStore) {
            const settings = get(appointmentSettings);
            const hours = currentStore.hours.all[getDay(now)];

            if (hours) {
                // Set whether we allow same day appointments
                if (settings.config['appointments-same-day'] === '1') {
                    return true;
                }
            }
        }

        return false;
    },
});

export const storeTimezoneFromConfig = selector({
    key: 'storeTimezoneFromConfig',
    get: ({ get }) => {
        const settings = get(appointmentSettings);

        return settings ? settings.config.timezone : false;
    },
});

export const appointmentBlackoutDays = selector({
    key: 'appointmentBlackoutDays',
    get: ({ get }) => {
        const settings = get(appointmentSettings);

        if (settings) {
            return settings.blackouts;
        }

        return [];
    },
});

export const appointmentSettings = selector({
    key: 'appointmentSettings',
    get: async ({ get }) => {
        const currentStoreId = get(storeId);
        const calendarActiveDay = get(calendarActiveDate);

        if (currentStoreId) {
            const { data } = await getAppointmentSettings(
                currentStoreId,
                calendarActiveDay
            );

            return data;
        }

        return null;
    },
});

export const pricing = selector({
    key: 'pricing',
    get: async ({ get }) => {
        try {
            const serviceTypeCodeState = get(serviceTypeCode);
            const selectedRepairState = get(selectedRepair);

            // Device repair id is required for pricing to work
            if (!selectedRepairState) return null;

            let service_type_code = serviceTypeCodeState;

            // Map service type to correct code
            if (serviceTypeCodeState === 'CC') {
                service_type_code = 'CI';
            }

            const requestPayload = {
                customer_location: {
                    postal_code: get(postalCode),
                },
                store_id:
                    serviceTypeCodeState !== 'RT'
                        ? get(selectedStore).id
                        : undefined,
                service_type_code,
                device_type_id: get(selectedDevice).id,
                device_repair_option_item_id:
                    get(repairOptions).device_repair_option_item_ids,
                device_repair_id: [selectedRepairState.id],
            };

            const {
                data: { data },
            } = await getRepairPrice(requestPayload);

            Array.isArray(window.dataLayer) &&
                window.dataLayer.push({
                    price: data.price || null,
                    experiment: data.price
                        ? 'willSeePricingOnConfirmationScreen'
                        : null,
                    event: 'repairData',
                });

            // We use data.price as the gate for displaying pricing UI
            // Handle any cases where price fails to come back
            if (data.price === undefined) {
                return {
                    price: null,
                };
            }

            return data;
        } catch (err) {
            // Handle errors by returning a null price
            return {
                price: null,
            };
        }
    },
});

export const repairOptions = selector({
    key: 'repairOptions',
    get: async ({ get }) => {
        const selectedRepairId = get(repairId);

        if (!selectedRepairId) return null;

        const {
            data: { data },
        } = await getRepairOptions({
            device_repair_id: selectedRepairId,
            country_id: get(locale).countryId,
        });

        return data;
    },
});

export const allRepairOptionsSelected = selector({
    key: 'allRepairOptionsSelected',
    get: ({ get }) => {
        const selectedRepairState = get(selectedRepair);

        if (selectedRepairState) {
            const repairOptionsState = get(repairOptions);
            const selectedRepairOptionsState = get(selectedRepairOptions);

            return (
                repairOptionsState.option_types.length ===
                Object.keys(selectedRepairOptionsState).length
            );
        }

        return false;
    },
});

export const workflow = selector({
    key: 'workflow',
    get: ({ get }) => {
        const selectedServiceTypeCode = get(validServiceTypeCode);

        if (selectedServiceTypeCode === 'CC') {
            return 'wf_carryInCurbsideDefault';
        }

        if (selectedServiceTypeCode === 'RT') {
            return 'wf_remoteTech';
        }

        if (selectedServiceTypeCode === 'MI') {
            return 'wf_mailIn';
        }

        return 'default';
    },
});

// Keep track of CI/CB service type codes and any other mappings
export const validServiceTypeCode = selector({
    key: 'validServiceTypeCode',
    get: ({ get }) => {
        const selectedServiceTypeCode = get(serviceTypeCode);

        if (selectedServiceTypeCode === 'CI') {
            return 'CC';
        }

        return selectedServiceTypeCode;
    },
});

export const currentStep = selector({
    key: 'currentStep',
    get: ({ get }) => {
        const selectedRepairState = get(noWait(selectedRepair));
        const selectedStoreState = get(noWait(selectedStore));
        const selectedDeviceState = get(noWait(selectedDevice));
        const currentWorkflow = get(workflow);
        const currentLocale = get(locale);
        const currentPostalCode = get(postalCode);
        const selectedServiceTypeCode = get(serviceTypeCode);
        const selectedBookingWindow = get(bookingWindow);
        const customerDetailsState = get(customerDetails);
        const allRepairOptionsSelectedState = get(allRepairOptionsSelected);
        const isPaymentCaptureDisabledState = get(isPaymentCaptureDisabled);
        const rtEligibleState = get(rtEligible);
        const leadState = get(lead);
        const paymentState = get(payment);
        const rtServiceOption = get(noWait(serviceOption('RT')));
        const selectedServiceOption = get(
            noWait(serviceOption(selectedServiceTypeCode))
        );
        const serviceOptions = get(
            noWait(locationBasedServiceOptions(currentPostalCode))
        );

        const deviceIsBottomLevel =
            selectedDeviceState?.contents?.is_bottom_level;

        // Define any pre-steps that are shared between all workflows
        const commonWorkflowSteps = [
            'ROOT_DEVICE',
            'CUSTOMER_LOCATION',
            'SERVICE_TYPE',
        ];

        // Define the order in which steps should be evaluated
        const workflows = {
            wf_carryInCurbsideDefault: [
                ...commonWorkflowSteps,
                'DEVICE_DETAILS',
                'REPAIR_SELECTION',
                'SELECTED_REPAIR_OPTIONS',
                'CHOOSE_STORE',
                'APPT_DETAILS',
                'CONTACT_DETAILS',
                'CONFIRMATION',
            ],
            wf_remoteTech: [
                ...commonWorkflowSteps,
                'DEVICE_DETAILS',
                'REPAIR_SELECTION',
                'SELECTED_REPAIR_OPTIONS',
                'RT_NOT_AVAILABLE',
                'SELECT_BOOKING_WINDOW',
                'RT_REPAIR_LOCATION',
                'CONTACT_DETAILS',
                'PAYMENT_COLLECTION',
                'CONFIRMATION',
            ],
            wf_mailIn: [
                ...commonWorkflowSteps,
                'DEVICE_DETAILS',
                'REPAIR_SELECTION',
                'CONTACT_DETAILS',
                'MI_REPAIR_LOCATION',
                'CONFIRMATION',
            ],
            default: commonWorkflowSteps,
        };

        // This is where the logic lives for which step should be rendered
        // Different conditions for rendering in different workflows can be defined
        // The order here does not matter

        // If the criteria is met the step is passed

        const stepEval = {
            wf_carryInCurbsideDefault: {},
            wf_mailIn: {
                CONTACT_DETAILS: !!customerDetailsState.hasValidDetails,
                MI_REPAIR_LOCATION: !!leadState.id,
            },
            wf_remoteTech: {
                SERVICE_TYPE:
                    !!selectedServiceTypeCode &&
                    Object.keys(serviceTypeCodes).includes(
                        selectedServiceTypeCode
                    ),
                RT_NOT_AVAILABLE:
                    allRepairOptionsSelectedState &&
                    rtServiceOption?.contents?.available,
                SELECT_BOOKING_WINDOW:
                    !!selectedBookingWindow && !!rtEligibleState,
                RT_REPAIR_LOCATION: !!customerDetailsState.hasValidLocation,
                CONTACT_DETAILS: !isPaymentCaptureDisabledState
                    ? !!customerDetailsState.hasValidDetails
                    : !!leadState.id,
                PAYMENT_COLLECTION: !isPaymentCaptureDisabledState
                    ? paymentState.status === 'succeeded'
                    : true,
            },
            default: {
                ROOT_DEVICE:
                    selectedDeviceState.state !== 'hasError' &&
                    !!selectedDeviceState.contents,
                CUSTOMER_LOCATION:
                    !!currentLocale &&
                    serviceOptions.state === 'hasValue' &&
                    !!Validation.postal_code[
                        currentLocale?.countryCode || 'US'
                    ].test(currentPostalCode),
                CHOOSE_STORE:
                    selectedStoreState.state === 'hasValue' &&
                    !!selectedStoreState.contents,
                DEVICE_DETAILS: !!deviceIsBottomLevel,
                REPAIR_SELECTION:
                    selectedRepairState.state === 'hasValue' &&
                    !!selectedRepairState.contents,
                SELECTED_REPAIR_OPTIONS: !!allRepairOptionsSelectedState,
                SERVICE_TYPE:
                    !!selectedServiceTypeCode &&
                    selectedServiceTypeCode !== 'RT' &&
                    selectedServiceOption?.contents?.available &&
                    !!Object.keys(serviceTypeCodes).includes(
                        selectedServiceTypeCode
                    ),
                APPT_DETAILS:
                    !!customerDetailsState.appointmentTime &&
                    !!customerDetailsState.service_type_code,
                CONTACT_DETAILS: !!leadState.id,
                CONFIRMATION: false,
            },
        };

        // Merge the evaluations between the default workflow and the current workflow
        const workflowSteps = {
            ...stepEval['default'],
            ...(currentWorkflow in stepEval ? stepEval[currentWorkflow] : {}),
        };

        // Return the first step in our workflow that is not completed
        // Utilizes the eval order we define in our workflows
        const currentStep = workflows[currentWorkflow].find((step) => {
            return !workflowSteps[step];
        });

        if (process.env.REACT_APP_ENV === 'local') {
            console.log('Current Workflow/steps: ', {
                currentWorkflow,
                currentStep,
                workflowSteps,
                workflows: workflows[currentWorkflow],
            });
        }

        return currentStep;
    },
});
