import { AlertBanner } from '@soluto-private/mx-asurion-ui-react';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import DefaultContext from './Context';

const ANIMATION_TIME = 0.3;

const AnimatedBanner = styled.div`
    transition: max-height ${ANIMATION_TIME}s ease,
        opacity ${ANIMATION_TIME}s ease;
    opacity: 0;
    max-height: 0;
`;
/**
 * utility component for animation(just to avoid using css-transition-group package)
 */
const AnimatedBannerWrapper = ({ children }) => {
    const [visible, setVisible] = useState(false);
    const [closed, setClosed] = useState(false);
    useEffect(() => {
        setVisible(true);
    }, [setVisible]);
    const wrapClose = (close) => {
        return () => {
            setClosed(true);
            setTimeout(() => {
                close && close();
            }, ANIMATION_TIME * 1000);
        };
    };
    return (
        <AnimatedBanner
            style={{
                opacity: visible && !closed ? 1 : 0,
                maxHeight: visible && !closed ? '100%' : 0,
            }}
        >
            {children({ wrapClose })}
        </AnimatedBanner>
    );
};

const Alert = ({ context }) => {
    const { alerts } = useContext(context || DefaultContext);

    const type2alertBannerType = {
        INFO: 'default',
        WARN: 'warn',
        SUCCESS: 'success',
        ERROR: 'error',
    };

    return alerts.map(({ message, close, id, options: { type } }) => (
        <AnimatedBannerWrapper key={id}>
            {({ wrapClose }) => (
                <AlertBanner
                    isOpen
                    message={message}
                    onCloseClick={wrapClose(close)}
                    alertBannerType={
                        type && type in type2alertBannerType
                            ? type2alertBannerType[type]
                            : 'default'
                    }
                />
            )}
        </AnimatedBannerWrapper>
    ));
};
export default Alert;
