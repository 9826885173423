import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
    useRecoilValue,
    useRecoilValueLoadable,
    useSetRecoilState,
    useRecoilState,
} from 'recoil';
import { useTranslation } from 'react-i18next';
import {
    serviceOption,
    serviceTypeCode,
    storeId,
    customerDetails,
} from 'state/ApplicationRecoil';
import { Text } from '@soluto-private/mx-asurion-ui-react';
import Pin from 'components/Pin';
import Loader from 'components/Loader';
import SelectionCard from 'components/SelectionCard';
import StepHeader from 'components/StepHeader';
import GoogleMap from 'components/GoogleMap';

const ChooseLocation = () => {
    const { t } = useTranslation('common');
    const [customerDetailsState, setCustomerDetails] =
        useRecoilState(customerDetails);
    const selectedServiceTypeCode = useRecoilValue(serviceTypeCode);
    const { state, contents } = useRecoilValueLoadable(
        serviceOption(selectedServiceTypeCode)
    );
    const setStoreId = useSetRecoilState(storeId);
    const [showMapState, setShowMapState] = useState(false);
    const [isDesktop, setDesktop] = useState(window.innerWidth >= 768);

    const selectStore = (locationId) => {
        // Make sure to reset appointment time when selecting a new store
        if (customerDetailsState.appointmentTime) {
            setCustomerDetails((customerData) => {
                return {
                    ...customerData,
                    appointmentTime: null,
                };
            });
        }

        // Assign our selected store to id in query params
        setStoreId(String(locationId));
    };

    const storesWithOpenHours = contents?.stores
        ? contents.stores.filter((store) => {
              return store.hours.all.some((block) => {
                  return block.open;
              });
          })
        : [];

    const updateScreensize = () => {
        setDesktop(window.innerWidth >= 768);
    };

    useEffect(() => {
        window.addEventListener('resize', updateScreensize);
        return () => window.removeEventListener('resize', updateScreensize);
    }, [isDesktop]);

    const showGoogleMaps = () => setShowMapState(true);

    return (
        <Locations className="page choose-location-page">
            <StepHeader
                title={t('choose_location.title')}
                className="choose-location-title"
            />

            <LocationMap>
                {state === 'loading' && <Loader />}
                {state === 'hasValue' && contents && (
                    <React.Fragment>
                        {isDesktop ? (
                            <GoogleMap
                                locations={storesWithOpenHours.map((store) => {
                                    return {
                                        name: store.name,
                                        lat: parseFloat(store.latitude),
                                        lng: parseFloat(store.longitude),
                                    };
                                })}
                                center={{
                                    lat: parseFloat(
                                        contents.stores[0].latitude
                                    ),
                                    lng: parseFloat(
                                        contents.stores[0].longitude
                                    ),
                                }}
                            />
                        ) : showMapState ? (
                            <GoogleMap
                                locations={storesWithOpenHours.map((store) => {
                                    return {
                                        name: store.name,
                                        lat: parseFloat(store.latitude),
                                        lng: parseFloat(store.longitude),
                                    };
                                })}
                                center={{
                                    lat: parseFloat(
                                        contents.stores[0].latitude
                                    ),
                                    lng: parseFloat(
                                        contents.stores[0].longitude
                                    ),
                                }}
                            />
                        ) : (
                            <ShowMapButton onClick={showGoogleMaps}>
                                Show Map
                            </ShowMapButton>
                        )}

                        <Collection>
                            {state === 'hasValue' &&
                                storesWithOpenHours.map((location, i) => {
                                    return (
                                        <li key={location.id}>
                                            <SelectionCard
                                                id={location.id}
                                                onClick={() =>
                                                    selectStore(location.id)
                                                }
                                                className="location-card"
                                            >
                                                <Pin>
                                                    <Text
                                                        size={1}
                                                        weight="heavy"
                                                    >
                                                        {i + 1}
                                                    </Text>
                                                </Pin>

                                                <div className="location-card-details">
                                                    <div className="split">
                                                        <Text size={3}>
                                                            {location.store_branding &&
                                                                `${location.store_branding} - `}
                                                            {location.name}
                                                        </Text>
                                                    </div>

                                                    <Text size={1}>
                                                        <div>
                                                            {location.address_1}
                                                        </div>
                                                        <div>
                                                            {location.address_2}
                                                        </div>
                                                        <div>
                                                            {location.city},{' '}
                                                            {location.state},{' '}
                                                            {
                                                                location.postal_code
                                                            }
                                                        </div>
                                                    </Text>
                                                </div>
                                            </SelectionCard>
                                        </li>
                                    );
                                })}
                        </Collection>
                    </React.Fragment>
                )}
            </LocationMap>
        </Locations>
    );
};

const Locations = styled.section`
    padding: 0 1rem;

    .choose-location-title {
        padding: 0;
    }

    .split {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`;

const LocationMap = styled.div`
    .location-card-map {
        width: 100%;
        height: 350px;
        margin-bottom: 2rem;
    }

    ${(props) => props.theme.mediaQuery(props.theme.breakpoints.medium)} {
        display: flex;
    }
`;

const ShowMapButton = styled.button`
    background-color: ${(props) => props.theme.colors.primaryDeep};
    color: #fff;
    font-size: 18px;
    line-height: 50px;
    cursor: pointer;
    font-weight: 500;
    display: block;
    width: 100%;
`;

const Collection = styled.ul`
    .location-card {
        border-radius: 0rem;
        padding: 1rem;
        flex-direction: row;

        ${Pin} {
            margin-right: 1.25rem;
        }
    }

    .location-card-distance {
        margin-left: 1rem;
        white-space: nowrap;
    }

    .location-card-details {
        flex-grow: 1;

        > * + * {
            display: block;

            &:not(.hours) {
                margin-top: 0.75rem;
            }

            &.hours {
                margin-top: 1rem;
            }
        }
    }

    ${(props) => props.theme.mediaQuery(props.theme.breakpoints.medium)} {
        width: 78%;
        height: 350px;
        padding: 0;
        overflow-y: auto;

        ::-webkit-scrollbar {
            width: 10px;
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background-color: rgba(195, 199, 198);
        }
    }
`;

export default ChooseLocation;
