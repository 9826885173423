import { useTranslation } from 'react-i18next';
import StepHeader from 'components/StepHeader';
import RepairOptionsFields from './RepairOptionsFields';
import { useDeviceName } from 'hooks';

const SelectedRepairOptions = () => {
    const { t } = useTranslation('common');
    const { rootDeviceName } = useDeviceName();

    return (
        <section className="page device-details-page">
            <StepHeader
                title={t('device_details.title', {
                    device: rootDeviceName,
                })}
            />

            <RepairOptionsFields />
        </section>
    );
};

export default SelectedRepairOptions;
