import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import { ReactComponent as BackArrowIcon } from 'images/icons/back-arrow-icon.svg';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { currentStep } from 'state/ApplicationRecoil/selectors';
import ProgressNav from '../SchedulingFlow/ProgressNav';

function useBackButton() {
    const step = useRecoilValue(currentStep);
    const disabledInSteps = ['ROOT_DEVICE', 'CONFIRMATION'];
    return !disabledInSteps.includes(step);
}

const AppNav = () => {
    const history = useHistory();
    const { t } = useTranslation('common');
    const hasBackButton = useBackButton();

    return (
        <StyledAppNav>
            {hasBackButton ? (
                <button
                    aria-label={t('back_to_previous_step')}
                    className="step-back"
                    onClick={() => history.goBack()}
                >
                    <BackArrowIcon aria-hidden="true" />
                </button>
            ) : null}
            <ProgressNav />
        </StyledAppNav>
    );
};

const StyledAppNav = styled.div`
    border-bottom: 1px solid transparent;
    display: flex;
    margin-top: 1rem;
    margin-right: auto;
    margin-left: auto;
    padding: 0 10px;
    overflow: hidden;

    .step-back + .progress-nav {
        padding-right: 1.5rem;
    }

    ${(props) => props.theme.mediaQuery(props.theme.breakpoints.medium)} {
        margin-top: 2rem;
    }

    ${(props) => props.theme.mediaQuery(props.theme.breakpoints.large)} {
        max-width: 740px;
    }

    @media (min-width: 992px) {
        max-width: 960px;
    }

    @media (min-width: 1200px) {
        max-width: 1160px;
    }

    .step-back {
        cursor: pointer;
        line-height: 0;
    }
`;

export default AppNav;
