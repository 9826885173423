import styled from 'styled-components';

// For cases where Asurion-UI does not provide an error state

const DropdownField = styled.div`
    .select-input {
        &.is-invalid {
            border-radius: 0.5rem 0.5rem 0 0;
            border-color: #db1b1b;
            box-shadow: inset 0px 0px 0px 1px #db1b1b;
        }
    }
`;

const HelperText = styled.div`
    font-size: 0.875rem;
    font-weight: 400;
    border: 0.0625rem #6e767d;
    border-radius: 0 0 0.5rem 0.5rem;
    padding: 0.25rem 0.75rem;
    background-color: #db1b1b;
    color: #ffffff;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
`;

export { HelperText, DropdownField };
