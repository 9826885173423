import React from 'react';
import {
    TextField,
    Button,
    Dropdown,
} from '@soluto-private/mx-asurion-ui-react';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { customerDetails, countryCode } from 'state/ApplicationRecoil';
import { DropdownField, HelperText } from 'components/Field';
import { useLeadSubmission, useScrollToTop } from 'hooks';
import { states, provinces, Validation } from 'utils/constants';
import StepHeader from '../StepHeader';
import Loader from 'components/Loader';

const MailInRepairLocation = () => {
    useScrollToTop();

    const { t } = useTranslation(['common', 'validation', 'attributes']);
    const setCustomerDetails = useSetRecoilState(customerDetails);
    const currentCountryCode = useRecoilValue(countryCode);

    const pattern = Validation.postal_code[currentCountryCode];

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();

    const { submitLead, leadSubmissionState } = useLeadSubmission();

    const submitForm = (values) => {
        setCustomerDetails((prevState) => ({
            ...prevState,
            ...values,
        }));

        submitLead(values);
    };

    return (
        <div className="page contact-details-page">
            <StepHeader
                title={t('address_question')}
                description={t('shipping_label_instruction')}
            />

            <ContactDetailsCard>
                <form onSubmit={handleSubmit(submitForm)}>
                    <div className="field">
                        <TextField
                            label={t('attributes:address_line_1')}
                            fieldStatus={
                                errors.address_line_1 ? 'error' : 'default'
                            }
                            helperText={errors.address_line_1?.message}
                            {...register('address_line_1', {
                                required: t('validation:required', {
                                    attribute: t('attributes:address_line_1'),
                                }),
                            })}
                        />
                    </div>
                    <div className="field">
                        <TextField
                            label={`${t('attributes:address_line_2')} (${t(
                                'optional'
                            )})`}
                            {...register('address_line_2')}
                        />
                    </div>
                    <div className="field">
                        <TextField
                            label={t('attributes:city')}
                            fieldStatus={errors.city ? 'error' : 'default'}
                            helperText={errors.city?.message}
                            {...register('city', {
                                required: t('validation:required', {
                                    attribute: t('attributes:city'),
                                }),
                            })}
                        />
                    </div>
                    <div className="field-group">
                        <div className="field">
                            <Controller
                                control={control}
                                name="state"
                                rules={{
                                    required: t('validation:required', {
                                        attribute: t('attributes:state'),
                                    }),
                                }}
                                render={({
                                    field: { onChange, value, name },
                                    fieldState: { invalid },
                                }) => (
                                    <React.Fragment>
                                        <DropdownField>
                                            <Dropdown
                                                label={t('attributes:state')}
                                                name={name}
                                                options={
                                                    currentCountryCode === 'CA'
                                                        ? provinces
                                                        : states
                                                }
                                                value={value}
                                                onChange={onChange}
                                                selectClassName={`select-input${
                                                    invalid ? ' is-invalid' : ''
                                                }`}
                                            />
                                        </DropdownField>

                                        {invalid && (
                                            <HelperText>
                                                {t('validation:required', {
                                                    attribute:
                                                        t('attributes:state'),
                                                })}
                                            </HelperText>
                                        )}
                                    </React.Fragment>
                                )}
                            />
                        </div>
                        <div className="field">
                            <TextField
                                label={t('attributes:zip_code')}
                                fieldStatus={
                                    errors.postal_code ? 'error' : 'default'
                                }
                                helperText={errors.postal_code?.message}
                                {...register('postal_code', {
                                    required: t('validation:required', {
                                        attribute: t('attributes:postal_code'),
                                    }),
                                    pattern: {
                                        value: pattern,
                                        message: t('validation:invalid', {
                                            attribute: t(
                                                'attributes:postal_code'
                                            ),
                                        }),
                                    },
                                })}
                            />
                        </div>
                    </div>

                    <Button
                        type="submit"
                        className="btn"
                        disabled={leadSubmissionState.loading}
                    >
                        {t('actions.finish')}
                    </Button>

                    {leadSubmissionState.loading && <Loader />}
                </form>
            </ContactDetailsCard>
        </div>
    );
};
const ContactDetailsCard = styled.div`
    padding: 0 1rem;

    .field {
        width: 100%;
        margin-bottom: 1rem;
        margin-right: 1rem;

        &:last-child {
            margin-right: 0;
        }
    }

    .field-group {
        display: flex;
        margin-bottom: 1rem;

        .field {
            margin-bottom: 0;
        }
    }

    .btn {
        width: 100%;
        margin-bottom: 2rem;
        margin-top: 2rem;

        ${(props) => props.theme.mediaQuery(props.theme.breakpoints.medium)} {
            min-width: 344px;
            margin-left: auto;
            margin-right: auto;
            width: auto;
        }
    }
`;

export default MailInRepairLocation;
