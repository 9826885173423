import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { locales } from 'utils/constants';
import { ReactComponent as Logo } from 'images/brand-black-logo.svg';
import { Alert } from 'components/Alert';

const PrimaryNav = () => {
    const scrollDelta = 10;
    const scrollOffset = 150;
    const { t, i18n } = useTranslation(['common', 'routes']);
    const [scrollDirection, setScrollDirection] = useState('up');
    const [scrolling, setScrolling] = useState(false);
    const [previousTop, setPreviousTop] = useState(0);

    const autoHideHeader = useCallback(() => {
        const currentTop = window.scrollY;

        if (previousTop - currentTop > scrollDelta) {
            setScrollDirection('up');
        } else if (
            currentTop - previousTop > scrollDelta &&
            currentTop > scrollOffset
        ) {
            setScrollDirection('down');
        }

        setPreviousTop(currentTop);
        setScrolling(false);
    }, [previousTop]);

    const handleScroll = useCallback(() => {
        document.documentElement.style.setProperty(
            '--scroll-y',
            window.scrollY
        );

        if (window.innerWidth >= 768) {
            if (!scrolling) {
                setScrolling(true);

                !window.requestAnimationFrame
                    ? setTimeout(autoHideHeader, 250)
                    : requestAnimationFrame(autoHideHeader);
            }
        }
    }, [autoHideHeader, scrolling]);

    const handleResize = useCallback(() => {
        // Set global window width variable
        document.documentElement.style.setProperty(
            '--window-width',
            window.innerWidth
        );

        if (
            document.body.classList.contains('frozen') &&
            window.innerWidth >= 768
        ) {
            unfreeze();
        }
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('scroll', handleResize);
        };
    }, [handleScroll, handleResize]);

    const unfreeze = () => {
        const body = document.body;
        const scrollY = body.style.top;
        body.style.overflow = '';
        body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);

        body.classList.remove('frozen');
    };

    return (
        <StyledMenu
            className={scrollDirection === 'down' ? 'is-scrolled' : ''}
            id="nav-container"
        >
            <a className="skip-link" href="#main">
                {t('skip_to_content')}
            </a>

            <nav className="nav-top" id="nav-top">
                <div className="nav-container">
                    <div className="nav-top__inner">
                        <div className="nav-top__item nav-dropdown-item">
                            <button
                                className="nav-top__action"
                                id="my-repairs-n"
                            >
                                <i className="far fa-user" aria-hidden="true" />
                                <span className="nav-caret" />
                                <span className="nav-top__action-text">
                                    {t('my_repairs')}
                                </span>
                            </button>

                            <ul
                                className="nav-dropdown-list"
                                role="menu"
                                aria-labelledby="my-repairs-n"
                            >
                                <li role="menuitem">
                                    <a
                                        className="nav__link"
                                        href={t('routes:device_tracking')}
                                    >
                                        {t('track_repair')}
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="nav-top__item nav-dropdown-item">
                            <button
                                className="nav-top__action"
                                id="menu-language"
                                aria-haspopup="true"
                            >
                                <span
                                    className={`flag-icon flag-icon-${
                                        locales[i18n.language].short
                                    }`}
                                />
                                <span>{locales[i18n.language].long}</span>
                                <span className="nav-caret" />
                            </button>

                            <ul
                                className="nav-dropdown-list"
                                role="menu"
                                aria-labelledby="menu-language"
                            >
                                {Object.keys(
                                    i18n.services.resourceStore.data
                                ).map((lang) => {
                                    return (
                                        <li key={lang}>
                                            <button
                                                className="nav__link"
                                                onClick={() =>
                                                    i18n.changeLanguage(lang)
                                                }
                                                role="menuitem"
                                            >
                                                <span
                                                    className={`flag-icon flag-icon-${locales[lang].short}`}
                                                />
                                                {'\u00A0'} {locales[lang].long}
                                            </button>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>

                        <div className="nav-top__item">
                            <a
                                className="nav-top__btn tls franchising-btn gtm-btn-menu-franchising"
                                href={t('routes:franchising')}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <span className="nav-top__btn-text">
                                    {t('franchising')}
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </nav>

            <nav className="nav-menu" id="nav-menu">
                <div className="nav-container">
                    <div className="nav-menu__inner">
                        <a
                            className="nav-menu__mark"
                            href={t('routes:home')}
                            aria-label={t('visit_website')}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Logo className="nav-menu__image" />
                        </a>
                    </div>
                </div>
                <Alert />
            </nav>
        </StyledMenu>
    );
};

export default PrimaryNav;

const StyledMenu = styled.header`
    background-color: #fff;
    flex-shrink: 0;
    font-size: 14px;
    position: fixed;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
    transition: transform 0.5s ease;
    z-index: 9999;
    line-height: 1.5;

    &.is-scrolled {
        ${(props) => props.theme.mediaQuery(props.theme.breakpoints.large)} {
            transform: translateY(calc(var(--nav-secondary-height) * -1.5px));
        }
    }

    .nav-caret {
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid #000000;
        position: absolute;
        top: 50%;
        right: 0;
        margin-left: 2.5px;
        transform: translateY(-50%);
        transition: 0.2s color ease-in-out;
    }

    // Small bar above main navigation
    .nav-top {
        display: none;

        ${(props) => props.theme.mediaQuery(props.theme.breakpoints.large)} {
            background: #f0f0f5;
            font-size: 12px;
            font-weight: 700;
            position: relative;
            pointer-events: auto;
            display: block;
            text-transform: uppercase;
            width: 100%;
            z-index: 4;
        }
    }

    .nav-top__btn {
        background-color: #000;
        color: #ffffff;
        display: inline-block;
        padding: 0.4375rem 0.875rem;
        text-align: center;
        transition-duration: 0.3s;
        transition-timing-function: ease;
        transition-property: background-color, color;

        &:hover,
        &:focus {
            background-color: #000;
            color: #ffffff;
        }
    }

    .nav-top__btn-text {
        display: inline-block;
        font-size: 14px;
    }

    .nav-top__item {
        display: flex;
        margin-left: 2.5rem;
        vertical-align: middle;

        &:first-child {
            margin-left: 0;
        }
    }

    .nav-top__action {
        align-items: center;
        color: #000;
        font-size: 14px;
        line-height: 1;
        text-transform: uppercase;
        position: relative;

        &#my-repairs-n,
        &#menu-language {
            padding-right: 1.375rem;
        }

        &:hover,
        &:focus {
            color: #000;

            .nav-caret {
                color: #000;
            }
        }

        i {
            font-size: 1rem;
            margin-right: 0.625rem;
            vertical-align: middle;
        }

        .flag-icon {
            margin-right: 0.625rem;
        }
    }

    .nav-top__action-text {
        display: inline-block;
        vertical-align: middle;
        position: relative;
    }

    .nav-top__inner {
        align-items: stretch;
        display: flex;
        justify-content: flex-end;
        text-align: right;
        min-height: 35px;
    }

    // Main nav bar containing brand mark
    .nav-menu {
        background-color: #ffffff;
        pointer-events: auto;
        position: relative;
        z-index: 3;

        .nav-dropdown-list {
            border-top: 0;
        }

        ${(props) => props.theme.mediaQuery(props.theme.breakpoints.large)} {
            padding: 0;
        }
    }

    .nav-container {
        margin-right: auto;
        margin-left: auto;
        padding: 1rem 10px;

        @media (min-width: 768px) {
            max-width: 740px;
        }

        @media (min-width: 992px) {
            max-width: 960px;
        }

        ${(props) => props.theme.mediaQuery(props.theme.breakpoints.large)} {
            padding: 0 10px;
        }

        @media (min-width: 1200px) {
            max-width: 1160px;
        }
    }

    .nav-menu__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;

        ${(props) => props.theme.mediaQuery(props.theme.breakpoints.large)} {
            margin-top: 1rem;
        }
    }

    // The full menu on mobile when toggled
    .nav-full {
        background-color: #333f48;
        list-style-type: none;
        top: 0;
        left: 0;
        padding: 0;
        position: fixed;
        overflow-x: hidden;
        overflow-y: auto;
        height: 100vh;
        width: 100%;
        margin: 0;
        opacity: 0;
        visibility: hidden;
        transition-duration: 0.5s;
        transition-timing-function: ease;
        transition-property: opacity;

        //  Top-level Items
        li {
            opacity: 0;
            overflow: hidden;
            transform: translateY(5px) scale(0.75);
            transform-origin: top center;
            transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;

            // Direct link
            &.is-open {
                > a {
                    background-color: #eeeeee;
                    color: #000;
                }
            }
        }

        &.is-active {
            opacity: 1;
            visibility: visible;

            li {
                transform: scale(1);
                opacity: 1;
            }
        }

        // Desktop menu
        ${(props) => props.theme.mediaQuery(props.theme.breakpoints.large)} {
            background-color: #ffffff;
            background-image: none;
            overflow: inherit;
            position: relative;
            height: auto;
            width: auto;
            opacity: 1;
            visibility: visible;

            li {
                align-items: center;
                display: flex;
                margin: 0;
                transform: scale(1);
                opacity: 1;
                overflow: visible;
            }
        }
    }

    // Links
    .nav__link {
        background-color: transparent;
        color: #fff;
        display: block;
        font-size: 1.5rem;
        font-weight: 300;
        line-height: 1.3;
        position: relative;
        padding: 1.5rem 0;
        text-decoration: none;
        text-transform: capitalize;
        transition-duration: 0.2s;
        transition-timing-function: ease-in-out;
        transition-property: color, background-color;

        &:hover,
        &:focus {
            outline: none;
        }

        ${(props) => props.theme.mediaQuery(props.theme.breakpoints.large)} {
            color: #000;
            font-size: 1rem;
            line-height: 1.3;
            padding: 2.625rem 1.875rem;

            &::after {
                content: '';
                border-bottom: 3px solid #000;
                display: inline-block;
                width: 35%;
                height: 5px;
                opacity: 0;
                position: absolute;
                bottom: 20px;
                left: 50%;
                transform: translateX(-50%);
                transition: opacity 0.2s ease-in-out;
            }

            &:hover,
            &:focus {
                color: #000;

                &::after {
                    opacity: 1;
                }
            }
        }

        @media (min-width: 1200px) {
            font-size: 1.15em;
            padding: 28px 20px;
        }
    }

    // The full nav list
    .nav-full__list {
        list-style-type: none;
        margin: 0;
        padding: 5rem 1.5rem 0;

        > li {
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);

            &:first-child {
                border-top: 1px solid rgba(255, 255, 255, 0.2);
            }
        }

        ${(props) => props.theme.mediaQuery(props.theme.breakpoints.large)} {
            display: flex;
            padding: 0;

            > li {
                border: none;
            }
        }
    }

    // The top of the full menu containing x button
    .nav-full__header {
        display: flex;
        left: 0;
        padding: 1rem 0.625rem;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 2;
        justify-content: center;

        ${(props) => props.theme.mediaQuery(props.theme.breakpoints.large)} {
            display: none;
        }
    }

    // The logo
    .nav-menu__mark {
        flex-shrink: 0;
        flex-grow: 1;
        text-align: center;

        ${(props) => props.theme.mediaQuery(props.theme.breakpoints.large)} {
            flex-grow: 0;
            order: -1;
            padding-left: 0;
            text-align: left;
            height: auto;
        }
    }

    .nav-menu__image {
        height: 3rem;
        width: auto;

        @media (min-width: 768px) {
            height: auto;
            width: 100px;
        }

        @media (min-width: 992px) {
            width: 140px;
        }

        @media (min-width: 1200px) {
            width: 180px;
        }
    }

    // A list item containing a dropdown list
    .nav-dropdown-item {
        position: relative;
        z-index: 2;

        &:hover,
        &.is-open {
            .nav-dropdown-list {
                opacity: 1;
                visibility: visible;
                transform: none;
            }
        }

        &:focus-within {
            .nav-dropdown-list {
                opacity: 1;
                visibility: visible;
                transform: none;
            }
        }
    }

    // The dropdown for sub menus
    .nav-dropdown-list {
        background: rgba(0, 0, 0, 0.15);
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 0 0 4px 4px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        opacity: 0;
        visibility: hidden;
        min-width: 160px;
        margin: 0;
        padding: 0;
        list-style-type: none;
        transform: translateY(5px);
        transition-property: opacity, visibility, transform;
        transition-timing-function: ease-in-out;
        transition-duration: 0.15s;
        z-index: 100;
        // Handle list dropdowns - start hidden
        // Above mobile these will be actived on hover
        display: none;

        // Nested list items
        li {
            display: block;
            margin: 0;
            width: 100%;
        }

        // Nested links
        .nav__link {
            font-size: 22px;
            padding: 7px 20px;
            width: 100%;

            ${(props) =>
                props.theme.mediaQuery(props.theme.breakpoints.large)} {
                font-size: 14px;
                font-weight: 400;
                text-align: left;
                text-transform: none;
                white-space: nowrap;

                &::after {
                    content: none;
                }

                &:hover,
                &:focus {
                    background-color: #f5f5f5;
                }
            }
        }

        ${(props) => props.theme.mediaQuery(props.theme.breakpoints.large)} {
            background: #ffffff;
            display: block;
            position: absolute;
            top: 100%;
        }
    }

    // Nav utils
    .nav-hide-lg {
        ${(props) => props.theme.mediaQuery(props.theme.breakpoints.large)} {
            display: none !important;
        }
    }

    .nav-display-lg {
        @media (max-width: ${(props) => props.theme.breakpoints.large}px) {
            display: none !important;
        }
    }
`;
