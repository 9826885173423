import styled from 'styled-components';
import { AsurionDoodleSpinner } from '@soluto-private/mx-asurion-ui-react';

const Loader = (props) => {
    return (
        <Container {...props}>
            <AsurionDoodleSpinner />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    ${(props) =>
        props.full &&
        `
            background-color: #fff;
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
        `}
`;

export default Loader;
