import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { locales } from 'utils/constants';
import { useEffect } from 'react';
import {
    useRecoilValueLoadable,
    useRecoilState,
    useResetRecoilState,
} from 'recoil';
import { Text, ButtonSelectionCard } from '@soluto-private/mx-asurion-ui-react';
import { deviceId, devicesState } from 'state/ApplicationRecoil';
import StepHeader from '../StepHeader';
import { ReactComponent as RightCaret } from 'images/right-caret.svg';
import { useAlert, SimpleAlert } from 'components/Alert';
import Loader from 'components/Loader';

const RootDeviceSelection = () => {
    const { t, i18n } = useTranslation(['common', 'errors']);

    const { warn: alertError } = useAlert();
    const resetDeviceId = useResetRecoilState(deviceId);

    const [deviceIdState, setDeviceId] = useRecoilState(deviceId);
    const devices = useRecoilValueLoadable(devicesState);

    useEffect(() => {
        if (devices.state === 'hasError' && deviceIdState) {
            alertError(t('errors:invalid_device'));
            resetDeviceId();
        }
    }, [devices, resetDeviceId, alertError, deviceIdState, t]);

    return (
        <section className="page root-device-selection-page">
            <StepHeader
                title={t('root_device_selection.title')}
                description={t('root_device_selection.description')}
            />

            {devices.state === 'loading' && <Loader />}

            <Collection>
                {devices.state === 'hasError' && (
                    <SimpleAlert
                        error
                        message={t('errors:error_has_occurred_oops')}
                    />
                )}

                {devices.state === 'hasValue' &&
                    devices.contents &&
                    devices.contents.map((device, index) => {
                        return (
                            <ButtonSelectionCard
                                key={device.id}
                                buttonClassName={`item btn-nf-${device.name[
                                    'us_en'
                                ]
                                    .replace(' ', '-')
                                    .toLowerCase()}-repair-cta
                                `}
                                iconWrapperClassName="icon-wrapper"
                                onClick={() => setDeviceId(String(device.id))}
                                data-testid={`repair-cta-button-group-${index}`}
                            >
                                <div className="image-container">
                                    <img
                                        className="image"
                                        src={device.image_link}
                                        alt=""
                                    />
                                </div>

                                <Text size={3} className="text">
                                    {
                                        device.name[
                                            locales[i18n.language]
                                                .apiTranslationKey
                                        ]
                                    }
                                </Text>
                                <RightCaret className="icon" />
                            </ButtonSelectionCard>
                        );
                    })}
            </Collection>
        </section>
    );
};

const Collection = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    padding: 0 1rem;

    ${(props) => props.theme.mediaQuery(props.theme.breakpoints.medium)} {
        grid-template-columns: 1fr 1fr;
    }

    .item {
        color: #000;
        padding: 0 1.5rem;
        height: 5.3125rem;

        .image-container {
            display: inline-flex;
            align-items: center;
            flex: 0 0 auto;
            height: 100%;
            margin-right: 1.5rem;
        }

        .image {
            height: 70px;
            width: 70px;
        }

        .icon {
            margin-left: auto;
        }

        .icon-wrapper,
        > div {
            display: none;
        }

        .text {
            padding-right: 1.5rem;
            text-align: left;
        }
    }
`;

export default RootDeviceSelection;
