import styled from 'styled-components';

const Pin = styled.div`
    background-color: ${(props) => props.theme.colors.primaryDeep};
    border-radius: 50%;
    color: #fff;
    flex-shrink: 0;
    height: 19px;
    width: 18px;
    text-align: center;
    line-height: 18px;

    &::after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        margin: 0 auto;
        position: relative;
        bottom: 2px;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 8px solid ${(props) => props.theme.colors.primaryDeep};
    }
`;

export default Pin;
