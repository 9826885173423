import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import common_en from 'translations/en-US/common.json';
import validation_en from 'translations/en-US/validation.json';
import attributes_en from 'translations/en-US/attributes.json';
import errors_en from 'translations/en-US/errors.json';
import routes_en from 'translations/en-US/routes';

import common_es from 'translations/es-419/common.json';
import validation_es from 'translations/es-419/validation.json';
import attributes_es from 'translations/es-419/attributes.json';
import errors_es from 'translations/es-419/errors.json';
import routes_es from 'translations/es-419/routes';

import common_fr from 'translations/fr-CA/common.json';
import validation_fr from 'translations/fr-CA/validation.json';
import attributes_fr from 'translations/fr-CA/attributes.json';
import errors_fr from 'translations/fr-CA/errors.json';
import routes_fr from 'translations/fr-CA/routes';

import common_ca from 'translations/en-CA/common.json';
import attributes_enCA from 'translations/en-CA/attributes.json';
import routes_enCA from 'translations/en-CA/routes';

import routes_enTT from 'translations/en-TT/routes';

i18n.use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        detection: {
            lookupQuerystring: 'locale',
        },
        resources: {
            us_en: {
                common: common_en,
                validation: validation_en,
                attributes: attributes_en,
                errors: errors_en,
                routes: routes_en,
            },
            us_es: {
                common: common_es,
                validation: validation_es,
                attributes: attributes_es,
                errors: errors_es,
                routes: routes_es,
            },
            ca_en: {
                common: common_ca,
                attributes: attributes_enCA,
                routes: routes_enCA,
            },
            ca_fr: {
                common: common_fr,
                validation: validation_fr,
                attributes: attributes_fr,
                errors: errors_fr,
                routes: routes_fr,
            },
            tt_en: {
                routes: routes_enTT,
            },
        },
        supportedLngs: ['us_en', 'us_es', 'ca_fr', 'tt_en', 'ca_en'],
        load: 'currentOnly',
        defaultNS: 'common',
        fallbackLng: 'us_en',
        debug: process.env.NODE_ENV !== 'production',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
