import { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Text, ButtonSelectionCard } from '@soluto-private/mx-asurion-ui-react';
import {
    useRecoilValueLoadable,
    useResetRecoilState,
    useSetRecoilState,
} from 'recoil';
import {
    repairId,
    availableRepairs,
    selectedRepairOptions,
    bookingWindow,
} from 'state/ApplicationRecoil';
import { locales } from 'utils/constants';
import { useDeviceName } from 'hooks';
import StepHeader from '../StepHeader';
import { ReactComponent as RightCaret } from 'images/right-caret.svg';

const RepairSelection = () => {
    const { t, i18n } = useTranslation('common');
    const setRepairId = useSetRecoilState(repairId);
    const repairs = useRecoilValueLoadable(availableRepairs);
    const { rootDeviceName } = useDeviceName();
    const resetSelectedRepairOptions = useResetRecoilState(
        selectedRepairOptions
    );
    const resetBookingWindow = useResetRecoilState(bookingWindow);

    // Reset any selected repair options and booking window when going back to repairs view
    // This view (Repair Selection) is the last step tracked in history
    useEffect(() => {
        resetSelectedRepairOptions();
        resetBookingWindow();
    }, [resetSelectedRepairOptions, resetBookingWindow]);

    return (
        <section className="page repair-details-page">
            <StepHeader
                title={t('repair_selection.title', {
                    device: rootDeviceName,
                })}
            />

            <Collection>
                {repairs.state === 'hasValue' &&
                    repairs.contents.map((repair) => {
                        return (
                            <ButtonSelectionCard
                                key={repair.id}
                                buttonClassName="item"
                                iconWrapperClassName="icon-wrapper"
                                onClick={() => setRepairId(String(repair.id))}
                            >
                                <span className="image-container">
                                    <img
                                        className="image"
                                        src={
                                            repair.repair_type.symptom_image_url
                                        }
                                        alt=""
                                    />
                                </span>

                                <Text size={3} className="text">
                                    {
                                        repair.repair_type.symptom[
                                            locales[i18n.language]
                                                .apiTranslationKey
                                        ]
                                    }
                                </Text>

                                <RightCaret className="icon" />
                            </ButtonSelectionCard>
                        );
                    })}
            </Collection>
        </section>
    );
};

// TODO: Same styling as in RootDeviceSelection
const Collection = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    padding: 0 1rem;

    @media only screen and (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
    }

    .item {
        color: #000;
        padding: 0 1.5rem;
        height: 5.3125rem;

        .image-container {
            display: inline-flex;
            flex: 0 0 auto;
            width: calc(42px + 1.5rem);
        }

        .image {
            height: 42px;
            width: 42px;
        }

        .icon {
            margin-left: auto;
        }

        .icon-wrapper,
        > div {
            display: none;
        }

        .text {
            padding-right: 1.5rem;
            text-align: left;
        }
    }
`;

export default RepairSelection;
