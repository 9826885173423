import styled from 'styled-components';
import { Text } from '@soluto-private/mx-asurion-ui-react';
import PropTypes from 'prop-types';

const StepHeader = ({ title, description, children, className }) => {
    return (
        <Header className={className}>
            {children}

            <Text as="h1" className="title" size={5} weight="feather">
                {title}
            </Text>

            {description && (
                <Text as="p" className="desc" size={3}>
                    {description}
                </Text>
            )}
        </Header>
    );
};

const Header = styled.header`
    margin-bottom: 2rem;
    padding: 0 1rem;

    ${(props) => props.theme.mediaQuery(props.theme.breakpoints.medium)} {
        margin-bottom: 3rem;
        text-align: center;
    }

    .desc {
        margin-top: 0.5rem;
    }
`;

StepHeader.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    children: PropTypes.node,
};

export default StepHeader;
