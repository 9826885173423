import { useRecoilValueLoadable } from 'recoil';
import { useTranslation } from 'react-i18next';
import { serviceOption } from 'state/ApplicationRecoil';
import ServiceOptionsCards from './ServiceOptionsCards';
import StepHeader from '../StepHeader';
import Loader from '../Loader';

const RemoteTechNotAvailable = () => {
    const { t } = useTranslation('common');
    const rtServiceOption = useRecoilValueLoadable(serviceOption('RT'));
    const miServiceOption = useRecoilValueLoadable(serviceOption('MI'));
    const ccServiceOption = useRecoilValueLoadable(serviceOption('CC'));

    const miAvailable = miServiceOption?.contents.available;
    const ccAvailable = ccServiceOption?.contents.available;

    return (
        <section className="page service-options-page">
            {rtServiceOption.state === 'loading' ? (
                <Loader />
            ) : (
                <>
                    <StepHeader
                        title={t('service_options.rt_not_available')}
                        description={t('service_options.description')}
                    />
                    <ServiceOptionsCards
                        rtAvailable={false}
                        miAvailable={miAvailable}
                        ccAvailable={ccAvailable}
                    />
                </>
            )}
        </section>
    );
};

export default RemoteTechNotAvailable;
