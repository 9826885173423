import { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import {
    isAfter,
    isToday,
    addMonths,
    eachDayOfInterval,
    startOfMonth,
    endOfMonth,
    format,
} from 'date-fns';
import { useTranslation } from 'react-i18next';
import {
    useRecoilValueLoadable,
    useRecoilState,
    useSetRecoilState,
    useResetRecoilState,
    useRecoilRefresher_UNSTABLE,
} from 'recoil';
import { Dropdown, Text, Button } from '@soluto-private/mx-asurion-ui-react';
import {
    selectedStore,
    allowSameDayAppointments,
    appointmentBlackoutDays,
    customerDetails,
    appointmentSettings,
    calendarActiveDate,
    appointmentIntent,
    appointmentSlotTaken,
} from 'state/ApplicationRecoil';
import StepHeader from 'components/StepHeader';
import Loader from 'components/Loader';
import {
    formatPhoneNumber,
    getAppointmentDate,
    timeSlotDropdownOptions,
    getFirstAppointmentTimeSlot,
    getCurrentActiveDay,
} from 'utils/helpers';
import { DatePicker } from 'components/DatePicker';
import { SimpleAlert } from 'components/Alert';
import SameDayNotAvailCallout from './ScheduleRepair/SameDayNotAvailCallout';
import TimeSlotTakenToast from './ScheduleRepair/TimeSlotTakenToast';
import { appointmentIntentRequest } from 'utils/api';

const ScheduleRepair = () => {
    const { t } = useTranslation(['common', 'errors']);
    const [intent, setIntent] = useRecoilState(appointmentIntent);
    const currentSelectedStore = useRecoilValueLoadable(selectedStore);
    const allowSameDay = useRecoilValueLoadable(allowSameDayAppointments);
    const blackoutDays = useRecoilValueLoadable(appointmentBlackoutDays);
    const resetCalendarActiveDate = useResetRecoilState(calendarActiveDate);

    // Appointment settings data
    const appointmentSettingsState =
        useRecoilValueLoadable(appointmentSettings);

    const setCustomerDetails = useSetRecoilState(customerDetails);
    const setActiveCalendarDate = useSetRecoilState(calendarActiveDate);
    const [currentActiveDay, setCurrentActiveDay] = useState(new Date());
    const [appointmentTime, setAppointmentTime] = useState();
    const timer = useRef();
    const refresh = useRecoilRefresher_UNSTABLE(appointmentSettings);

    const currentDaysOfWeek =
        appointmentSettingsState.contents?.open_appointments;

    const [appointmentTimeOptions, setAppointmentTimeOptions] = useState();
    const [loadingBuffer, setLoadingBuffer] = useState(false);
    const [currentAppointmentSlotTaken, setAppointmentSlotTaken] =
        useRecoilState(appointmentSlotTaken);

    const startForInterval = useRef(startOfMonth(new Date()));
    const endForInterval = useRef(endOfMonth(addMonths(new Date(), 1)));
    const days = useRef(
        eachDayOfInterval({
            start: startForInterval.current,
            end: endForInterval.current,
        })
    );

    useEffect(() => {
        refresh();

        return () => {
            clearTimeout(timer.current);

            resetCalendarActiveDate();
            setAppointmentSlotTaken(false);
        };
    }, [refresh, resetCalendarActiveDate, setAppointmentSlotTaken]);

    useEffect(() => {
        if (appointmentSettingsState.state === 'hasValue') {
            const { next_available_appointment, open_appointments } =
                appointmentSettingsState.contents;

            // Just use the active day for data
            const activeDay = getCurrentActiveDay(
                currentActiveDay,
                open_appointments
            );

            if (activeDay) {
                const timeSlotOptions = timeSlotDropdownOptions(activeDay);
                const appointmentTimeSlot = getFirstAppointmentTimeSlot(
                    activeDay.at
                );

                setAppointmentTimeOptions(timeSlotOptions);
                setAppointmentTime(appointmentTimeSlot);
            }

            // We should always be starting from the next available appointment
            if (
                isAfter(
                    getAppointmentDate(next_available_appointment),
                    currentActiveDay
                )
            ) {
                // Add a fake loading state here to account for instances where we re-request data
                // Loading state is managed by recoil selector and we trigger a second request when setting next_available_appointment here
                setLoadingBuffer(true);
                timer.current = setTimeout(() => {
                    setLoadingBuffer(false);
                }, 1250);

                setActiveCalendarDate(
                    getAppointmentDate(next_available_appointment)
                );
                setCurrentActiveDay(
                    getAppointmentDate(next_available_appointment)
                );
            }
        }
    }, [
        currentActiveDay,
        appointmentSettingsState,
        setActiveCalendarDate,
        allowSameDay,
    ]);

    const selectDay = (dateString) => {
        setCurrentActiveDay(new Date(dateString));
    };

    const getAppointmentTime = useCallback(() => {
        if (typeof appointmentTime === 'object') {
            return appointmentTime.appointment_at_utc;
        }

        return parseInt(appointmentTime);
    }, [appointmentTime]);

    const handleAppointmentIntent = useCallback(() => {
        const storeId = currentSelectedStore.contents.id;
        const time = getAppointmentTime();

        const cancel = intent
            ? appointmentIntentRequest({
                  delete: true,
                  time: intent.time,
                  store_id: intent.storeId,
                  intent_id: intent.intentId,
              }).then(() => {
                  setIntent(null);
              })
            : Promise.resolve();
        const createIntent = () =>
            appointmentIntentRequest({
                store_id: storeId,
                time,
            }).then((result) => {
                setIntent({
                    storeId,
                    time,
                    intentId: result.data.data.intent_id,
                });
            });
        cancel.then(createIntent, createIntent);
    }, [intent, setIntent, currentSelectedStore, getAppointmentTime]);

    const saveApptInfo = () => {
        Array.isArray(window.dataLayer) &&
            window.dataLayer.push({
                appointment: getAppointmentTime(),
                event: 'repairData',
            });

        setCustomerDetails((customerDetails) => {
            return {
                ...customerDetails,
                appointmentTime: getAppointmentTime(),
                service_type_code: 'CI',
            };
        });

        handleAppointmentIntent();
    };

    const hideAppointmentSlotToast = () => {
        setAppointmentSlotTaken(false);
    };

    const getTranslatedChooseTime = (formattedDateString) => {
        const parseString = formattedDateString.split(' ');
        const translateDayOfWeek = t(`${parseString[0]}`);

        return `${translateDayOfWeek} ${parseString[1]}`;
    };

    const loading =
        appointmentSettingsState.state === 'loading' || loadingBuffer;

    return (
        <Section className="page choose-appointment-time">
            <StepHeader title={t('come_in_message')} />

            {appointmentSettingsState.state === 'hasError' && (
                <SimpleAlert
                    error
                    message={t('errors:error_has_occurred_oops')}
                />
            )}

            <div className="row">
                {loading ? (
                    <Loader />
                ) : (
                    <>
                        <DatePicker
                            days={days.current}
                            weekOfDays={currentDaysOfWeek}
                            setActiveDay={selectDay}
                            setCalendarActiveDay={setActiveCalendarDate}
                            activeDay={currentActiveDay}
                            blackoutDays={blackoutDays.contents}
                            allowSameDay={allowSameDay.contents}
                            nextAvailAppointmentDate={getAppointmentDate(
                                appointmentSettingsState.contents
                                    .next_available_appointment
                            )}
                            disabledDaysOfWeek={currentSelectedStore.contents.hours.all
                                .map((day, i) => {
                                    return {
                                        day,
                                        i,
                                    };
                                })
                                .filter(({ day }) => {
                                    return !day;
                                })
                                .map((day) => day.i)}
                        />

                        {!isToday(
                            getAppointmentDate(
                                appointmentSettingsState.contents
                                    .next_available_appointment
                            )
                        ) && (
                            <SameDayNotAvailCallout
                                phoneNumber={formatPhoneNumber(
                                    currentSelectedStore.contents.phone
                                )}
                            />
                        )}

                        {appointmentTimeOptions &&
                            appointmentTimeOptions.length > 0 && (
                                <>
                                    <Text className="field-helper">
                                        {t(
                                            'schedule_repair.appointment_time_title'
                                        )}{' '}
                                        <Text weight="heavy">
                                            {getTranslatedChooseTime(
                                                format(
                                                    currentActiveDay,
                                                    'E M/d'
                                                )
                                            )}
                                        </Text>
                                        .
                                    </Text>
                                    <Dropdown
                                        label={t('actions.select_one')}
                                        name="available-times"
                                        id="available-times"
                                        onChange={(e) => {
                                            setAppointmentTime(e.target.value);
                                        }}
                                        value={appointmentTime}
                                        options={appointmentTimeOptions}
                                    />
                                </>
                            )}

                        {!appointmentSettingsState.contents
                            .next_available_appointment?.date && (
                            <div>
                                {t('errors:schedule_repair.no_appointments')}
                            </div>
                        )}
                    </>
                )}

                <Button
                    className="btn"
                    disabled={loading || !appointmentTime}
                    onClick={saveApptInfo}
                >
                    {t('actions.continue')}
                </Button>

                {currentAppointmentSlotTaken && (
                    <TimeSlotTakenToast
                        hideAppointmentToastHandler={hideAppointmentSlotToast}
                    />
                )}
            </div>
        </Section>
    );
};

const Section = styled.section`
    .link {
        text-align: center;
        margin-top: 2rem;
    }

    .row {
        padding: 0 1rem;
    }

    .btn {
        margin-top: 2rem;
        width: 100%;

        ${(props) => props.theme.mediaQuery(props.theme.breakpoints.medium)} {
            min-width: 344px;
            margin-left: auto;
            margin-right: auto;
            width: auto;
        }
    }
`;

export default ScheduleRepair;
