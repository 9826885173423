import React from 'react';

export const Calendar: React.FC = () => (
    <svg
        width="41"
        height="41"
        viewBox="0 0 41 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            style={{
                stroke: 'currentColor',
            }}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.1667 39.6683C34.6896 39.6683 39.1668 35.1912 39.1668 29.6683C39.1668 24.1455 34.6896 19.6683 29.1667 19.6683C23.6439 19.6683 19.1667 24.1455 19.1667 29.6683C19.1667 35.1912 23.6439 39.6683 29.1667 39.6683Z"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            style={{
                stroke: 'currentColor',
            }}
            d="M32.5001 29.6683H29.1667V24.6683"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            style={{
                stroke: 'currentColor',
            }}
            d="M15.8334 31.3333H2.50004C1.57957 31.3333 0.833374 30.5871 0.833374 29.6666V6.33329C0.833374 5.41282 1.57957 4.66663 2.50004 4.66663H32.5C33.4205 4.66663 34.1667 5.41282 34.1667 6.33329V17.1666"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            style={{
                stroke: 'currentColor',
            }}
            d="M9.16675 1.33496V9.66829"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            style={{
                stroke: 'currentColor',
            }}
            d="M25.8334 1.33496V9.66829"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            style={{
                stroke: 'currentColor',
            }}
            d="M0.833374 13.0016H34.1667"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
