import { useReducer, useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'components/Alert';
import { locales, serviceTypeCodes, errorCodes } from 'utils/constants';
import { createLead } from 'utils/api';
import {
    customerDetails,
    selectedDevice,
    selectedRepair,
    selectedRepairOptions,
    countryCode,
    selectedStore,
    bookingWindow,
    lead,
    validServiceTypeCode,
    appointmentIntent,
    appointmentSlotTaken,
    pricing,
} from 'state/ApplicationRecoil';
import { getCookieValue } from 'utils/helpers';

const initialState = { loading: false, error: null };

function reducer(state, action) {
    switch (action.type) {
        case 'loading':
            return { loading: true, error: null };
        case 'error':
            return { loading: false, error: action.error };
        default:
            return state;
    }
}

const useLeadSubmission = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { t } = useTranslation('errors');
    const { i18n } = useTranslation();
    const { error: alertError, removeAll: clearAlerts } = useAlert();
    const customer = useRecoilValue(customerDetails);
    const selectedServiceTypeCode = useRecoilValue(validServiceTypeCode);
    const selectedDeviceState = useRecoilValue(selectedDevice);
    const selectedRepairState = useRecoilValue(selectedRepair);
    const selectedRepairOptionsState = useRecoilValue(selectedRepairOptions);
    const selectedStoreState = useRecoilValue(selectedStore);
    const countryCodeState = useRecoilValue(countryCode);
    const bookingWindowState = useRecoilValue(bookingWindow);
    const pricingState = useRecoilValue(pricing);
    const intent = useRecoilValue(appointmentIntent);
    const setCustomerDetails = useSetRecoilState(customerDetails);
    const setLead = useSetRecoilState(lead);
    const setAppointmentSlotTaken = useSetRecoilState(appointmentSlotTaken);

    const getLeadSubmissionPayload = useCallback(
        (formData = {}) => {
            const customerData = { ...customer, ...formData };

            let actualServiceTypeCode = selectedServiceTypeCode;
            // Capture the CI/CB selection
            if (customerData.service_type_code) {
                actualServiceTypeCode = customerData.service_type_code;
            }

            const selectedRepairId = selectedRepairState
                ? selectedRepairState.id
                : undefined;

            // Google Analytics Client ID
            // https://ubreakifix.atlassian.net/browse/WEB-1212
            const gaCookie = getCookieValue('_ga');
            let clientId = null;

            if (gaCookie) {
                clientId = RegExp(/\S*[.]\S*[.](\S*[.]\S*)/).exec(gaCookie);

                clientId = clientId ? clientId[clientId.length - 1] : null;
            }

            const payload = {
                referer: window.location.href,
                amount: true,
                customer: {
                    given_name: customerData.givenName,
                    family_name: customerData.familyName,
                    email: customerData.email,
                    phone: customerData.phone.replace(/\D/g, ''),
                    // Start address
                    address_line_1: customerData.address_line_1,
                    address_line_2: customerData.address_line_2,
                    city: customerData.city,
                    state: customerData.state,
                    postal_code: customerData.postal_code,
                    country: countryCodeState,
                    // End address
                    special_instructions: customerData.specialInstructions,
                    can_sms: customerData.canSms,
                    can_email: customerData.canEmail,
                    can_call: customerData.canCall,
                },
                country_code: countryCodeState.toLowerCase(),
                customer_message: '',
                is_appointment: actualServiceTypeCode === 'CI',
                device_type_id: selectedDeviceState.id,
                device_repair_id: selectedRepairId,
                service_options: {
                    location_type: customerData.locationType,
                    service_type_code: actualServiceTypeCode,
                },
                partner_data: {
                    device_repair_id: selectedRepairId,
                    device_type_option_ids: Object.values(
                        selectedRepairOptionsState
                    )
                        .map((option) => {
                            return JSON.parse(option);
                        })
                        .reduce((acc, curr) => acc.concat(curr), []),
                },
                intent_id: intent?.intentId,
                locale: locales[i18n.language].apiTranslationKey,
            };

            if (clientId) {
                payload.clientId = clientId;
            }

            // Add extra params based on service type/code
            if (actualServiceTypeCode !== 'RT') {
                payload.customer.store_id = selectedStoreState.id;
                payload.store_id = selectedStoreState.id;
                payload.products = [{ product_id: selectedRepairId }];
            } else {
                payload.booking_window_id = bookingWindowState.window_id;
            }

            if (actualServiceTypeCode !== 'MI') {
                payload.aqlead_type_id =
                    serviceTypeCodes[actualServiceTypeCode].id;
            }

            if (actualServiceTypeCode === 'CI') {
                payload.appointment_at = customerData.appointmentTime;
            }

            // Add the quote_store_id if we have a price
            if (pricingState.price && pricingState.store_id) {
                payload.quote_store_id = pricingState.store_id;
            }

            return payload;
        },
        [
            countryCodeState,
            i18n.language,
            customer,
            selectedStoreState,
            selectedRepairState,
            selectedDeviceState,
            bookingWindowState,
            selectedServiceTypeCode,
            selectedRepairOptionsState,
            intent,
            pricingState,
        ]
    );

    const resetAppointmentTime = useCallback(() => {
        setCustomerDetails((customerData) => {
            return {
                ...customerData,
                appointmentTime: null,
            };
        });
    }, [setCustomerDetails]);

    const handleSuccess = (leadId) => {
        // Pass the leadId into the gtm dataLayer
        Array.isArray(window.dataLayer) &&
            window.dataLayer.push({
                leadId,
                event: 'new_flow_schedule_app_success',
            });
    };

    // Data should contain any extra data that isnt set in state at time of submission
    const submitLead = useCallback(
        async (formData) => {
            try {
                clearAlerts();

                dispatch({ type: 'loading' });

                const {
                    data: { data },
                } = await createLead(getLeadSubmissionPayload(formData));

                // Handled errors
                if (data.error) {
                    // Error where postal code is invalid for the provided state/province for mail in
                    if (selectedServiceTypeCode === 'MI' && data.code === 0) {
                        throw new Error(t('invalid_mailin_postal_code'));
                    }

                    if (selectedServiceTypeCode === 'CC' && data.code === 400) {
                        throw new Error(errorCodes.TIMESLOT_TAKEN);
                    }

                    // Generic error message
                    throw new Error(t('something_went_wrong'));
                }

                // Perform any actions we need after successful lead creation
                // Pass lead id to success handler
                handleSuccess(data.id);

                // Save the lead
                setLead(data);
            } catch (err) {
                // Only show banner if we are not dealing with appointment time already taken error state.
                if (err.message !== errorCodes.TIMESLOT_TAKEN) {
                    alertError(err.message || t('something_went_wrong'));
                } else {
                    resetAppointmentTime();
                    setAppointmentSlotTaken(true);
                }

                dispatch({ type: 'error', error: err.message });
            }
        },
        [
            getLeadSubmissionPayload,
            setLead,
            alertError,
            clearAlerts,
            selectedServiceTypeCode,
            resetAppointmentTime,
            setAppointmentSlotTaken,
            t,
        ]
    );

    return {
        submitLead,
        handleSuccess,
        getLeadSubmissionPayload,
        leadSubmissionState: state,
    };
};

export { useLeadSubmission };
