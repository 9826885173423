import { Text, Link } from '@soluto-private/mx-asurion-ui-react';
import { useRecoilValue, useResetRecoilState, useRecoilState } from 'recoil';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import {
    bookingWindow,
    customerDetails,
    locale,
} from 'state/ApplicationRecoil';
import { Icon } from 'components/Icons';
import { ReactComponent as PinLocationIcon } from 'images/pin-location-icon.svg';
import { formatBookingWindowSlot } from 'utils/helpers';

const RemoteTechDetails = () => {
    const { t } = useTranslation('common');
    const resetBookingWindow = useResetRecoilState(bookingWindow);
    const selectedBookingWindow = useRecoilValue(bookingWindow);
    const [customerDetailsValue, setCustomerDetails] =
        useRecoilState(customerDetails);
    const localeState = useRecoilValue(locale);
    const formattedBookingWindow = formatBookingWindowSlot(
        selectedBookingWindow
    );

    const handleChangeTime = (e) => {
        e.preventDefault();

        resetBookingWindow();
    };

    const handleLocationChange = (e) => {
        e.preventDefault();

        setCustomerDetails({
            ...customerDetailsValue,
            hasValidLocation: false,
        });
    };

    return (
        <div className="appointment-details">
            <div className="section">
                <Text
                    className="section-header"
                    weight="heavy"
                    size="3"
                    as="div"
                >
                    {t('appointment_time')}
                </Text>

                <div className="section-body">
                    <Icon className="icon" name="Calendar" size={41} />

                    <div>
                        <Text as="div">
                            {format(
                                formattedBookingWindow.window_start,
                                'ccc, MMMM d, y',
                                {
                                    locale: localeState.dateLocale.default,
                                }
                            )}
                            <br />
                            {format(formattedBookingWindow.window_start, 'h', {
                                locale: localeState.dateLocale.default,
                            })}
                            -
                            {format(formattedBookingWindow.window_end, 'ha', {
                                locale: localeState.dateLocale.default,
                            })}
                        </Text>

                        <Link
                            href="#"
                            color="secondary"
                            onClick={handleChangeTime}
                            className="action-link"
                        >
                            {t('actions.change_time')}
                        </Link>
                    </div>
                </div>
            </div>

            <div className="line" />

            <div className="section">
                <div className="section-header">
                    <Text weight="heavy" size="3">
                        {t('repair_location')}
                    </Text>
                </div>

                <div className="section-body">
                    <PinLocationIcon width="41" height="41" className="icon" />

                    <div>
                        <Text as="div" size={2} className="address">
                            <div>{customerDetailsValue.address_line_1}</div>
                            <div>{customerDetailsValue.address_line_2}</div>
                            <div>
                                {customerDetailsValue.city},{' '}
                                {customerDetailsValue.state},{' '}
                                {customerDetailsValue.postal_code}
                            </div>
                        </Text>

                        <Link
                            href="#"
                            color="secondary"
                            onClick={handleLocationChange}
                            className="action-link"
                        >
                            {t('actions.change_location')}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RemoteTechDetails;
