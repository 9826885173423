import { useRecoilValue } from 'recoil';
import { currentStep } from 'state/ApplicationRecoil';
import RootDeviceSelection from 'components/SchedulingFlow/RootDeviceSelection';
import CustomerLocator from 'components/SchedulingFlow/CustomerLocator';
import ServiceOptions from 'components/SchedulingFlow/ServiceOptions';
import ChooseLocation from 'components/SchedulingFlow/ChooseLocation';
import DeviceDetails from 'components/SchedulingFlow/DeviceDetails';
import RepairSelection from 'components/SchedulingFlow/RepairSelection';
import SelectedRepairOptions from 'components/SchedulingFlow/SelectedRepairOptions';
import SelectBookingWindow from 'components/SchedulingFlow/SelectBookingWindow';
import ScheduleRepair from 'components/SchedulingFlow/ScheduleRepair';
import ContactDetails from 'components/SchedulingFlow/ContactDetails';
import MailInRepairLocation from 'components/SchedulingFlow/MailInRepairLocation';
import RemoteTechLocation from 'components/SchedulingFlow/RemoteTechLocation';
import PaymentCollection from 'components/SchedulingFlow/RepairDeposit';
import Confirmation from 'components/SchedulingFlow/Confirmation';
import RemoteTechNotAvailable from 'components/SchedulingFlow/RemoteTechNotAvailable';

const SchedulingSteps = () => {
    const step = useRecoilValue(currentStep);

    const sections = {
        ROOT_DEVICE: RootDeviceSelection,
        CUSTOMER_LOCATION: CustomerLocator,
        SERVICE_TYPE: ServiceOptions,
        CHOOSE_STORE: ChooseLocation,
        APPT_DETAILS: ScheduleRepair,
        DEVICE_DETAILS: DeviceDetails,
        REPAIR_SELECTION: RepairSelection,
        SELECTED_REPAIR_OPTIONS: SelectedRepairOptions,
        RT_NOT_AVAILABLE: RemoteTechNotAvailable,
        SELECT_BOOKING_WINDOW: SelectBookingWindow,
        CONTACT_DETAILS: ContactDetails,
        MI_REPAIR_LOCATION: MailInRepairLocation,
        RT_REPAIR_LOCATION: RemoteTechLocation,
        PAYMENT_COLLECTION: PaymentCollection,
        CONFIRMATION: Confirmation,
    };

    const Component = sections[step];

    return <Component />;
};

export default SchedulingSteps;
